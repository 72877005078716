import styled from 'styled-components';

const TableWrapper = styled.table`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 300;

  tr {
    height: 30px;
  }

  th {
    text-align: left;

    color: #b1b1b1;
    font-weight: 300;
    border-bottom: 1px solid #b1b1b175;
  }
`;

const TableChart = ({ data }) => {
  return (
    <TableWrapper>
      <thead>
        <tr className="head">
          <th> Número</th>
          <th> Extensión </th>
          <th> Nombre </th>
          <th> Segundos </th>
        </tr>
      </thead>

      <tbody>
        {data.map((info, index) => (
          <tr key={index}>
            <td> {info.num} </td>
            <td> {info.ext} </td>
            <td> {info.name} </td>
            <td> {info.seconds} </td>
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default TableChart;
