import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 11px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: ${colors.greyActive};
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 9px;
    margin-right: 5px;
  }
`;
