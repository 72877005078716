import styled from 'styled-components';

export const OverviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;

  .containtTop {
    width: 80%;
    height: 183px;
    margin-top: 12px;
    background-color: white;
  }

  .containtCenter {
    display: flex;
    height: 183px;
    width: 100%;
    justify-content: space-around;
    margin-top: 40px;
  }

  .left {
    height: 183px;
    width: 40%;
    background-color: white;
  }

  .rigth {
    height: 183px;
    width: 40%;
    background-color: white;
  }

  .containtBottom {
    height: 260px;
    margin-top: 10px;
    width: 90%;
    background-color: white;
  }
`;
