import React from 'react';
import { Navigate } from 'react-router-dom';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuApplications } from '../../../data/dataApplications';
import { OverviewApplicationsWrapper } from './OverviewApplications.style';

export const OverviewApplications = () => {
  return (
    <OverviewApplicationsWrapper>
      <Navigate to="/applications/conference" />
      <LinkButtonBig menuTop={menuApplications} />
    </OverviewApplicationsWrapper>
  );
};
