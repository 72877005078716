export const fontFamily = {
  font: 'Ubuntu',
  fontCard: 'Inter',
};

export const weight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const newFontSize = {
  h1: '48.83px',
  h2: '31.25px',
  h3: '16px',
  h4: '20px',
  button: '14px',
  body: '14px',
  placeholder: '13px',
  label: '12px',
  h5: '11px',
  span: '10px',
};
