import React from 'react';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuSettings } from '../../../data/dataSettings';
import { OverviewSettingsScreenWrapper } from './OverviewSettingsScreen.style';
import { Navigate } from 'react-router-dom';

export const OverviewSettingsScreen = () => {
  return (
    <OverviewSettingsScreenWrapper>
      <Navigate to="/settings/users" />
      <LinkButtonBig menuTop={menuSettings} />
    </OverviewSettingsScreenWrapper>
  );
};
