import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IconStepInto, IconStepOut, IconLogout } from '@tabler/icons-react';

import PermissionProvider from '../Permission/PermissionProvider';
import Restricted from '../Permission/PermissionConsumer';
import { logoutIcon } from '../../data/dataLogin';
import { loginUserAction } from '../../redux/actions/login/actionCreators';
import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';
import { useSwal } from '../../hooks/useSwal';

import { NavMenu } from './NavMenuLeft.style';
import { Button } from '../Button/Button';
import { colors } from '../../theme/colors';
import { UserProfile } from '../UserProfile/UserProfile';

export const NavMenuLeft = ({ menu, isClosed, setIsClosed, user }) => {
  const { menuTop, menuBottom, chevrons } = menu;
  const { pathname } = useLocation();
  const { typeSwal } = useSwal();
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const initialState = {
    authChecked: false,
    loggedIn: false,
    user: {},
  };

  const initialStatePermissions = [];

  const logout = () => {
    typeSwal('logout').then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('tokenStorage');
        dispatch(loginUserAction(initialState));
        dispatch(getUsersPermissionAction(initialStatePermissions));
        navigate('/login', { replace: true });
      }
    });
  };

  const pathNameSeparate = pathname.split('/')[1];

  const { getState } = useStore();

  const permissions = getState()['arrayUsersPermission'];

  const titlePermissionsUser = permissions.map((item) => item.title);

  const changeView = () => {
    setIsClosed(!isClosed);
  };

  return (
    <PermissionProvider permissionsUser={titlePermissionsUser}>
      <NavMenu isClosed={isClosed}>
        {/* <div className={isClosed ? 'contain__openMenuIcon-close' : 'contain__openMenuIcon-open'}> */}
        <div className="iconActionMenu">
          <div className="iconActionMenu__contain">
            <Button
              width={'30px'}
              backgroundColor={colors.primaryColor}
              className={isClosed ? 'iconActionMenu__close' : 'iconActionMenu__open'}
              border={`1px solid ${colors.white}`}
              Icon={isClosed ? IconStepOut : IconStepInto}
              onClick={changeView}
              strokeWidth={2}
              heightIcon={16}
            />
          </div>
        </div>
        <div className="contain__navLeft">
          <div className="container__top">
            {menuTop.map((item, index) =>
              item.text === 'Divider' ? (
                <div key={index} className="item__divider">
                  <div></div>
                </div>
              ) : (
                <Restricted key={index} to={item.restricted}>
                  <Link to={item.path} replace>
                    <div className={item.path === pathNameSeparate ? 'item__active' : 'item__contain'}>
                      <item.image className="item__image" height={13} />
                      <p className="item__text">{item.text}</p>
                    </div>
                  </Link>
                </Restricted>
              )
            )}
          </div>

          <div className="container__bottom">
            {menuBottom.map((item, index) =>
              item.restricted === 'settings' ? (
                <Restricted key={index} to={item.restricted}>
                  <Link to={item.path} replace>
                    <div className={item.path === pathNameSeparate ? 'item__active' : 'item__contain'} key={index}>
                      <item.image className="item__image" height={13} />
                      <p className="item__text">{item.text}</p>
                    </div>
                  </Link>
                </Restricted>
              ) : (
                <Link key={index} to={item.path} replace>
                  <div className={item.path === pathNameSeparate ? 'item__active' : 'item__contain'} key={index}>
                    <item.image className="item__image" height={13} />
                    <p className="item__text">{item.text}</p>
                  </div>
                </Link>
              )
            )}

            <div onClick={logout} className="item__contain">
              <IconLogout className="item__image" height={13} />

              <p className="item__text">Cerrar sesión</p>
            </div>
            <div className="item__divider">
              <div></div>
            </div>
            <div>
              <UserProfile user={user} isClosed={isClosed} />
            </div>
          </div>
        </div>
      </NavMenu>
    </PermissionProvider>
  );
};

NavMenuLeft.propType = {
  menu: PropTypes.object.isRequired,
};
