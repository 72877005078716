import { useState } from 'react';

import { CostumerServiceScreenWrapper } from './CostumerServiceScreen.style';

import { CardGird } from '../../../components/CardGrid/CardGird';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';

const CostumerServiceScreen = () => {
  const [, setIsBottom] = useState(false);

  const DATA = [
    {
      id: 1,
      title: 'Adasd '.toUpperCase(),
      subtitle: '426 Llamadas',
      // icon: icono,
      text: 'Llamadas entrantes 213',
      text2: 'Llamadas salientes 213',
    },
    {
      id: 2,
      title: 'Asadsa'.toUpperCase(),
      subtitle: '426 Llamadas',
      // icon: icono,
      text: 'Llamadas entrantes 213',
      text2: 'Llamadas salientes 213',
    },
  ];

  const handleClick = (id) => {};

  return (
    <CostumerServiceScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleClick,
            },
          }}
        />
      </div>
      {false ? (
        <LoaderSpinner />
      ) : (
        <div className="contain__cardGrid">
          <CardGird data={DATA} onClick={handleClick} setIsBottom={setIsBottom} />
        </div>
      )}
    </CostumerServiceScreenWrapper>
  );
};

export default CostumerServiceScreen;
