import { BlockPicker } from 'react-color';

import { useEffect } from 'react';
import usePreferences from '../../hooks/usePreferences';

const BlockPickerComponent = ({ color, setColor, setPressed, reference }) => {
  const { preferencesForm } = usePreferences();

  useEffect(() => {
    preferencesForm({ action: 'input', value: color }, { form: 'calendar', element: 'color' });
    const handleClickOutside = (event) => {
      if (reference.current && !reference.current.contains(event.target)) setPressed(false);
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
    
  }, []);

  const onClick = (color) => {
    setColor(color);
    setPressed(false);

    preferencesForm({ action: 'input', value: color }, { form: 'calendar', element: 'color' });
    //
  };

  return (
    <div>
      <BlockPicker
        color={color ? color : '#db9cd7'}
        width="100%"
        colors={['#C68282', '#C6C582', '#83C682', '#82C6C5', '#8297C6', '#A382C6', '#dce775']}
        triangle="top"
        onChangeComplete={(color) => onClick(color?.hex)}
      />
    </div>
  );
};

export default BlockPickerComponent;
