import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
import { dataScreenGroupCategories } from '../../../data/dataOutbound';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { useSwal } from '../../../hooks/useSwal';

import { GroupCategoriesWrapper } from './GroupCategories.style';

export const GroupCategoriesScreen = () => {
  const {
    //getCategoriesLinkHook,
    categoriesLinkHook,
    getCategoriesGroupHook,
    categoriesGroupHook,
    deleteCategoriesGroup,
    loading,
  } = useOutbound();
  const { typeSwal } = useSwal();

  const [selectedId, setSelectedId] = useState('');
  const [enable, setEnable] = useState(1);

  const [objectCategoriesGroup, setObjectCategoriesGroup] = useState({
    arrayCategoriesGroup: [],
    title: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    getCategoriesGroupHook();
  }, [getCategoriesGroupHook]);

  useEffect(() => {
    let newName = categoriesLinkHook.filter((object) => object.CategoriesGroup?.id === categoriesGroupHook[0]?.id);
    setObjectCategoriesGroup({
      arrayCategoriesGroup: newName,
      title: categoriesGroupHook[0]?.description,
    });
    setEnable(categoriesGroupHook[0]?.id);
  }, [categoriesGroupHook, categoriesLinkHook]);

  const handleClick = (item) => {
    let newGroupCategory = categoriesLinkHook.filter((object) => object.CategoriesGroup.id === item.id);
    setObjectCategoriesGroup({
      arrayCategoriesGroup: newGroupCategory,
      title: item.text,
    });

    setEnable(item.id);
  };

  const handleOpenFormCreate = () => {
    navigate('/outboundRoutes/groupCategories/create', {});
  };

  const handleOpenFormEdit = (item) => {
    handleClick(item);
    navigate('/outboundRoutes/groupCategories/update', {
      state: objectCategoriesGroup,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await deleteCategoriesGroup(item);
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getCategoriesGroupHook();
            typeSwal('delete');
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const dataToCards = objectCategoriesGroup.arrayCategoriesGroup?.map((item) => ({
    title: item.Category?.description,
    icon: dataScreenGroupCategories.icon.flag,
    subtitle: item.Category?.prefix,
    icon2: dataScreenGroupCategories.icon.categories,
    text: item.CategoriesGroup?.description,
    icon3: dataScreenGroupCategories.icon.arrowRight,
    text2: item.Category?.createdAt,
  }));

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) {
      item.function = handleOpenFormEdit;
    } else if (item.id === 2) {
      item.function = handleDelete;
    }
  });

  const { title, notFound } = dataScreenGroupCategories;

  const option = categoriesGroupHook.map((item) => ({
    text: item.description,
    id: item.id,
  }));

  return (
    <GroupCategoriesWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
          }}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : categoriesGroupHook[0]?.status ? (
        <div className="distributionTable">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : (
        <div className="distributionTable">
          <DistributionTable
            title={title}
            option={option}
            component={<CardGird data={dataToCards} />}
            selectedId={selectedId}
            setSelectId={setSelectedId}
            handleClick={handleClick}
            menuButtonsDropDown={menuButtonsDropDownTable}
            enable={enable}
          />
        </div>
      )}
    </GroupCategoriesWrapper>
  );
};
