import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const PhoneWrapper = styled.div`
  height: 100%;
  width: 100%;
  .phone {
    height: 100%;
    width: 100%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;

    &__container {
      height: 100%;
      width: 100%;
    }

    &__keys {
      height: 183px;
      width: 300px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      column-gap: 5px;
      row-gap: 5px;
    }

    &__selection {
      height: 100%;
      width: 100%;
    }

    &__selection__items {
      height: 100%;
      width: 100%;
    }

    &__key__active {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: ${colors.black};
      align-self: center;
    }

    &__select {
      width: 100%;
      height: 100%;
      margin-top: 10px;
    }
  }

  .phone__info {
    display: grid;
    grid-template-columns: 1fr 8fr;
    margin-top: 70px;
    height: 100%;
    width: 100%;
    row-gap: 30px;

    .phone__option__active {
      height: 100%;
      width: 100%;
      display: grid;

      .phone__option__item {
        background: ${colors.secondaryColor};
        border: 1px solid ${colors.greyLine};
        border-radius: 5px;
        align-self: center;
        display: grid;
        padding: 8px 15px;
        width: 390px;
        grid-template-columns: 9fr 1fr;

        p {
          margin: 0px;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: ${colors.black};
          align-self: center;
        }

        img {
          justify-self: end;
          align-self: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .phone__notPressed {
    height: 100%;
    width: 100%;
    margin-top: 70px;

    &__contain {
      height: 100%;
      width: 208px;
    }
    &__title {
      &__item {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: ${colors.black};
        align-self: center;
      }
    }
  }

  .phone__timeOut {
    height: 100%;

    margin-top: 20px;
    width: 205px;

    &__contain {
      height: 100%;
      width: 100%;
    }
    &__title {
      p {
        margin: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: ${colors.black};
        align-self: center;
      }
    }
    &__input {
      margin-top: 10px;
      height: 36px;

      p {
        margin: 0px;
        font-size: 14px;
      }
    }
  }
`;
