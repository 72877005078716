import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const SelectWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .select {
    height: 100%;
    width: 100%;
    min-width: 209px;

    &__contain {
      height: 100%;
      width: 100%;
    }
    &__item {
      height: 32px;
      border: 1px solid ${colors.greyBorder};
      border-radius: 5px;
      display: grid;
      grid-template-columns: 9fr 1fr;
      padding: 0 2px;

      &:hover {
        cursor: pointer;
      }
    }
    &__text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      &__item {
        margin: 0;
        padding-left: 8px;
        font-family: Inter;
        font-weight: 300;
        font-size: 13px;
        color: #4a5056;
      }
      &__itemSelected {
        margin: 0;
        padding-left: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #222529;
      }
      &__remove {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__img__dropdown {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &__item {
        padding-right: 5px;
        color: ${colors.primaryColor};
      }
    }
    &__options {
      height: auto;
      max-height: 150px;
      width: 50%;
      border-radius: 5px;
      display: grid;
      margin-top: 5px;
      min-width: 140px;

      z-index: 100;
      position: absolute;
      background: ${colors.white};

      border-radius: 4px;
      box-shadow:
        0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColor};
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }
    &__optionsSub {
      min-width: 150px;
      border-radius: 5px;
      display: grid;
      margin-top: 5px;
      max-height: 150px;
      z-index: 100;
      position: absolute;
      left: 141px;
      background: ${colors.white};

      border-radius: 4px;
      box-shadow:
        0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 2px;
        background: white;
        height: 5px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        width: 1px;
        background: ${colors.primaryColor};
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
      }
      &::-webkit-scrollbar-track-piece {
        height: 80%;
      }
    }

    &__optionsSub-input {
      height: auto;
      width: 140px;
      min-width: 150px;
      border-radius: 5px;
      padding: 4px 2px;
      display: grid;
      z-index: 100;
      position: absolute;
      top: 111px;
      left: 141px;
      background: ${colors.white};
      align-items: center;

      border-radius: 4px;
      box-shadow:
        0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
    }

    &__optionSub-input {
      height: 100%;
      width: 100%;
    }
    &__option {
      height: 100%;
      width: 100%;
    }

    .position {
      position: absolute;
      right: 232px;
    }
  }
`;
