import React, { useState } from 'react';
import { IconX, IconPlus } from '@tabler/icons-react';

import { ModalWrapper } from './Modal.style';
import { Search } from '../Search/Search';
import { Button } from '../Button/Button';
import { colors } from '../../theme/colors';
import { TableInfo } from '../TableInfo/TableInfo';

export const Modal = ({ show, handleClose, children, dataModal, columnsModal, setDataCard }) => {
  const [search, setSearch] = useState([]);

  return (
    show && (
      <ModalWrapper>
        <div className="modal">
          <div className="modal__title">
            <p className="modal__title__item">Selecciona los agentes a los que les quieras asignar una estrategia </p>
            <div onClick={handleClose} className="modal__title__icon">
              <IconX height={14} width={14} className="modal__title__icon__item" />
            </div>
          </div>
          <div className="modal__search">
            <Search setSearch={setSearch} data={dataModal} columns={columnsModal} />
          </div>
          {search[0]?.notFound ? (
            <div className="modal__notFound">
              <h4>{search[0].notFound}</h4>
            </div>
          ) : (
            children === 'table' && (
              <div className="modal__children">
                <TableInfo format={'list'} COLUMNS={columnsModal} DATA={search.length === 0 ? dataModal : search} isOptions={false} isNavTable={false} setDataCard={setDataCard} />
              </div>
            )
          )}
          <div className="modal__button">
            <Button
              backgroundColor={colors.primaryColor}
              border={'0px'}
              colorText={colors.white}
              width={'50%'}
              Icon={IconPlus}
              onClick={handleClose}
              hoverBackgroundColor={colors.secondaryColor}
              hoverIcon={colors.primaryColor}
              text={'Añadir selección'}
              hoverColorText={colors.primaryColor}
            />
          </div>
        </div>
      </ModalWrapper>
    )
  );
};
