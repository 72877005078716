import { actionTypesOutboundRoutes } from '../../actions/outboundRoutes/actionTypes';

export const outboundRoutesGetTrunksReducer = (trunks = [], action) => {
  let newTrunks;
  switch (action.type) {
    case actionTypesOutboundRoutes.getTrunks:
      newTrunks = [...action.trunks];
      break;
    default:
      newTrunks = trunks;
  }
  return newTrunks;
};

export const outboundRoutesFormReducer = (
  state = {
    id: '',
    name: '',
    ipaddr: null,
    port: '',
    context: '',
    permit: null,
    deny: null,
    transport: [],
    nat: [],
    dtmfmode: [],
    allow: [],
    disallow: [],
    qualify: null,
    description: '',
    password: '',
    domain: '',
    server: '',
    headquarters: '',
    defaultuser: '',
    prefix: '',
    register: true,
    sipPeerId: '',
    kamDispatcherId: '',
  },
  action
) => {
  let newState;
  switch (action.type) {
    case actionTypesOutboundRoutes.clearTrunksForm:
      newState = {
        id: '',
        name: '',
        ipaddr: null,
        port: '',
        context: '',
        permit: null,
        deny: null,
        transport: [],
        nat: [],
        dtmfmode: [],
        allow: [],
        disallow: [{ value: 'All', label: 'all' }],
        qualify: null,
        description: '',
        password: '',
        domain: '',
        server: '',
        headquarters: '',
        defaultuser: '',
        prefix: '',
        register: true,
        sipPeerId: '',
        kamDispatcherId: '',
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormKamDispatcherId:
      newState = {
        ...state,
        kamDispatcherId: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormName:
      newState = {
        ...state,
        name: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormIpaddr:
      newState = {
        ...state,
        ipaddr: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormPort:
      newState = {
        ...state,
        port: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormContext:
      newState = {
        ...state,
        context: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormPermit:
      newState = {
        ...state,
        permit: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormDeny:
      newState = {
        ...state,
        deny: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormTransport:
      newState = {
        ...state,
        transport: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormNat:
      newState = {
        ...state,
        nat: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormDFMDMode:
      newState = {
        ...state,
        dtmfmode: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormAllow:
      newState = {
        ...state,
        allow: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormDisallow:
      newState = {
        ...state,
        disallow: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormQualify:
      newState = {
        ...state,
        qualify: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormDescription:
      newState = {
        ...state,
        description: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormPassword:
      newState = {
        ...state,
        password: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormDomain:
      newState = {
        ...state,
        domain: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormServer:
      newState = {
        ...state,
        server: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.addTrunksFormHeadquarters:
      newState = {
        ...state,
        headquarters: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.removeTrunksFormHeadquarters:
      newState = {
        ...state,
        headquarters: '',
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormUser:
      newState = {
        ...state,
        defaultuser: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormPrefix:
      newState = {
        ...state,
        prefix: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormRegister:
      newState = {
        ...state,
        register: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormSipPeerId:
      newState = {
        ...state,
        sipPeerId: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeTrunksFormId:
      newState = {
        ...state,
        id: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.clearTrunksFormNat:
      newState = {
        ...state,
        nat: [],
      };
      break;

    case actionTypesOutboundRoutes.clearTrunksFormAllow:
      newState = {
        ...state,
        allow: [],
      };
      break;
    case actionTypesOutboundRoutes.clearTrunksFormDisallow:
      newState = {
        ...state,
        disallow: [],
      };
      break;
    case actionTypesOutboundRoutes.clearTrunksFormCompany:
      newState = {
        ...state,
        headquarters: '',
      };
      break;
    case actionTypesOutboundRoutes.clearTrunksFormTransport:
      newState = {
        ...state,
        transport: [],
      };
      break;
    case actionTypesOutboundRoutes.clearTrunksFormDFMDMode:
      newState = {
        ...state,
        dtmfmode: [],
      };
      break;

    default:
      newState = { ...state };
      break;
  }

  return newState;
};

//CategoriesLink reducer

export const getCategoriesLinkReducer = (categories = [], action) => {
  let newCategories;
  switch (action.type) {
    case actionTypesOutboundRoutes.getCategoriesLink:
      newCategories = [...action.categories];
      break;
    default:
      newCategories = categories;
  }
  return newCategories;
};

//CategoriesGroup reducer

export const getCategoriesGroupReducer = (categoriesGroup = [], action) => {
  let newCategoriesGroup;
  switch (action.type) {
    case actionTypesOutboundRoutes.getCategoriesGroup:
      newCategoriesGroup = [...action.categoriesGroup];
      break;
    default:
      newCategoriesGroup = categoriesGroup;
  }
  return newCategoriesGroup;
};

export const categoriesGroupFormReducer = (
  state = {
    id: null,
    name: '',
    categories: [],
  },
  action
) => {
  let arrayCategories = { ...state }.categories;
  switch (action.type) {
    case actionTypesOutboundRoutes.changeCategoriesGroupFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;

    case actionTypesOutboundRoutes.changeCategoriesGroupFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.addCategoryFormGroup:
      arrayCategories.push(action.payload);
      state = {
        ...state,
        categories: arrayCategories,
      };
      break;
    case actionTypesOutboundRoutes.removeCategoryFormGroup:
      const index = arrayCategories.map((p) => p.value).indexOf(action.payload.value);
      if (index > -1) {
        arrayCategories.splice(index, 1);
      }
      state = {
        ...state,
        categories: arrayCategories,
      };
      break;
    case actionTypesOutboundRoutes.clearMultiselectCategoriesGroupForm:
      state = {
        ...state,
        categories: [],
      };
      break;

    case actionTypesOutboundRoutes.clearCategoryFormGroup:
      state = {
        name: '',
        categories: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

//CategoriesReducer

export const getCategoriesReducer = (categories = [], action) => {
  let newCategories;
  switch (action.type) {
    case actionTypesOutboundRoutes.getCategories:
      newCategories = [...action.categories];
      break;
    default:
      newCategories = categories;
  }
  return newCategories;
};

export const categoriesFormReducer = (
  state = {
    name: '',
    prefix: '',
    id: '',
    outboundRoutesId: '',
    failOverOutboundRoutesId: '',
  },
  action
) => {
  switch (action.type) {
    case actionTypesOutboundRoutes.changeCategoriesFormName:
      state = {
        ...state,
        name: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.changeCategoriesFormPrefix:
      state = {
        ...state,
        prefix: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.changeCategoriesFormId:
      state = {
        ...state,
        id: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.addCategoriesOutboundRouteForm:
      state = {
        ...state,
        outboundRoutesId: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.removeCategoriesOutboundRouteForm:
      state = {
        ...state,
        outboundRoutesId: [],
      };
      break;
    case actionTypesOutboundRoutes.addCategoriesOutboundRouteFailForm:
      state = {
        ...state,
        failOverOutboundRoutesId: action.payload,
      };
      break;
    case actionTypesOutboundRoutes.removeCategoriesOutboundRouteFailForm:
      state = {
        ...state,
        failOverOutboundRoutesId: [],
      };
      break;
    case actionTypesOutboundRoutes.clearCategoriesOutboundRouteForm:
      state = {
        ...state,
        outboundRoutesId: [],
      };
      break;
    case actionTypesOutboundRoutes.clearCategoriesOutboundRouteFailForm:
      state = {
        ...state,
        failOverOutboundRoutesId: [],
      };
      break;

    case actionTypesOutboundRoutes.clearCategoriesForm:
      state = {
        name: '',
        prefix: '',
        id: '',
        outboundRoutesId: '',
        failOverOutboundRoutesId: '',
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
