import React from 'react';
import { OverviewWrapper } from './OverviewScreen.style';

export const OverviewScreen = () => {
  return (
    <OverviewWrapper>
      <div className="containtTop"></div>
      <div className="containtCenter">
        <div className="left"></div>
        <div className="rigth"></div>
      </div>
      <div className="containtBottom"></div>
    </OverviewWrapper>
  );
};
