import React, { useEffect, useState } from 'react';
import { IconSquare, IconCheck } from '@tabler/icons-react';

import { CheckboxWrapper } from './Checkbox.style';

export const Checkbox = ({ row, checkBoxAllSelected, setCheckBoxAllSelected, checkboxesActives, setCheckboxesActives, DATA }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (checkBoxAllSelected) {
      setIsActive(true);
      if (row !== undefined) {
        let found = checkboxesActives.some((checkbox) => checkbox.id === row.id);
        if (!found) {
          checkboxesActives.push(row);
          let newCheckboxes = checkboxesActives;
          let found2 = checkboxesActives.some((checkbox) => checkbox.id === row.id);
          if (!found2) {
            newCheckboxes.map((item) => setCheckboxesActives([...checkboxesActives, item]));
          }
        }
      }
    } else {
      setIsActive(false);
      if (row !== undefined) {
        setCheckboxesActives([]);
      }
    }
    
  }, [checkBoxAllSelected]);

  const handleCheckbox = () => {
    if (row === undefined) {
      setCheckBoxAllSelected(!checkBoxAllSelected);
      if (checkBoxAllSelected) {
        setCheckboxesActives(DATA);
      } else {
        setCheckboxesActives([]);
      }
    } else {
      let found = checkboxesActives.some((checkbox) => checkbox.id === row.id);
      if (found) {
        let filteredRows = checkboxesActives.filter((checkbox) => checkbox.id !== row.id);
        setCheckboxesActives(filteredRows);
        setIsActive(false);
      } else {
        setCheckboxesActives([...checkboxesActives, row]);
      }
      setIsActive(!isActive);
    }
  };

  return (
    <CheckboxWrapper>
      {!isActive && !checkBoxAllSelected ? (
        <div className="checkbox__icon">
          <IconSquare className="checkbox__icon__square" strokeWidth={1} height={15} onClick={handleCheckbox} />
        </div>
      ) : isActive ? (
        <div className="checkbox__icon">
          <IconSquare className="checkbox__icon__square" strokeWidth={1} height={15} onClick={handleCheckbox} />{' '}
          <IconCheck className="checkbox__icon__check" strokeWidth={2} height={12} onClick={handleCheckbox} />
        </div>
      ) : (
        <div className="checkbox__icon">
          <IconSquare className="checkbox__icon__square" strokeWidth={1} height={15} onClick={handleCheckbox} />
        </div>
      )}
    </CheckboxWrapper>
  );
};
