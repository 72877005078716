// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApplicattionsScreen {
  height: 100%;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ApplicationsWrapper.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".ApplicattionsScreen {\n  height: 100%;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
