// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --greyLine: '#D2D3D4',
}

.static-screen{
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
  .container {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .contain {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-left: 0.995763px solid var(--greyLine);
  }

  .fullScreen {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/styles/StaticScreen.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,WAAW;AACb;EACE;IACE,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,6CAA6C;EAC/C;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,YAAY;EACd","sourcesContent":[":root{\n    --greyLine: '#D2D3D4',\n}\n\n.static-screen{\n  box-sizing: border-box;\n  height: 100%;\n  width: 100%;\n}\n  .container {\n    box-sizing: border-box;\n    display: flex;\n    width: 100%;\n    height: 100%;\n  }\n\n  .contain {\n    box-sizing: border-box;\n    width: 100%;\n    height: 100%;\n    border-left: 0.995763px solid var(--greyLine);\n  }\n\n  .fullScreen {\n    box-sizing: border-box;\n    display: flex;\n    width: 100%;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
