import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import createIcon from '../../assets/images/iconsForm/createIcon.svg';
import { useValidationForm } from '../../hooks/useValidationForm';
import { StepForm } from '../StepForm/StepForm';
import { FormScreenWrapper } from './FormScreen.style';

export const FormScreen = ({ data, onSubmit, form, crudType, maxwidth, arrScreen, reducerForm, title = true, dataModalButtonToTable }) => {
  const [selectedId, setSelectedId] = useState(1);
  const { validationForm } = useValidationForm();

  const handleNextStep = () => {
    setSelectedId(selectedId + 1);
  };

  const [stepForm, setStepForm] = useState({
    inputFull: false,
    button: 0,
  });

  const [inputValue, setInputValue] = useState(' ');
  const [errorInput, setErrorInput] = useState(false);
  const [validationNormalInput, setValidationNormalInput] = useState([]);
  const [inputValueUnique, setInputValueUnique] = useState([]);

  const [validateReducer, setValidateReducer] = useState([]);

  const variants = {
    initial: {
      opacity: 0,
      y: '0px',
      height: '0px',
      transition: {
        duration: 0.4,
        ease: 'easeInOut',
        opacity: {
          delay: 0.4,
        },
      },
    },
    animate: {
      opacity: 1,
      y: '0px',
      height: '100%',
      transition: {
        duration: 0.4,
        ease: 'easeInOut',
        opacity: {
          delay: 0.4,
        },
      },
    },
    exit: {
      opacity: 1,
      y: '0px',
      height: '100%',
      transition: {
        duration: 0.4,
        ease: 'easeInOut',
        opacity: {
          delay: 0.4,
        },
      },
    },
  };

  const checkForm = () => {
    const invalidInput = [];

    Object.entries(inputValue).map((item) =>
      data.steps.map((step) =>
        step.input.forEach((item2) => {
          if (item2.ref?.element === item[0]) {
            if (item2.required.validation) {
              let regexExpression = item2.required.regex;
              let validate = regexExpression.test(item[1]);
              if (!validate || item[1] === undefined) {
                invalidInput.push(item);
              }
            }
          }
        })
      )
    );
    if (invalidInput.length !== 0) {
      const invalidInputKeys = invalidInput.map((item) => item[0]);
      setValidationNormalInput(invalidInputKeys);
      setErrorInput(true);
    } else {
      setErrorInput(false);
      setValidationNormalInput([]);
      if (selectedId === data.steps.length || crudType === 'edit') {
        let responseReducerValidate = [];
        if (reducerForm !== undefined) {
          responseReducerValidate = validationForm(reducerForm, data);
          setValidateReducer(responseReducerValidate);
        }

        if (responseReducerValidate.length === 0 && inputValueUnique.length === 0) {
          onSubmit();
        }
      } else {
        if (inputValueUnique.length === 0) handleNextStep();
      }
    }
  };

  const ref = React.createRef();

  return (
    <FormScreenWrapper maxwidth={maxwidth}>
      {title && (
        <div className="container__title">
          <img src={createIcon} alt="create icon" />
          <p className="contain__title">{crudType === 'edit' ? data.titleEdit : data.title}</p>
        </div>
      )}

      <div className="container_form">
        <div className="container__cards">
          <AnimatePresence>
            {data.steps.map((item, index) => (
              <div className="contain__inputCard-component" key={index}>
                {selectedId >= item.id || crudType === 'edit' ? (
                  <StepForm
                    dataForm={item}
                    isOpen={true}
                    layoutId={item.id}
                    variants={variants}
                    initial={'initial'}
                    animate={'animate'}
                    exit={'exit'}
                    handleNextStep={handleNextStep}
                    ref={ref}
                    form={form}
                    crudType={crudType}
                    selectedId={selectedId}
                    stepForm={stepForm}
                    setStepForm={setStepForm}
                    errorInput={errorInput}
                    setErrorInput={setErrorInput}
                    validationNormalInput={validationNormalInput}
                    setValidationNormalInput={setValidationNormalInput}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    checkForm={checkForm}
                    allSteps={data.steps}
                    maxwidth={maxwidth}
                    arrScreen={arrScreen}
                    inputValueUnique={inputValueUnique}
                    setInputValueUnique={setInputValueUnique}
                    //
                    validateReducer={validateReducer}
                    setValidateReducer={setValidateReducer}
                    reducerForm={reducerForm}
                    data={data}
                    //
                    dataModalButtonToTable={dataModalButtonToTable}
                  />
                ) : null}
              </div>
            ))}
          </AnimatePresence>
        </div>

        <motion.div className="contain__button-save" variants={variants} initial={'initial'} animate={'animate'} exit={'exit'}>
          <button onClick={checkForm} className={crudType === 'edit' ? 'save_edit_button' : selectedId === data.steps.length ? 'save_button' : 'next_step'}>
            {crudType === 'edit' ? 'Guardar nuevos cambios' : selectedId === data.steps.length ? data.textButton : 'Siguiente paso'}
          </button>
        </motion.div>
      </div>
    </FormScreenWrapper>
  );
};
