import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const NavMenuLeftWrapper = styled.div`
  box-sizing: border-box;
  width: ${(props) => props.width};
  transition: all 1s;
  transition-delay: ${(p) => (p.isClosed ? 0.5 : 0)}s;
  height: 100%;
  background-color: ${colors.primaryColor};

  .contain__logo {
    box-sizing: border-box;
    transition: all 1s;
    transition-delay: ${(p) => (p.isClosed ? 0.5 : 0)}s;
    height: 60px;
    padding-top: 25px;
    width: 232px;
    img {
      width: 183.2px;
      height: 32px;
    }
  }
  .contain__logo-close {
    box-sizing: border-box;
    transition: all 1s;
    transition-delay: ${(p) => (p.isClosed ? 0.5 : 0)}s;
    height: 60px;
    padding-top: 25px;
    width: 53px;
    img {
      width: 183.2px;
      height: 32px;
    }
  }

  .contain__navMenuLeft {
    box-sizing: border-box;
    height: calc(100% - 60px);
  }
`;
