import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const FilterWrapper = styled.div`
  height: 100%;

  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    height: 100%;
    border-radius: 10px;
    width: 80px;

    img {
      height: 16px;
      width: 16px;
    }
    p {
      margin: 0;
      color: ${colors.black};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.button};
      font-family: ${fontFamily.font};
    }

    &:hover {
      cursor: pointer;
      background-color: ${colors.secondaryColor};
      transition: all 0.3s;
      p {
        color: ${colors.primaryColor};
      }
      img {
        filter: invert(30%) sepia(41%) saturate(1358%) hue-rotate(240deg) brightness(97%) contrast(94%);
      }
    }
  }

  .filter__active {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    height: 100%;
    border-radius: 10px;
    width: 80px;
    background-color: ${colors.secondaryColor};
    transition: all 0.3s;

    .filter__icon {
      color: ${colors.primaryColor};
    }
    p {
      margin: 0;
      color: ${colors.primaryColor};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.button};
      font-family: ${fontFamily.font};
    }

    &:hover {
      cursor: pointer;
      background-color: ${colors.white};
      transition: all 0.3s;
      p {
        color: ${colors.black};
      }
      img {
        filter: none;
      }
    }
  }
`;
