import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NumberingScreen } from '../../screens/NumberingScreen/NumberingScreen';
import { OverviewScreen } from '../../screens/OverviewScreen/OverviewScreen';
import { WideWrapper } from './WideNavigation.style';
import { routesWide } from '../routes';
import { EMScreen } from '../../screens/EMScreen/EMScreen';
import ApplicationsScreen from '../../screens/ApplicationsScreen/ApplicationsScreen';
import PreferencesScreen from '../../screens/PreferencesScreen/PreferencesScreen';
import { SettingsScreen } from '../../screens/SettingsScreen/SettingsScreen';
import { CallRegisterScreen } from '../../screens/CallRegisterScreen/CallRegisterScreen';
import { OutboundScreen } from '../../screens/OutboundScreen/OutboundScreen';
import { ProfileScreen } from '../../screens/ProfileScreen/ProfileScreen';
import { HelpScreen } from '../../screens/HelpScreen/HelpScreen';

const WideNavigation = () => {
  return (
    <WideWrapper>
      <Routes>
        <Route path="/" element={<Navigate to="/EM" />} />
        <Route path={routesWide.overview} element={<OverviewScreen />} />
        <Route path={`${routesWide.numbering}/*`} element={<NumberingScreen />} />
        <Route path={`${routesWide.applications}/*`} element={<ApplicationsScreen />} />
        <Route path={`${routesWide.cdr}/*`} element={<CallRegisterScreen />} />
        <Route path={`${routesWide.outboundRoutes}/*`} element={<OutboundScreen />} />
        <Route path={`${routesWide.preferences}/*`} element={<PreferencesScreen />} />
        <Route path={`${routesWide.EM}/*`} element={<EMScreen />} />
        <Route path={`${routesWide.profile}/*`} element={<ProfileScreen />} />
        <Route path={`${routesWide.help}/*`} element={<HelpScreen />} />
        <Route path={`${routesWide.settings}/*`} element={<SettingsScreen />} />
      </Routes>
    </WideWrapper>
  );
};

export default WideNavigation;
