import home from '../../assets/images/applications/home.svg';
import locutions from '../../assets/images/applications/microphone.svg';
import music from '../../assets/images/applications/music.svg';
import phone from '../../assets/images/applications/phone.svg';
import users from '../../assets/images/applications/users.svg';
import click from '../../assets/images/notFound/click.svg';
import arrowDown from '../../assets/images/phone/arrowDown.svg';
import arrowRight from '../../assets/images/phone/arrowRight.svg';
import arrowUp from '../../assets/images/phone/arrowUp.svg';
import cross from '../../assets/images/phone/cross.svg';

import { IconMicrophone2, IconMicrophone, IconMail, IconPhone, IconPlaylist, IconTableShortcut, IconPhonePlus } from '@tabler/icons-react';

// Form Icons
import stethoscope from '../../assets/images/forms/stethoscope.svg';
import textInput from '../../assets/images/forms/textInput.svg';
import toggle from '../../assets/images/forms/toggle-left.svg';

import { routesApplications } from '../../navigation/routes';
import { typeRegex } from '../dataRegex';

export const menuApplications = [
  {
    text: 'Conferencias',
    path: routesApplications.conference,
    icon: IconMicrophone2,
    restricted: 'conference',
    id: 1,
    content: 'Crea conferencias para tus extensiones',
  },
  {
    text: 'Locuciones',
    path: routesApplications.locutions,
    icon: IconMicrophone,
    restricted: 'locutions',
    id: 2,
    content: 'Crea locuciones para tus extensiones',
  },
  {
    text: 'Buzones',
    path: routesApplications.mailboxes,
    icon: IconMail,
    restricted: 'mailboxes',
    id: 3,
    content: 'Crea buzones para tus extensiones',
  },
  {
    text: 'Ivr',
    path: routesApplications.ivr,
    icon: IconPhone,
    restricted: 'ivr',
  },
  {
    text: 'Música en espera',
    path: routesApplications.songWaiting,
    icon: IconPlaylist,
    restricted: 'songWaiting',
    id: 5,
    content: 'Crea música en espera para tus extensiones',
  },
  {
    text: 'Marcación abreviada',
    path: routesApplications.shortMarking,
    icon: IconTableShortcut,
    restricted: 'shortMarking',
    id: 6,
    content: 'Crea marcaciones abreviadas para tus extensiones',
  },
  {
    text: 'Colas',
    path: routesApplications.queue,
    icon: IconPhonePlus,
    restricted: 'queue',
  },
];

export const formCreateLocutions = {
  title: 'Configuración de una nueva locución',
  titleEdit: 'Edita la locución',
  textButton: 'Crear Locución',
  steps: [
    {
      number: '1',
      title: 'Nombre, locución y sede',
      id: 1,
      nextStepText: 'Siguiente Paso',
      columns: 2,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'locution', element: 'description', unique: true },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
            errorMessageUnique: '* Ya existe una locución con este nombre',
          },
        },
        {
          layoutId: 1,
          cardType: 'dropzone',
          data: 'locutionsFile',
          ref: { form: 'locution', element: 'file' },
          defaultValues: {},
          acceptFile: {
            'audio/mp3': ['.mp3'],
            'audio/mpeg': ['.mp3'],
            'audio/wav': ['.wav'],
          },
          required: {
            text: '* Locución',
            validation: true,
            errorMessage: '* Carge un archivo de audio',
            regex: typeRegex.inputDropzone,
          },
        },
        {
          layoutId: 1,
          cardType: 'select',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'locution', element: 'headquarters' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenLocutions = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna locución creada',
    subtitle: 'Empieza por configurar una nueva locución para visualizar en tu escritorio',
    textButton: 'Crear locución',
  },
  icon: {
    home,
    locutions,
  },
};

export const dataFilterLocutions = {
  filters: [
    {
      label: 'Grupo',
      type: 'select',
      ref: 'group',
      options: [],
    },
  ],
};

export const formCreateConference = {
  title: 'Configuración de una nueva conferencia',
  titleEdit: 'Edita la conferencia',
  textButton: 'Crear conferencia',
  steps: [
    {
      number: '1',
      title: 'Nombre, Número, Sede y Pin',
      id: 1,
      lastStep: true,
      nextStepText: 'Siguiente Paso',
      columns: 2,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'conference', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número',
          placeholder: 'Número',
          icon: textInput,
          ref: { form: 'conference', element: 'number' },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Solo números',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'conference', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'toggle',
          label: 'Activa o desactiva el registro',
          icon: toggle,
          ref: { form: 'conference', element: 'record' },
          defaultValues: false,
          required: {
            text: 'Activa o desactiva',
            validation: false,
            errorMessage: '',
            regex: /^/,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Pin',
          placeholder: 'Pin',
          icon: textInput,
          ref: { form: 'conference', element: 'pinConf' },
          defaultValues: null,
          required: {
            text: '* Pin',
            validation: true,
            errorMessage: '* Solo números',
            regex: typeRegex.inputNumber,
          },
        },
      ],
    },
  ],
};

export const dataScreenConferencesRoom = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna conferencia creada',
    subtitle: 'Empieza por configurar una nueva conferencia para visualizar en tu escritorio',
    textButton: 'Crear conferencia',
  },
  icon: {
    // users,
    // home,
  },
};

export const dataFilterConferences = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Grabación',
      type: 'select',
      ref: 'record',
      options: [],
    },
  ],
};

export const formCreateMailboxes = {
  title: 'Configuración de un nuevo buzón',
  titleEdit: 'Edita el buzón',
  textButton: 'Crear buzón',
  steps: [
    {
      number: '1',
      title: 'Nombre, Número y Pin',
      id: 1,
      nextStepText: 'Siguiente Paso',

      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número',
          placeholder: 'Número',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'number' },
          defaultValues: null,
          required: {
            text: '* Número',
            validation: true,
            errorMessage: '* Números',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Pin',
          placeholder: 'Pin',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'pin' },
          defaultValues: null,
          required: {
            text: '* Pin',
            validation: true,
            errorMessage: '* 6 números',
            regex: typeRegex.inputOnlyNumbers,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Sede, Email, Locución',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      columns: 2,
      input: [
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'mailboxes', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          label: 'Email',
          placeholder: 'Email',
          icon: textInput,
          ref: { form: 'mailboxes', element: 'email' },
          defaultValues: null,
          required: {
            text: '* Email',
            validation: true,
            errorMessage: '* Mail incorrecto',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Locución',
          placeholder: 'Locución',
          icon: stethoscope,
          ref: { form: 'mailboxes', element: 'soundPrompt' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Locución',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenMailBoxes = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay nigun buzón creado',
    subtitle: 'Empieza por configurar un nuevo buzón para visualizar en tu escritorio',
    textButton: 'Crear buzón',
  },
  icon: {
    users,
    home,
  },
};

export const dataFilterMailboxes = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateSongWaiting = {
  title: 'Configuración de una nueva música de espera',
  titleEdit: 'Edita la música de espera',
  textButton: 'Crear música de espera',
  steps: [
    {
      number: '1',
      title: 'Nombre y Sede',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'songWaiting', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'songWaiting', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Locuciones',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 2,
          cardType: 'multiselect',
          label: 'Locuciones',
          icon: toggle,
          ref: { form: 'songWaiting', element: 'soundPrompt' },
          defaultValues: [],
          options: [],
          required: {
            text: '* Locusiones',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataFilterSongWaiting = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Activo',
      type: 'select',
      ref: 'active',
      options: [],
    },
  ],
};

export const dataScreenSongWaiting = {
  title: [
    {
      text: 'Musica de espera',
      icon: music,
    },
    {
      text: 'Locuciones',
      icon: locutions,
    },
  ],
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna música en espera creada',
    subtitle: 'Empieza por configurar una nueva música en espera para visualizar en tu escritorio',
    textButton: 'Crear música en espera',
  },
  icon: {
    home,
    locutions,
  },
};

export const formCreateShortMarking = {
  title: 'Configuración de una nueva marcación abreviada',
  titleEdit: 'Edita la marcación abreviada',
  textButton: 'Crear marcación abreviada',
  steps: [
    {
      number: '1',
      title: 'Nombre, Número, Destino y Sede',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'nombre' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Número abrev',
          placeholder: 'Número abrev',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'num_corto' },
          defaultValues: null,
          required: {
            text: '* Número abrev',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Destino',
          placeholder: 'Destino',
          icon: textInput,
          ref: { form: 'shortMarking', element: 'destino' },
          defaultValues: null,
          required: {
            text: '* Destino',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'select-option',
          label: 'Sede',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'shortMarking', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const dataScreenShortMarking = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay niguna marcación abreviada creada',
    subtitle: 'Empieza por configurar un nueva marcación abreviada para visualizar en tu escritorio',
    textButton: 'Crear marcación abreviada',
  },
  icon: {
    users,
    home,
  },
};

export const dataFilterShortMarking = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateIvr = {
  title: 'Configuración de una nueva Ivr',
  titleEdit: 'Edita el Ivr',
  textButton: 'Crear Ivr',
  steps: [
    {
      number: '1',
      title: 'Nombre y Locución, Sede',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Nombre',
          icon: textInput,
          ref: { form: 'ivr', element: 'name' },
          defaultValues: null,
          required: {
            text: '* Nombre',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 caracteres',
            regex: typeRegex.inputText,
          },
        },
        {
          cardType: 'select-option',
          label: 'Locución:',
          placeholder: 'Locución',
          icon: stethoscope,
          ref: { form: 'ivr', element: 'soundPrompt' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Locución',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
        {
          cardType: 'select-option',
          label: 'Sede:',
          placeholder: 'Sede',
          icon: stethoscope,
          ref: { form: 'ivr', element: 'company' },
          options: [],
          defaultValues: {},
          required: {
            text: '* Sede',
            validation: true,
            errorMessage: '* Selecciona una opción',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Destinos',
      id: 2,
      lastStep: true,
      input: [
        {
          cardType: 'phone',

          label: 'Selecciona el número de destino que desees y  escoge en el desplegable dónde quieres dirigir la llamada. ',
          icon: phone,
          ref: { form: 'ivr', element: 'destination' },
          defaultValues: null,

          required: {
            text: 'Selecciona el número de destino que desees y  escoge en el desplegable dónde quieres dirigir la llamada.',
            validation: true,
            errorMessage: '* Crea un destino',
            regex: /^/,
          },
        },
      ],
    },
  ],
};

export const dataFilterIvr = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
    {
      label: 'Activo',
      type: 'select',
      ref: 'active',
      options: [],
    },
  ],
};

export const dataScreenIvr = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna Ivr creada',
    subtitle: 'Empieza por configurar una nueva Ivr en espera para visualizar en tu escritorio',
    textButton: 'Crear Ivr',
  },
  icon: {},

  phone: {
    data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'],
    text: 'Tecla',
    timeOut: 'Time Out',
    notPressed: 'Si no se pulsa',
    icon: {
      arrowDown: {
        img: arrowDown,
        alt: 'arrowDown',
      },
      arrowRight: {
        img: arrowRight,
        alt: 'arrowRight',
      },
      arrowUp: {
        img: arrowUp,
        alt: 'arrowUp',
      },
      cross: {
        img: cross,
        alt: 'cross',
      },
    },
  },
};

export const dataScreenQueue = {
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna cola creada',
    subtitle: 'Empieza por configurar una nueva cola para visualizar en tu escritorio',
    textButton: 'Crear cola',
  },
  icon: {
    // users,
    // home,
  },
};

export const dataFilterQueue = {
  filters: [
    {
      label: 'Sede',
      type: 'select',
      ref: 'company',
      options: [],
    },
  ],
};

export const formCreateQueue = {
  title: 'Configuración de una nueva cola',
  titleEdit: 'Edita la cola',
  tabs: [
    {
      textButton: 'Crear cola',
      id: 1,
      text: 'Colas y agentes',
      steps: [
        {
          number: '1',
          title: 'Cola',
          id: 1,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'input',
              label: 'Nombre',
              placeholder: 'Nombre',
              icon: textInput,
              ref: { form: 'queue', element: 'name' },
              defaultValues: null,
              required: {
                text: '* Nombre',
                validation: true,
                errorMessage: '* Debe tener entre 3 y 50 caracteres',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'input',
              label: 'Número',
              placeholder: 'Número',
              icon: textInput,
              ref: { form: 'queue', element: 'number' },
              defaultValues: null,
              required: {
                text: '* Número',
                validation: true,
                errorMessage: '* Mínimo 3 números',
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Número máx. de personas en espera',
              placeholder: 'Número máx. de personas en espera',
              icon: textInput,
              ref: { form: 'queue', element: 'max_waiting_users' },
              defaultValues: null,
              required: {
                text: 'Número máx. de personas en espera',
                validation: false,
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo de ring por extensión',
              placeholder: 'Tiempo de ring por extensión',
              icon: textInput,
              ref: { form: 'queue', element: 'call_to_use_extensions' },
              defaultValues: null,
              required: {
                text: 'Tiempo de ring por extensión',
                validation: false,
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Tiempo max. de estancia en cola',
              placeholder: 'Tiempo max. de estancia en cola',
              icon: textInput,
              ref: { form: 'queue', element: 'timeout_queue' },
              defaultValues: null,
              required: {
                text: 'Tiempo max. de estancia en cola',
                validation: false,
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Reintentos',
              placeholder: 'Reintentos',
              icon: textInput,
              ref: { form: 'queue', element: 'retry' },
              defaultValues: null,
              required: {
                text: 'Reintentos',
                validation: false,
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'input',
              label: 'Prioridad de cola',
              placeholder: 'Prioridad de cola',
              icon: stethoscope,
              ref: { form: 'queue', element: 'timeout_priority' },
              options: [],
              defaultValues: {},
              required: {
                text: 'Prioridad de cola',
                validation: false,
                regex: typeRegex.inputNumber,
              },
            },
            {
              cardType: 'select-option',
              label: 'Sede',
              placeholder: 'Sede',
              icon: stethoscope,
              ref: { form: 'queue', element: 'companyId' },
              options: [],
              defaultValues: {},
              required: {
                text: '* Sede',
                validation: true,
                errorMessage: '* Selecciona una opción',
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'select-option',
              label: 'Música en espera',
              placeholder: 'Música en espera',
              icon: stethoscope,
              ref: { form: 'queue', element: 'musicclass' },
              options: [],
              defaultValues: {},
              required: {
                text: 'Música en espera',
                validation: false,
                regex: typeRegex.inputText,
              },
            },
            {
              cardType: 'destinations',
              label: 'Destino si no hay respuesta',
              placeholder: 'Destino si no hay respuesta',
              icon: stethoscope,
              ref: { form: 'queue', element: 'destination' },
              options: [],
              defaultValues: {},
              required: {
                text: 'Destino si no hay respuesta',
                validation: false,
                regex: typeRegex.inputText,
              },
            },
          ],
        },
        {
          number: '2',
          title: 'Selección múltiple según preferencias',
          id: 2,
          lastStep: true,
          nextStepText: 'Siguiente Paso',
          input: [
            {
              cardType: 'radio',
              label: 'Selección múltiple según preferencias',
              placeholder: 'Selección múltiple según preferencias',
              icon: stethoscope,
              ref: { form: 'queue', element: 'preferences' },
              defaultValues: '',
              radioType: 'multi',
              options: [
                { label: 'Grabar', value: 'grabar', id: 1 },
                { label: 'Llamar a extensiones en uso', value: 'llamar', id: 2 },
                { label: 'Desborde automático si no hay agentes disponibles', value: 'desborde', id: 3 },
                {
                  label: 'Realiza encuesta',
                  value: 'encuesta',
                  id: 4,
                  selectData: {
                    label: 'Locución encuesta',
                    placeholder: 'Locución encuesta',
                    ref: { form: 'queue', element: 'encuesta' },
                    options: [],
                    defaultValues: {},
                  },
                },
                { label: 'Expulsión automática de la cola en uso en caso de no disponer de agentes', value: 'expulsion', id: 5 },
              ],
              required: {
                text: 'Selección múltiple según preferencias',
                validation: false,
                errorMessage: 'Selecciona una opción',
                regex: typeRegex.inputText,
              },
            },
          ],
        },
        {
          number: '3',
          title: 'Agentes',
          id: 3,
          lastStep: true,
          nextStepText: 'Siguiente Paso',
          ref: { form: 'queue', element: 'agents' },
          input: [
            {
              cardType: 'cardSlider',
              label: 'Selecciona a los agentes y destinales una estrategia',
              navSlider: {
                buttonAdd: {
                  text: 'Añadir agente',
                  options: [],
                },
                selectStrategy: {
                  ref: { form: 'queue', element: 'selectStrategy' },
                  placeholder: 'Estrategia',
                  label: '* Estrategia',
                  options: [
                    {
                      label: 'Todos a la vez',
                      value: 1,
                    },
                    {
                      label: 'Por orden',
                      value: 2,
                    },
                    {
                      label: 'Por orden grupal',
                      value: 3,
                    },
                    // {
                    //     label: 'Por orden circular',
                    //     value: 'por orden circular',
                    // },
                    // {
                    //     label: 'Más tiempo en espera',
                    //     value: 'mas tiempo en espera',
                    // },
                    // {
                    //     label: 'Menos llamadas atendidas',
                    //     value: 'menos llamadas atendidas',
                    // },
                    // {
                    //     label: 'Aleatorio',
                    //     value: 'aleatorio',
                    // },
                  ],
                  defaultValues: {
                    label: 'Todos a la vez',
                    value: 1,
                  },
                },
              },
            },
          ],
        },
      ],
    },
    // {
    //     id: 2,
    //     text: 'Avisos',
    //     steps: [
    //         {
    //             number: '1',
    //             title: 'Aviso Agente',
    //             id: 1,
    //             nextStepText: 'Siguiente Paso',
    //             input: [
    //                 {
    //                     cardType: 'input',
    //                     label: 'Tiempo de descanso',
    //                     placeholder: 'Tiempo de descanso',
    //                     icon: textInput,
    //                     ref: { form: 'queue', element: 'time' },
    //                     defaultValues: null,
    //                     required: {
    //                         text: '* Tiempo de descanso',
    //                         validation: true,
    //                         errorMessage: '* Debe tener entre 3 y 50 caracteres',
    //                         regex: /^/,
    //                     },
    //                 },
    //             ],
    //         },
    //         {},
    //     ],
    // },
  ],
};
