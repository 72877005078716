import React from 'react';

import useUsers from '../../hooks/useUsers';
import { ButtonPassword } from '../ButtonPassword/ButtonPassword';
import { useSwal } from '../../hooks/useSwal';

import { ChangePasswordWrapper } from './ChangePassword.style';

export const ChangePassword = ({ row }) => {
  const { typeSwal } = useSwal();
  const { changePassword } = useUsers();

  const userData = (data) => {
    typeSwal('changePassword').then((result) => {
      if (result.isConfirmed) {
        typeSwal('newPassword', data, changePassword);
      }
    });
  };

  return (
    <ChangePasswordWrapper>
      <ButtonPassword onClick={() => userData(row.original)} />
    </ChangePasswordWrapper>
  );
};
