import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const ScheduleFormWrapper = styled.div`
  border: 1px solid #d2d3d4;
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

    button {
      border: none;
      background-color: ${colors.blue};
      color: white;
      padding: 10px 20px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: ${colors.blueHover};
      }
    }
  }
`;
