import axios from 'axios';

import { getSchedulesAction, getCalendarAction } from '../actions/preferences/actionCreators';

const API = process.env.REACT_APP_URL;

// Schedule

const joinHelper = (scheduleArray) => {
  const updatedScheduleArray = scheduleArray.map((schedule) => {
    let i = 0;

    while (i < schedule.length) {
      schedule[i].print = true;
      schedule[i].ScheduleTime.hourInit = schedule[i].ScheduleTime.hourInit.slice(0, 5);
      schedule[i].ScheduleTime.hourEnd = schedule[i].ScheduleTime.hourEnd.slice(0, 5);
      schedule[i].scheduleString = schedule[i].ScheduleTime.hourInit + ' - ' + schedule[i].ScheduleTime.hourEnd;

      const actualId = i;
      const actualDay = schedule[i].ScheduleTime.weekDay;
      ++i;

      while (i < schedule.length && schedule[i].ScheduleTime.weekDay === actualDay) {
        schedule[i].ScheduleTime.hourInit = schedule[i].ScheduleTime.hourInit.slice(0, 5);
        schedule[i].ScheduleTime.hourEnd = schedule[i].ScheduleTime.hourEnd.slice(0, 5);
        schedule[actualId].scheduleString += '</br> ' + schedule[i].ScheduleTime.hourInit + ' - ' + schedule[i].ScheduleTime.hourEnd;
        ++i;
      }
    }
    return schedule;
  });
  return updatedScheduleArray;
};

export const createScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var respone = await axios.post(`${API}preferences/schedule/create`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    return respone;
  }
};

export const getSchedulesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const { data } = await axios.get(`${API}preferences/schedule`);
    dispatch(getSchedulesAction(joinHelper(data)));
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const updateScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var respone = await axios.patch(`${API}preferences/schedule/update`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    return respone;
  }
};

export const deleteScheduleThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var respone = await axios.delete(`${API}preferences/schedule/delete`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    // setLoading(false)
    return respone;
  }
};

// Calendar

export const getCalendarThunk = (setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const { data } = await axios.get(`${API}preferences/calendar`);
    dispatch(getCalendarAction(data));
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const createCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var data = await axios.post(`${API}preferences/calendar/create`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    return data;
  }
};

export const updateCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var data = await axios.patch(`${API}preferences/calendar/update`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
    return data;
  }
};

export const deleteCalendarThunk = async (payload, setLoading) => {
  setLoading(true);

  try {
    var data = await axios.delete(`${API}preferences/calendar/delete`, { data: payload });
  } catch (error) {
    console.log(error);
  } finally {
    return data;
  }
};
