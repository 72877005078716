import styled from 'styled-components';

export const ContainerCalendar = styled.section`
  width: 98%;

  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 12px;

    button {
      border: none;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #7f40a6;
        color: white;
      }
    }
  }

  .months_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: center;
    gap: 20px;
    font-size: 9px;
  }

  .edit_container {
    width: 100%;
  }

  .week_buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      border: none;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
    }
  }

  .edit_months {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    gap: 20px;
    font-size: 9px;
  }
`;
