import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CalendarFormScreen } from '../../screens/PreferencesScreen/CalendarScreen/CalendarFormScreen';
import { CalendarScreen } from '../../screens/PreferencesScreen/CalendarScreen/CalendarScreen';
import { OverviewPreferencesScreen } from '../../screens/PreferencesScreen/OverviewPreferencesScreen/OverviewPreferencesScreen';
import { ProtocolSetupScreen } from '../../screens/PreferencesScreen/ProtocolSetupScreen/ProtocolSetupScreen';
import ScheduleScreen from '../../screens/PreferencesScreen/ScheduleScreen/ScheduleScreen';
import ScheduleFormScreen from '../../screens/PreferencesScreen/ScheduleScreen/ScheduleFormScreen';
import { routesPreferences } from '../routes';
import { PreferencesNavigationWrapper } from './PreferencesNavigation.style';
import { CompanyScreen } from '../../screens/PreferencesScreen/CompanyScreen/CompanyScreen';
import { DepartmentScreen } from '../../screens/PreferencesScreen/DepartmentScreen/DepartmentScreen';
import { DepartmentForm } from '../../screens/PreferencesScreen/DepartmentScreen/DepartmentForm';
import { CompanyForm } from '../../screens/PreferencesScreen/CompanyScreen/CompanyForm';

const PreferencesNavigation = () => {
  return (
    <PreferencesNavigationWrapper>
      <Routes>
        <Route path="/" element={<OverviewPreferencesScreen />} />
        <Route path={routesPreferences.calendar} element={<CalendarScreen />} />
        <Route path={routesPreferences.createCalendar} element={<CalendarFormScreen crudType={'create'} />} />
        <Route path={routesPreferences.updateCalendar} element={<CalendarFormScreen crudType={'edit'} />} />
        <Route path={routesPreferences.schedule} element={<ScheduleScreen />} />
        <Route path={routesPreferences.createSchedule} element={<ScheduleFormScreen crudType={'create'} />} />
        <Route path={routesPreferences.updateSchedule} element={<ScheduleFormScreen crudType={'edit'} />} />
        <Route path={routesPreferences.protocolSetup} element={<ProtocolSetupScreen />} />
        <Route path={routesPreferences.company} element={<CompanyScreen />} />
        <Route path={routesPreferences.createCompany} element={<CompanyForm crudType={'create'} />} />
        <Route path={routesPreferences.updateCompany} element={<CompanyForm crudType={'edit'} />} />
        <Route path={routesPreferences.department} element={<DepartmentScreen />} />
        <Route path={routesPreferences.createDepartment} element={<DepartmentForm crudType={'create'} />} />
        <Route path={routesPreferences.updateDepartment} element={<DepartmentForm crudType={'edit'} />} />
      </Routes>
    </PreferencesNavigationWrapper>
  );
};

export default PreferencesNavigation;
