import React from 'react';
import { motion } from 'framer-motion';

import eye from '../../assets/images/setting/eye.svg';
import eyeOff from '../../assets/images/setting/eyeOff.svg';
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch';
import { colors } from '../../theme/colors';

import { CardDropDownWrapper, CardOptionsWrapper, CardWrapper } from './CardDropDown.style';

export const CardDropDrown = ({ item, selectedId, submenusId, toggleSwitch, setSelectedId, layout }) => {
  const variants = {
    initial: {
      scale: 1,
      opacity: 0,
      when: 'afterChildren',
    },
    enter: {
      scale: 1,
      opacity: 1,
      when: 'afterChildren',
    },
    exit: {
      scale: 0.95,
      opacity: 0,
      transition: {
        easings: 'easeInOut',
        when: 'afterChildren',
      },
    },
  };

  const openOptions = (id) => {
    setSelectedId(id);
  };

  return (
    <CardDropDownWrapper>
      {selectedId === '' ? (
        <CardWrapper onClick={() => openOptions(item.id)} layout={layout}>
          <div className="cardDropDown">
            <div className="cardDropDown__head">
              <div className="cardDropDown__title">
                <img src={item.icon} alt={item.title} />
                <p>{item.title}</p>
              </div>
              <div className="cardDropDown__eye">
                <img src={eye} alt="eye" />
              </div>
            </div>
          </div>
        </CardWrapper>
      ) : selectedId === item.id ? (
        <CardWrapper onClick={() => openOptions(item.id)} layout={layout}>
          <div className="cardDropDown">
            <div className="cardDropDown__head">
              <div className="cardDropDown__title">
                <img src={item.icon} alt={item.title} />
                <p>{item.title}</p>
              </div>
              <div className="cardDropDown__eye">
                <img src={eye} alt="eye" style={{ opacity: 0 }} />
              </div>
            </div>
            <motion.div key={item.id} animate={'enter'} exit={'exit'} variants={variants} initial={'initial'}>
              <CardOptionsWrapper>
                {item.subtitle.map((item2) => (
                  <div className="cardDropDown__option" key={item2.id}>
                    <div className="cardDropDown__body">
                      <p>{item2.text}</p>
                    </div>
                    <div className="cardDropDown__toggle">
                      <ToggleSwitch color={colors.primaryColor} data={item2} isActive={submenusId.includes(item2.id) === true ? 1 : 0} toggleSwitch={toggleSwitch} />
                    </div>
                  </div>
                ))}
              </CardOptionsWrapper>
              <div className="cardDropDown__eyeOff">
                <img onClick={() => openOptions('')} src={eyeOff} alt="eyeOff" />
              </div>
            </motion.div>
          </div>
        </CardWrapper>
      ) : (
        <CardWrapper onClick={() => openOptions(item.id)} layout={layout}>
          <div className="cardDropDown">
            <div className="cardDropDown__head">
              <div className="cardDropDown__title">
                <img src={item.icon} alt={item.title} />
                <p>{item.title}</p>
              </div>
              <div className="cardDropDown__eye">
                <img src={eye} alt="eye" />
              </div>
            </div>
          </div>
        </CardWrapper>
      )}
    </CardDropDownWrapper>
  );
};
