import Axios from 'axios';
import jwtDecode from 'jwt-decode';

import { loginUserAction } from '../actions/login/actionCreators';
import { createUsersAction, getUsersAction, getUsersPermissionAction } from '../actions/settingsScreen/user/actionCreators';

const urlApi = process.env.REACT_APP_URL;

export const usersLoginThunk = (user) => async (dispatch) => {
  try {
    const response = await Axios.post(`${urlApi}user/login`, user);
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      const token = response.data.token;
      const userDecode = jwtDecode(token);
      localStorage.setItem('tokenStorage', JSON.stringify({ token }));
      dispatch(loginUserAction(userDecode));
    }
    if (response.status === 203) {
      return response;
    }
  } catch (err) {
    return err;
  }
};

export const usersCreateThunk = (user, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    var response = await Axios.post(`${urlApi}user/create`, user);
    if (response.status === 201) {
      dispatch(createUsersAction(user));
    }
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const usersDeleteThunk = (user, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    var response = await Axios.delete(`${urlApi}user/delete`, {
      data: user,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};

export const usersUpdateThunk = (user, setLoading) => async () => {
  setLoading(true);
  try {
    var response = await Axios.post(`${urlApi}user/update`, {
      data: user,
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const getUsersThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const arrayUsers = await Axios.get(`${urlApi}settings/users`);
    if (arrayUsers.status === 200) {
      dispatch(getUsersAction(arrayUsers.data));
    }
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const uploadFileUsersThunk = (file, setLoading) => async (dispatch) => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);

  try {
    var response = await Axios.post(`${urlApi}user/uploadFile`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

//permissions according to user id
export const getUsersPermissionsThunk = (user) => async (dispatch) => {
  try {
    const response = await Axios.get(`${urlApi}user/permissions/${user}`);
    if (response.status === 200) {
      dispatch(getUsersPermissionAction(response.data));
    }
    return response;
  } catch (err) {
    return err;
  }
};

export const changeUserPassword = (newPasswordUser) => async () => {
  try {
    const response = await Axios.patch(`${urlApi}user/changeUserPassword`, {
      data: newPasswordUser,
    });
    return response;
  } catch (err) {
    console.log(err);
  }
};
