import { actionTypesUser } from './actionTypes';

export const createUsersAction = (user) => {
  return {
    type: actionTypesUser.create,
    user,
  };
};

export const deleteUsersAction = (user) => {
  return {
    type: actionTypesUser.delete,
    user,
  };
};

export const updateUsersAction = (user) => {
  return {
    type: actionTypesUser.update,
    user,
  };
};

export const getUsersAction = (users) => {
  return {
    type: actionTypesUser.get,
    users,
  };
};

export const getUsersPermissionAction = (permissions) => {
  return {
    type: actionTypesUser.getUsersPermission,
    permissions,
  };
};

//actions for user FORM

export const changeUserFormIdAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormId,
    payload,
  };
};
export const changeUserFormNameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormName,
    payload,
  };
};

export const changeUserFormFirstSurnameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormFirstSurname,
    payload,
  };
};

export const changeUserFormSecondSurnameAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormSecondSurname,
    payload,
  };
};

export const changeUserFormEmailAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormEmail,
    payload,
  };
};

export const changeUserFormPasswordAction = (payload) => {
  return {
    type: actionTypesUser.changeUserFormPassword,
    payload,
  };
};

export const addUserFormRolesAction = (payload) => {
  return {
    type: actionTypesUser.addUserFormRoles,
    payload,
  };
};

export const removeUserFormRolesAction = (payload) => {
  return {
    type: actionTypesUser.removeUserFormRoles,
    payload,
  };
};
export const clearUserFormAction = (payload) => {
  return {
    type: actionTypesUser.clearUserForm,
    payload,
  };
};

export const clearUserFormRolesAction = () => {
  return {
    type: actionTypesUser.clearUserFormRoles,
  };
};
