import React, { useState } from 'react';

import createIcon from '../../assets/images/iconsForm/createIcon.svg';
import { FormScreen } from '../FormScreen/FormScreen';

import { PageScreenWrapper } from './PageScreen.style';

export const PageScreen = ({ data, render, setRender, onSubmit, form, crudType, maxwidth, arrScreen, reducerForm, dataModalButtonToTable }) => {
  const [pageSelected, setPageSelected] = useState(1);

  const handlePageSelect = (id) => {
    setRender(!render);
    setPageSelected(id);
  };

  return (
    <PageScreenWrapper id="modalRoot">
      <div className="pageScreen__title">
        <img src={createIcon} alt="create icon" />
        <p className="pageScreen__title__text">{crudType === 'edit' ? data.titleEdit : data.title}</p>
      </div>
      <div className="pageScreen">
        <div className="pageScreen__tabs">
          {data.tabs.map((tab) => (
            <div className={`pageScreen__tabs__tab ${pageSelected === tab.id ? 'active' : 'inactive'}`} key={tab.id} onClick={() => handlePageSelect(tab.id)}>
              <p className={`pageScreen__tabs__tab__item ${pageSelected === tab.id ? 'active' : 'inactive'}`}>{tab.text}</p>
            </div>
          ))}
        </div>
        <div className="pageScreen__form">
          <FormScreen
            data={data.tabs[pageSelected - 1]}
            onSubmit={onSubmit}
            form={form}
            crudType={crudType}
            maxwidth={maxwidth}
            arrScreen={arrScreen}
            title={false}
            dataModalButtonToTable={dataModalButtonToTable}
          />
        </div>
      </div>
    </PageScreenWrapper>
  );
};
