import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const CheckboxWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  .checkbox__icon {
    position: relative;

    &__square,
    &__check {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      color: ${colors.primaryColor};
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
