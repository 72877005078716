import React from 'react';
import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { ProfileScreenWrapper } from './ProfileScreen.style';

export const ProfileScreen = () => {
  return (
    <ProfileScreenWrapper>
      <Header image={menuNavLeft.menuBottom[0].image} text={menuNavLeft.menuBottom[0].text} />
      <MenuTop menu={[]} />
    </ProfileScreenWrapper>
  );
};
