import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { IconX } from '@tabler/icons-react';

import { addDestinationOpenAction, removeDestinationOpenAction } from '../../../redux/actions/numbering/actionCreator';
import { MySelect } from '../../MySelect/MySelect';

import { BodyMultiFormWrapper } from './BodyMultiForm.style';
import useNumbering from '../../../hooks/useNumbering';
import usePreferences from '../../../hooks/usePreferences';
import { DestinationSelect } from '../../DestinationSelect/DestinationSelect';
import { useValidationForm } from '../../../hooks/useValidationForm';

export const BodyMultiForm = ({
  item,
  form,
  dataForm,
  setInputValue,
  setErrorInput,
  setValidationNormalInput,
  validationNormalInput,
  allSteps,
  idPage,
  validateReducer,
  setValidateReducer,
  reducerForm,
  firstData,
  setRemovedDestinationOpen,
  removedDestinationOpen,
}) => {
  const dispatch = useDispatch();
  const [addOption, setAddOption] = useState(0);
  const [newDataForm, setNewDataForm] = useState([]);
  const { FormListNumberingReducer } = useNumbering();
  const { scheduleArrayHook } = usePreferences();
  const { validationForm } = useValidationForm();

  let destinationsOpenReducer = FormListNumberingReducer.destinationCalendar;

  const { calendar, destinationClose, destinationOpen, soundPromptClose } = item;

  const destinationContent = dataForm[1].destinationOpen[0].input;

  let nameUniqueSchedule = [];
  destinationsOpenReducer[idPage - 1]?.destinationOpen.forEach((schedule) => {
    if (schedule.schedule.label !== '') {
      nameUniqueSchedule.push(schedule.schedule);
    }
  });

  dataForm.forEach((obj) => {
    obj.destinationOpen?.forEach((obj2) => {
      obj2.input.forEach((obj3) => {
        if (obj3.ref.element === 'schedule') {
          scheduleArrayHook.forEach((schedule) => {
            if (!schedule.status) {
              schedule.forEach((item7) => {
                const scheduleName = {
                  label: item7.Schedule.name,
                  value: item7.Schedule.id.toString(),
                };
                const found = obj3.options.some((e) => e.value === scheduleName.value);
                const foundUnique = nameUniqueSchedule.some((e) => e.value === scheduleName.value);

                if (!found && !foundUnique) {
                  obj3.options.push(scheduleName);
                }
                if (foundUnique) {
                  obj3.options = obj3.options.filter((e) => e.value !== scheduleName.value);
                }
              });
            }
          });
        }
      });
    });
  });

  useEffect(() => {
    setNewDataForm(
      dataForm.map((item2) => {
        if (item2.input) {
          return {
            ...item2,
            input: item2.input.map((item3) => {
              if (item3.ref.element === 'calendar') {
                if (calendar.label !== '') {
                  return {
                    ...item3,
                    defaultValues: calendar,
                  };
                }
              }
              if (item3.ref.element === 'destinationClose') {
                if (destinationClose.label !== '') {
                  return {
                    ...item3,
                    defaultValues: destinationClose,
                  };
                }
              }
              if (item3.ref.element === 'soundPromptClose') {
                if (soundPromptClose.label !== '') {
                  return {
                    ...item3,
                    defaultValues: soundPromptClose.label === undefined ? {} : soundPromptClose,
                  };
                }
              }
              return item3;
            }),
          };
        }

        return item2;
      })
    );
    
  }, []);

  useEffect(() => {}, [newDataForm]);

  const addOptions = () => {
    const lastObj = destinationOpen.slice(-1)[0];
    const lastId = lastObj.id;
    const newDestinationOpen = {
      ...lastObj,
      id: lastId + 1,
    };

    const newObjectWithRef = {
      newDestinationOpen,
      refId: idPage,
    };

    setAddOption(addOption + 1);
    dispatch(addDestinationOpenAction(newObjectWithRef));
  };

  const removedOptions = (idPage, idDestinationOpen) => {
    const newObjectWithRef = {
      idPage,
      idDestinationOpen,
    };

    dispatch(removeDestinationOpenAction(newObjectWithRef));
    setRemovedDestinationOpen(removedDestinationOpen + 1);

    const index = destinationOpen.findIndex((obj) => obj.id === idDestinationOpen);
    if (index !== -1) {
      destinationOpen.splice(index, 1);
    }
  };

  const onChange = (valueInput, name, idPage, destinationOpen, itemForm) => {
    if (name === 'clear') {
      const arg = {
        action: name,
        removedValues: [{ label: valueInput.ref, value: valueInput.value, optionRefPage: idPage, destinationOpen: destinationOpen }],
      };
      const ref = {
        form: itemForm.ref.form,
        element: itemForm.ref.element,
      };

      if (reducerForm !== undefined) {
        const item = {
          idDestination: destinationOpen === undefined ? null : destinationOpen,
          idPage: idPage,
          action: arg.action,
          element: itemForm.ref.element,
        };

        let responseReducerValidate = validationForm(reducerForm, firstData, item);
        setValidateReducer(responseReducerValidate);
      }

      form(arg, ref);
    } else {
      const arg = {
        action: 'select-option',
      };
      const ref = {
        form: itemForm.ref.form,
        element: itemForm.ref.element,
      };

      const inputValue = {
        label: valueInput.value === 'external' ? valueInput.label : valueInput.ref,
        value: valueInput.value,
        optionRefPage: idPage,
        destinationOpen: destinationOpen,
      };

      if (reducerForm !== undefined) {
        const item = {
          idDestination: destinationOpen === undefined ? null : destinationOpen,
          idPage: idPage,
          action: arg.action,
          element: itemForm.ref.element,
        };

        let responseReducerValidate = validationForm(reducerForm, firstData, item);
        setValidateReducer(responseReducerValidate);
      }

      form(arg, ref, inputValue);
    }
  };

  function checkIfObjectExistsInArray(array, idPage, idDestination, name) {
    const index = array.findIndex((obj) => obj.idPage === idPage && obj.idDestination === idDestination && obj.name === name);

    return index !== -1;
  }

  return (
    <BodyMultiFormWrapper>
      <div className="bodyMultiForm">
        {newDataForm.map((item2, index) => (
          <div key={index}>
            <p className="bodyMultiForm__title">{item2.title}</p>
            <p className="bodyMultiForm__subtitle">{item2.subtitle}</p>

            {item2.id === 1 && idPage === 1 ? (
              <div className="bodyMultiForm__selects">
                <div className="bodyMultiForm__select">
                  {<span className="bodyMultiForm__span">{'*Predeterminado'}</span>}
                  <MySelect defaultValue={{ label: 'Predeterminado', value: 'Predeterminado' }} isDisabled={true} />
                </div>
              </div>
            ) : item2.id !== 2 ? (
              <div className="bodyMultiForm__selects">
                {item2.input?.map((item3, index2) => (
                  <div className="bodyMultiForm__select" key={index2}>
                    <span className="bodyMultiForm__span">{item3.required.text}</span>
                    {item2.id === 3 && index2 === 1 ? (
                      <DestinationSelect onChange={onChange} placeholder={item3.placeholder} idPage={idPage} item={item3} defaultValue={destinationClose.label === '' ? {} : destinationClose} />
                    ) : (
                      <MySelect
                        item={item3}
                        placeholder={item3.placeholder}
                        options={item3.options}
                        defaultValue={item3.defaultValues}
                        isMulti={undefined}
                        isSearchable={false}
                        form={form}
                        dataForm={newDataForm}
                        setInputValue={setInputValue}
                        setErrorInput={setErrorInput}
                        setValidationNormalInput={setValidationNormalInput}
                        validationNormalInput={validationNormalInput}
                        allSteps={allSteps}
                        idPage={idPage}
                        //
                        validateReducer={validateReducer}
                        setValidateReducer={setValidateReducer}
                        reducerForm={reducerForm}
                        firstData={firstData}
                      />
                    )}
                    {checkIfObjectExistsInArray(validateReducer, idPage, null, item3.ref.element) && <span className="error">{item3.required.errorMessage}</span>}
                  </div>
                ))}
              </div>
            ) : (
              <div key={index} className="bodyMultiForm__selects">
                {destinationOpen.map((destination, index) => {
                  return destinationContent.map((content, index2) => {
                    return (
                      <AnimatePresence key={index2} exitBeforeEnter>
                        <motion.div className="bodyMultiForm__select" initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: -10, opacity: 0 }} transition={{ duration: 0.3 }}>
                          {index < 1 && <span className="bodyMultiForm__span">{content.required.text}</span>}
                          {index2 < 2 ? (
                            <MySelect
                              item={content}
                              placeholder={content.placeholder}
                              options={content.options}
                              defaultValue={
                                destinationOpen[index][content.ref.element].label === '' || destinationOpen[index][content.ref.element].label === undefined
                                  ? {}
                                  : { label: destinationOpen[index][content.ref.element].label, value: destinationOpen[index][content.ref.element].value }
                              }
                              isMulti={undefined}
                              isSearchable={false}
                              form={form}
                              dataForm={dataForm}
                              setInputValue={setInputValue}
                              setErrorInput={setErrorInput}
                              setValidationNormalInput={setValidationNormalInput}
                              validationNormalInput={validationNormalInput}
                              allSteps={allSteps}
                              idPage={idPage}
                              destinationOpen={destination.id}
                              validateReducer={validateReducer}
                              setValidateReducer={setValidateReducer}
                              reducerForm={reducerForm}
                              firstData={firstData}
                              value={true}
                            />
                          ) : (
                            <div className="bodyMultiForm__select__destinationSelect">
                              <DestinationSelect
                                onChange={onChange}
                                placeholder={content.placeholder}
                                idPage={idPage}
                                destinationOpen={destination.id}
                                item={content}
                                defaultValue={
                                  destinationOpen[index][content.ref.element].label === ''
                                    ? {}
                                    : { label: destinationOpen[index][content.ref.element].label, value: destinationOpen[index][content.ref.element].value }
                                }
                              />
                              {destinationOpen.length > 1 && <IconX size={15} onClick={() => removedOptions(idPage, destination.id)} className={'bodyMultiForm__select__destinationSelect__removed'} />}
                            </div>
                          )}

                          {checkIfObjectExistsInArray(validateReducer, idPage, destination.id, content.ref.element) && <span className="error">{content.required.errorMessage}</span>}
                        </motion.div>
                      </AnimatePresence>
                    );
                  });
                })}
              </div>
            )}
            {index === 1 ? (
              <button className="bodyMultiForm__button" onClick={addOptions}>
                + Añadir horario
              </button>
            ) : null}
          </div>
        ))}
      </div>
    </BodyMultiFormWrapper>
  );
};
