import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateSongWaiting } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';

export const SongWaitingFormScreen = ({ crudType }) => {
  const { applicationsForm, songWaitingForm, getSongWaitingLink, songWaiting, createSongWaiting, updateSongWaiting, getApplicationsLocutions, arrayLocutionsHook } = useApplications();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);

  const data = formCreateSongWaiting;

  useEffect(() => {
    getHeadquarter();
    getSongWaitingLink();
    getApplicationsLocutions();
    applicationsForm({ action: 'clearForm' }, { form: 'songWaiting' });

    if (crudType === 'edit') {
      let actionId = {
        action: 'input',
        value: location.state.arraySongWaiting[0].Moh.id,
      };
      applicationsForm(actionId, {
        form: 'songWaiting',
        element: 'id',
      });
      let actionName = { action: 'input', value: location.state.title };
      applicationsForm(actionName, {
        form: 'songWaiting',
        element: 'name',
      });
      location.state.arraySongWaiting.map((item) =>
        applicationsForm(
          {
            action: 'select-option',
            option: {
              label: item.Soundprompt.description,
              value: item.Soundprompt.id.toString(),
            },
          },
          {
            form: 'songWaiting',
            element: 'soundPrompt',
          }
        )
      );
      let actionCompany = {
        action: 'select-option',
        option: {
          label: location.state.arraySongWaiting[0].Moh.Company.name,
          value: location.state.arraySongWaiting[0].Moh.Company.id.toString(),
        },
      };
      applicationsForm(actionCompany, {
        form: 'songWaiting',
        element: 'company',
      });
    }
    
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0 && songWaiting.length > 0 && arrayLocutionsHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'company') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref.element === 'soundPrompt') {
              arrayLocutionsHook.forEach((item3) => {
                const locution = {
                  label: item3.description,
                  value: item3.id?.toString(),
                };

                const found = item2.options.some((e) => e.value === locution.value);
                if (!found && locution.value !== undefined) {
                  item2.options.push(locution);
                }
              });
            }
            return item2;
          });
          return item;
        });
        if (crudType === 'edit' && (songWaitingForm.id === '' || songWaitingForm.name !== location.state.title)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit' && songWaitingForm.name === location.state.title) {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'name':
                  item2.defaultValues = songWaitingForm.name;
                  break;
                case 'company':
                  item2.options.filter((item3) => {
                    if (item3.label === songWaitingForm.company.label) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'soundPrompt':
                  item2.defaultValues = [];
                  item2.options.filter((item3) =>
                    songWaitingForm.soundPrompt.forEach((item4) => {
                      if (item3.value === item4.value) {
                        item2.defaultValues.push(item3);
                      }
                    })
                  );
                  break;
                default:
                  break;
              }

              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
    
  }, [loadingCounter, crudType]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getSongWaitingLink, `/${routesWide.applications}/${routesApplications.songWaiting}`, 'shortMarking', createSongWaiting);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getSongWaitingLink, `/${routesWide.applications}/${routesApplications.songWaiting}`, 'shortMarking', updateSongWaiting);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} />}</>;
};
