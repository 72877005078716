import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { dataFilterCategories, dataScreenCategories } from '../../../data/dataOutbound';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';

import { CategoriesOutboundWrapper } from './CategoriesOutboundScreen.style';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';

export const CategoriesOutboundScreen = () => {
  const { getCategoriesHook, categoriesHook, deleteCategories, uploadFileCategories, loading } = useOutbound();
  const { typeSwal } = useSwal();
  const { changeView, isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound, icon } = dataScreenCategories;

  const navigate = useNavigate();

  useEffect(() => {
    getCategoriesHook();
    setSearch([]);
    
  }, [setSearch]);

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Prefijo',
      accessor: 'prefix',
    },
    {
      Header: 'Ruta saliente',
      accessor: 'outboundRoute',
    },
    {
      Header: 'Ruta saliente (Backup)',
      accessor: 'outboundRouteBackup',
    },
  ];

  const DATA = categoriesHook.map(({ description, prefix, id, outboundRouteFirst, outboundRouteFail, failOverOutboundRoutesId, outboundRoutesId }) => ({
    name: description,
    prefix: prefix,
    idCategories: id,
    outboundRoute: outboundRouteFirst?.description ? outboundRouteFirst.description : 'Sin valor',
    outboundRouteBackup: outboundRouteFail?.description ? outboundRouteFail.description : 'Sin valor',
    failOverOutboundRoutesId,
    outboundRoutesId,
    id,
  }));

  const dataExport = categoriesHook.map(({ description, prefix }) => ({
    Nombre: description,
    Prefijo: prefix,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ name, prefix, idCategories, outboundRoute, outboundRouteBackup, failOverOutboundRoutesId, outboundRoutesId }) => ({
        title: name,
        icon: icon.phoneCard,
        subtitle: prefix,
        icon2: icon.trunksIcon,
        text: outboundRoute,
        icon3: icon.trunksIcon,
        text2: outboundRouteBackup,
        name,
        prefix,
        idCategories,
        outboundRoute,
        outboundRouteBackup,
        failOverOutboundRoutesId,
        outboundRoutesId,
      }));
    } else {
      return DATA.map(({ description, prefix, id, outboundRouteFirst, outboundRouteFail, failOverOutboundRoutesId, outboundRoutesId }) => ({
        title: description,
        icon: icon.phoneCard,
        subtitle: prefix,
        icon2: icon.trunksIcon,
        text: outboundRouteFirst?.description ? outboundRouteFirst.description : 'Sin valor',
        icon3: icon.trunksIcon,
        text2: outboundRouteFail?.description ? outboundRouteFail.description : 'Sin valor',
        name: description,
        prefix: prefix,
        idCategories: id,
        outboundRoute: outboundRouteFirst?.description ? outboundRouteFirst.description : 'Sin valor',
        outboundRouteBackup: outboundRouteFail?.description ? outboundRouteFail.description : 'Sin valor',
        failOverOutboundRoutesId,
        outboundRoutesId,
      }));
    }
  };

  const dataToCards = dataCard();

  const handleOpenFormCreate = () => {
    navigate('/outboundRoutes/categories/create', {});
  };

  const handleOpenFormEdit = (item) => {
    navigate('/outboundRoutes/categories/update', {
      state: item.original ? item.original : item,
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteCategories(item.original);
          } else {
            response = await deleteCategories(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getCategoriesHook();
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileCategories(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getCategoriesHook();
        typeSwal('upload');
      }
    } catch (err) {
      return err;
    } finally {
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Nombre: '',
      Prefijo: '',
      'Ruta Saliente Id': '',
      'Ruta Saliente backup Id': '',
    },
  ];

  return (
    <CategoriesOutboundWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },

            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            changeView: {
              position: 'right',
              function: changeView,
              isCardView: isCardView,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
            filter: {
              position: 'left',
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
        {categoriesHook[0]?.status ? null : (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterCategories} setSearch={setSearch} />
          </div>
        )}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : categoriesHook[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          {' '}
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
          />
        </div>
      )}
    </CategoriesOutboundWrapper>
  );
};
