import styled from 'styled-components';
import { colors } from '../../theme/colors';

import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const ButtonWrapper = styled.button`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.hoverBackgroundColor};
    transition: all 0.3s;
    p {
      color: ${(props) => props.hoverColorText};
      transition: all 0.3s;
    }
    .button__icon {
      transition: all 0.3s;
      color: ${(props) => props.hoverIcon};
    }
  }
  p {
    margin: 0;
    color: ${(props) => props.colorText};
    font-size: ${newFontSize.button};
    font-family: ${fontFamily.font};
    font-weight: ${weight.medium};
    transition: all 0.3s;
  }
  .button__icon {
    color: ${colors.white};
    transition: all 0.3s;
    width: 18px;
  }
`;
