export const actionTypesApplications = {
  //actions conferences
  getConferences: 'GET_CONFERENCES',
  createConference: 'CREATE_CONFERENCE',
  updateConference: 'UPDATE_CONFERENCE',
  deleteConference: 'DELETE_CONFERENCE',

  //action form of conference
  changeConferenceFormName: 'CHANGE_CONFERENCE_FORM_NAME',
  changeConferenceFormNumber: 'CHANGE_CONFERENCE_FORM_NUMBER',
  changeConferenceFormPin: 'CHANGE_CONFERENCE_FORM_PIN',
  changeConferenceFormRecord: 'CHANGE_CONFERENCE_FORM_RECORD',
  addConferenceFormCompany: 'CHANGE_CONFERENCE_FORM_COMPANY',
  clearConferenceForm: 'CLEAR_CONFERENCE_FORM',
  changeConferenceFormId: 'CHANGE_CONFERENCE_FORM_ID',
  removeConferencesCompany: 'REMOVE_CONFERENCE_COMPANY',

  //actions locutions
  getLocutions: 'GET_LOCUTIONS',
  createApplicationsLocutions: 'CREATE_APPLICATIONS_LOCUTIONS',
  updateApplicationsLocutions: 'UPDATE_APPLICATIONS_LOCUTIONS',
  deleteApplicationsLocutions: 'DELETE_APPLICATIONS_LOCUTIONS',
  getAudios: 'GET_AUDIOS',

  //actions form of locutions
  changeApplicationsLocutionsFormName: 'CHANGE_LOCUTIONS_FORM_NAME',
  addApplicationsLocutionsFormHeadquarters: 'ADD_LOCUTIONS_FORM_HEADQUARTERS',
  removeApplicationsLocutionsFormHeadquarters: 'REMOVE_LOCUTIONS_FORM_HEADQUARTERS',
  addApplicationsLocutionsFormFile: 'ADD_LOCUTIONS_FORM_FILE',
  clearLocutionsForm: 'CLEAR_LOCUTIONS_FORM',
  changeApplicationsLocutionsFormId: 'CHANGE_LOCUTIONS_FORM_ID',

  //actions mailboxes
  getMailboxes: 'GET_MAILBOXES',
  createMailboxes: 'CREATE_MAILBOXES',
  updateMailboxes: 'UPDATE_MAILBOXES',
  deleteMailboxes: 'DELETE_MAILBOXES',

  //actions form of mailboxes
  changeMailboxesFormNumber: 'CHANGE_MAILBOXES_FORM_NUMBER',
  changeMailboxesFormName: 'CHANGE_MAILBOXES_FORM_NAME',
  changeMailboxesFormPin: 'CHANGE_MAILBOXES_FORM_PIN',
  addMailboxesFormCompany: 'ADD_MAILBOXES_FORM_COMPANY',
  changeMailboxesFormEmail: 'CHANGE_MAILBOXES_FORM_EMAIL',
  addMailboxesFormSoundPrompt: 'ADD_MAILBOXES_FORM_SOUND_PROMPT',
  changeMailboxesFormId: 'CHANGE_MAILBOXES_FORM_ID',
  changeMailboxesFormAstVoicemailId: 'CHANGE_MAILBOXES_FORM_AST_VOICEMAIL_ID',
  clearMailboxesForm: 'CLEAR_MAILBOXES_FORM',
  removeMailboxesCompany: 'REMOVE_MAILBOXES_COMPANY',
  removeMailboxesSoundPrompt: 'REMOVE_MAILBOXES_SOUND_PROMPT',

  //actions songWaiting
  getSongWaiting: 'GET_SONG_WAITING',
  createSongWaiting: 'CREATE_SONG_WAITING',
  updateSongWaiting: 'UPDATE_SONG_WAITING',
  deleteSongWaiting: 'DELETE_SONG_WAITING',
  getSongWaitingLink: 'GET_SONG_WAITING_LINK',
  addSongWaitingFormSoundPrompt: 'ADD_SONG_WAITING_FORM_SOUND_PROMPT',
  removeSongWaitingFormSoundPrompt: 'REMOVE_SONG_WAITING_FORM_SOUND_PROMPT',

  //actions form of songWaiting
  changeSongWaitingFormName: 'CHANGE_SONG_WAITING_FORM_NAME',
  addSongWaitingFormFile: 'ADD_SONG_WAITING_FORM_FILE',
  changeSongWaitingFormActive: 'CHANGE_SONG_WAITING_FORM_ACTIVE',
  addSongWaitingFormCompany: 'ADD_SONG_WAITING_FORM_COMPANY',
  changeSongWaitingFormId: 'CHANGE_SONG_WAITING_FORM_ID',
  clearSongWaitingForm: 'CLEAR_SONG_WAITING_FORM',
  removeSongWaitingFormCompany: 'REMOVE_SONG_WAITING_FORM_COMPANY',
  clearSongWaitingFormSoundPrompt: 'CLEAR_SONG_WAITING_FORM_SOUND_PROMPT',

  //actions ivr
  getIvr: 'GET_IVR',
  createIvr: 'CREATE_IVR',
  updateIvr: 'UPDATE_IVR',
  deleteIvr: 'DELETE_IVR',
  getDestination: 'GET_DESTINATION',

  //actions form of ivr
  changeIvrFormName: 'CHANGE_IVR_FORM_NAME',
  addIvrFormCompany: 'ADD_IVR_FORM_COMPANY',
  addIvrFormSoundPrompt: 'ADD_IVR_FORM_SOUND_PROMPT',
  changeIvrFormId: 'CHANGE_IVR_FORM_ID',
  clearIvrForm: 'CLEAR_IVR_FORM',
  changeIvrFormDestinations: 'CHANGE_IVR_FORM_DESTINATIONS',
  removeIvrFormDestinations: 'REMOVE_IVR_FORM_DESTINATIONS',
  removeIvrCompany: 'REMOVE_IVR_COMPANY',
  removeIvrSoundPrompt: 'REMOVE_IVR_SOUND_PROMPT',

  //actions shortMarking
  getShortMarking: 'GET_SHORT_MARKING',
  createShortMarking: 'CREATE_SHORT_MARKING',
  updateShortMarking: 'UPDATE_SHORT_MARKING',
  deleteShortMarking: 'DELETE_SHORT_MARKING',

  //actions form of shortMarking
  changeShortMarkingFormNombre: 'CHANGE_SHORT_MARKING_FORM_NOMBRE',
  changeShortMarkingFormNumCorto: 'CHANGE_SHORT_MARKING_FORM_NUM_CORTO',
  changeShortMarkingFormDestino: 'CHANGE_SHORT_MARKING_FORM_DESTINO',
  addShortMarkingFormCompany: 'CHANGE_SHORT_MARKING_FORM_COMPANY',
  changeShortMarkingFormId: 'CHANGE_SHORT_MARKING_FORM_ID',
  clearShortMarkingForm: 'CLEAR_SHORT_MARKING_FORM',
  removeShortMarkingCompany: 'REMOVE_SHORT_MARKING_COMPANY',

  //actions queue
  getQueue: 'GET_QUEUE',
  createQueue: 'CREATE_QUEUE',
  updateQueue: 'UPDATE_QUEUE',
  deleteQueue: 'DELETE_QUEUE',

  //actions for of queue
  changeQueueFormName: 'CHANGE_QUEUE_FORM_NAME',
  changeQueueFormNumber: 'CHANGE_QUEUE_FORM_NUMBER',
  changeQueueFormMaxWaitingUsers: 'CHANGE_QUEUE_FORM_MAX_WAITING_USERS',
  changeQueueFormCallToUseExtensions: 'CHANGE_QUEUE_FORM_CALL_TO_USE_EXTENSIONS',
  changeQueueFormTimeoutQueue: 'CHANGE_QUEUE_FORM_TIMEOUT_QUEUE',
  changeQueueFormRetry: 'CHANGE_QUEUE_FORM_RETRY',
  changeQueueFormTimeoutPriority: 'CHANGE_QUEUE_FORM_TIMEOUT_PRIORITY',
  addQueueFormCompanyId: 'ADD_QUEUE_FORM_COMPANY_ID',
  addQueueFormMusicClass: 'ADD_QUEUE_FORM_MUSIC_CLASS',
  addQueueFormDestination: 'ADD_QUEUE_FORM_DESTINATION',
  addQueueFormPreferences: 'ADD_QUEUE_FORM_PREFERENCES',
  addQueueFormSoundPrompt: 'ADD_QUEUE_FORM_SOUND_PROMPT',
  addQueueFormStrategy: 'ADD_QUEUE_FORM_STRATEGY',
  addQueueFormAgents: 'ADD_QUEUE_FORM_AGENTS',
  changeQueueFormAgentIsActive: 'CHANGE_QUEUE_FORM_AGENT_IS_ACTIVE',
  changeQueueFormAgentIsExternal: 'CHANGE_QUEUE_FORM_AGENT_IS_EXTERNAL',
  removeQueueFormAgentDelete: 'REMOVE_QUEUE_FORM_AGENT_DELETE',
  clearQueueFormCompanyId: 'CLEAR_QUEUE_FORM_COMPANY_ID',
  clearQueueFormMusicClass: 'CLEAR_QUEUE_FORM_MUSIC_CLASS',
  clearQueueFormDestination: 'CLEAR_QUEUE_FORM_DESTINATION',
  clearQueueForm: 'CLEAR_QUEUE_FORM',
  changeQueueFormId: 'CHANGE_QUEUE_FORM_ID',
  changeQueueFormIdAstQueue: 'CHANGE_QUEUE_FORM_ID_AST_QUEUE',
};
