import Axios from 'axios';

import { getDepartmentsAction } from '../actions/department/actionCreator';

const urlApi = process.env.REACT_APP_URL;

export const getDepartmentsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const department = await Axios.get(`${urlApi}departments/departments`);
    dispatch(getDepartmentsAction(department.data));
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
  }
};

export const createDepartmentThunk = (department, setLoading) => async () => {
  try {
    var response = await Axios.post(`${urlApi}departments/departments/create`, { data: department });
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
    return response;
  }
};

export const updateDepartmentThunk = (department, setLoading) => async () => {
  setLoading(true);
  try {
    var response = await Axios.patch(`${urlApi}departments/departments/update`, { data: department });
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
    return response;
  }
};

export const deleteDepartmentThunk = (department, setLoading) => async () => {
  setLoading(true);
  try {
    var response = await Axios.delete(`${urlApi}departments/departments/delete`, { data: department });
  } catch (error) {
    return error;
  } finally {
    return response;
  }
};

export const uploadDepartmentThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);
  try {
    var response = await Axios.post(`${urlApi}departments/departments/uploadFile`, data);
  } catch (error) {
    return error;
  } finally {
    return response;
  }
};
