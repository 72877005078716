import React from 'react';
import PropTypes from 'prop-types';

import { HeaderWrapper } from './Header.style';

export const Header = ({ text }) => {
  return (
    <HeaderWrapper>
      <div className="header__title">
        <h1>{text}</h1>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  text: PropTypes.string.isRequired,
};
