import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import usePreferences from '../../../hooks/usePreferences';
import { formPreferences } from '../../../data/dataPreferences';
import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { useSwal } from '../../../hooks/useSwal';

const ScheduleFormScreen = ({ crudType }) => {
  const { preferencesForm, createSchedule, updateSchedule, loading, getScheduleDB } = usePreferences();
  const { typeSwal } = useSwal();

  const location = useLocation();

  useEffect(() => {
    preferencesForm({ action: 'clear', value: '' }, { form: 'schedule', element: '' });

    if (crudType === 'edit') {
      const formState = location.state;
      preferencesForm({ action: 'input', value: formState.schedule[0].Schedule.name }, { form: 'schedule', element: 'name' });
      preferencesForm({ action: 'input', value: formState.scheduleId }, { form: 'schedule', element: 'scheduleId' });
      for (let i = 0; i < formState.schedule.length; i++)
        preferencesForm(
          {
            action: 'input',
            value: formState.schedule[i].scheduleTimeId,
          },
          { form: 'schedule', element: 'scheduleTimeId' }
        );

      formPreferences.steps[0].input[0].defaultValues = formState.schedule[0].Schedule.name;
    }

    
  }, []);

  const onSubmit = async () => {
    if (crudType === 'create') typeSwal('createLoader', '', preferencesForm, getScheduleDB, '/preferences/schedule', 'schedule', createSchedule);
    else if (crudType === 'edit') typeSwal('updateLoader', '', preferencesForm, getScheduleDB, '/preferences/schedule', 'schedule', updateSchedule);
  };

  return <>{loading ? <div /> : <FormScreen data={formPreferences} onSubmit={onSubmit} form={preferencesForm} crudType={crudType} />}</>;
};

export default ScheduleFormScreen;
