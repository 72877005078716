import calendar from '../../assets/images/preferences/calendar.svg';
import clock from '../../assets/images/preferences/clock.svg';
import settings from '../../assets/images/preferences/sitemap.svg';
import stars from '../../assets/images/preferences/stars.svg';
import blue from '../../assets/images/preferences/blue.svg';
import red from '../../assets/images/preferences/red.svg';
import green from '../../assets/images/preferences/green.svg';
import transparent from '../../assets/images/preferences/transparent.svg';
import home from '../../assets/images/applications/home.svg';
import click from '../../assets/images/notFound/click.svg';

import { IconCalendarDue, IconClockHour3, IconHome, IconDoor } from '@tabler/icons-react';

//Form Icons
import textInput from '../../assets/images/forms/textInput.svg';
import schedule from '../../assets/images/forms/clock.svg';

import { typeRegex } from '../dataRegex';

import { routesPreferences } from '../../navigation/routes';

export const menuPreferences = [
  {
    text: 'Calendario',
    path: routesPreferences.calendar,
    icon: IconCalendarDue,
    restricted: 'calendar',
    id: 1,
    content: 'Crea y edita calendarios',
  },
  {
    text: 'Horario',
    path: routesPreferences.schedule,
    icon: IconClockHour3,
    restricted: 'schedule',
    id: 2,
    content: 'Crea y edita horarios',
  },
  // {
  //     text: 'Configuración del protocolo',
  //     path: routesPreferences.protocolSetup,
  //     icon: settings,
  //     restricted: 'protocolSetup',
  // },
  {
    text: 'Sedes',
    path: routesPreferences.company,
    icon: IconHome,
    restricted: 'protocolSetup',
    id: 4,
    content: 'Crea y edita sedes',
  },
  {
    text: 'Departamentos',
    path: routesPreferences.department,
    icon: IconDoor,
    restricted: 'protocolSetup',
    id: 5,
    content: 'Crea y edita departamentos',
  },
];

export const monthNames = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export const weekDays = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];

export const calendarScreen = {
  title: [
    {
      text: 'Calendarios',
      icon: calendar,
    },
    {
      text: 'Vista previa',
      icon: stars,
    },
  ],
  option: [
    {
      text: 'Predeterminado',
      icon: transparent,
      id: '1',
    },
    {
      text: 'Festivos nacionales',
      icon: blue,
      id: '2',
    },
    {
      text: 'Festivos verano',
      icon: red,
      id: '3',
    },
    {
      text: 'Festivos locales',
      icon: green,
      id: '4',
    },
  ],
};

export const formCalendar = {
  title: 'Crea un nuevo calendario',
  titleEdit: 'Edita el calendario',
  textButton: 'Crear Horario',

  steps: [
    {
      number: '1',
      title: 'Información del calendario',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Añade el nombre del calendario',
          icon: calendar,
          ref: { form: 'calendar', element: 'name' },
          defaultValues: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Puede contener letras números',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'color',
          label: 'Color',
          placeholder: 'Añade el color del calendario',
          icon: settings,
          ref: { form: 'calendar', element: 'color' },
          defaultValues: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Puede contener letras números',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Configuración del calendario',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          layoutId: 2,
          cardType: 'calendar',
          label: 'Configuración del calendario',
          placeholder: 'Añade el nombre del calendario',
          icon: schedule,
          ref: { form: 'calendar', element: 'scheduleId' },
          defaultValues: null,
          default: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: 'Debe tener entre 3 y 50 carácteres',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formPreferences = {
  title: 'Crear nuevo horario',
  titleEdit: 'Edita el horario',
  textButton: 'Crear horario',

  steps: [
    {
      number: '1',
      title: 'Nombre',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          layoutId: 1,
          cardType: 'input',
          label: 'Nombre',
          placeholder: 'Añade el nombre del horario',
          icon: textInput,
          ref: { form: 'schedule', element: 'name' },
          defaultValues: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 carácteres',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Configuración del horario',
      id: 2,
      nextStepText: 'Siguiente Paso',
      lastStep: true,

      input: [
        {
          layoutId: 2,
          cardType: 'schedule',
          label: 'Configuración del horario',
          placeholder: 'Añade el nombre del horario',
          icon: schedule,
          ref: { form: 'schedule', element: 'scheduleId' },
          defaultValues: null,
          radioType: 'multi',
          options: [
            { label: 'Lunes', value: 'lun', id: 1 },
            { label: 'Martes', value: 'mar', id: 2 },
            { label: 'Miércoles', value: 'mie', id: 3 },
            { label: 'Jueves', value: 'jue', id: 4 },
            { label: 'Viernes', value: 'vie', id: 5 },
            { label: 'Sábado', value: 'sab', id: 6 },
            { label: 'Domingo', value: 'dom', id: 7 },
          ],
          required: {
            text: 'Campo obligatorio / Añade tantas franjas horarias como quieras /Las horas no seleccionadas formaran parte de un horario en cerrado predeterminado',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 carácteres',
            regex: /^/,
          },
        },
      ],
    },
  ],
};

export const scheduleDaysForm = [
  {
    label: 'Lunes',
    value: 'mon',
  },
  {
    label: 'Martes',
    value: 'tue',
  },
  {
    label: 'Miércoles',
    value: 'wed',
  },
  {
    label: 'Jueves',
    value: 'thu',
  },
  {
    label: 'Viernes',
    value: 'fri',
  },
  {
    label: 'Sábado',
    value: 'sat',
  },
  {
    label: 'Domingo',
    value: 'sun',
  },
];

export const cardDays = {
  mon: 'Lunes',
  tue: 'Martes',
  wed: 'Miércoles',
  thu: 'Jueves',
  fri: 'Viernes',
  sat: 'Sábado',
  sun: 'Domingo',
};

export const notScheduleForm = 'Sin horario creado';

export const dataScreenSchedule = {
  title: [
    {
      text: 'Horarios',
      icon: clock,
    },
  ],
  option: [],
  icon: {
    calendar,
    clock,
    settings,
  },
  notFound: {
    icon: { img: calendar, alt: 'click' },

    title: 'No hay ningún horario creado',
    subtitle: 'Empieza por configurar una nuevo horario para visualizar en tu escritorio',
    textButton: 'Crear horario',
  },
};

export const dataScreenCompany = {
  title: [
    {
      text: 'Sede',
      icon: home,
    },
    {
      text: 'Departamento',
      icon: home,
    },
  ],
  option: [],
  icon: {
    home,
  },
  notFound: {
    icon: { img: click, alt: 'click' },

    title: 'No hay ninguna Sede creada',
    subtitle: 'Empieza por configurar una nueva Sede para visualizar en tu escritorio',
    textButton: 'Crear Sede',
  },
};

export const dataScreenDepartment = {
  icon: {
    home,
  },
  notFound: {
    icon: { img: click, alt: 'click' },
    title: 'No hay ningún Departamento creado',
    subtitle: 'Empieza por configurar un nuevo Departamento para visualizar en tu escritorio',
    textButton: 'Crear Departamento',
  },
};

export const formDepartment = {
  title: 'Crear nuevo Departamento',
  titleEdit: 'Edita el Departamento',
  textButton: 'Crear Departamento',
  steps: [
    {
      number: '1',
      title: 'Nombre',
      id: 1,
      nextStepText: 'Siguiente Paso',
      lastStep: true,
      input: [
        {
          cardType: 'id',
          ref: { form: 'department', element: 'id' },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Nombre',
          placeholder: 'Nombre',
          ref: { form: 'department', element: 'name' },
          default: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 carácteres',
            regex: typeRegex.inputText,
          },
        },
      ],
    },
  ],
};

export const formCompany = {
  title: 'Crear nueva Sede',
  titleEdit: 'Edita la Sede',
  textButton: 'Crear Sede',
  steps: [
    {
      number: '1',
      title: 'Nombre, Ip, Puerto',
      id: 1,
      nextStepText: 'Siguiente Paso',
      input: [
        {
          cardType: 'id',
          ref: { form: 'company', element: 'id' },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Nombre',
          placeholder: 'Nombre',
          ref: { form: 'company', element: 'name' },
          default: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Debe tener entre 3 y 50 carácteres',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Ip',
          placeholder: 'Ip',
          ref: { form: 'company', element: 'ip' },
          default: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Números',
            regex: typeRegex.inputNumber,
          },
        },
        {
          layoutId: 1,
          cardType: 'input',
          icon: textInput,
          label: 'Puerto',
          placeholder: 'Puerto',
          ref: { form: 'company', element: 'port' },
          default: null,
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Números',
            regex: typeRegex.inputNumber,
          },
        },
      ],
    },
    {
      number: '2',
      title: 'Departamentos, Ip(backup), Puerto(backup)',
      id: 2,
      lastStep: true,
      nextStepText: 'Siguiente Paso',
      columns: 2,
      input: [
        {
          layoutId: 2,
          cardType: 'multiselect',
          label: 'Departamentos',
          placeholder: 'Departamentos',
          icon: textInput,
          ref: { form: 'company', element: 'departments' },
          options: [],
          defaultValues: [],
          required: {
            text: 'Campo obligatorio',
            validation: true,
            errorMessage: '* Departamento requerido',
            regex: typeRegex.inputText,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          icon: textInput,
          label: 'Ip(backup)',
          placeholder: 'Ip(backup)',
          ref: { form: 'company', element: 'ipBackup' },
          default: null,
          required: {
            text: 'Ip(backup)',
            validation: false,
            errorMessage: '* Números',
            regex: /^/,
          },
        },
        {
          layoutId: 2,
          cardType: 'input',
          icon: textInput,
          label: 'Port(backup)',
          placeholder: 'Port backup',
          ref: { form: 'company', element: 'portBackup' },
          default: null,
          required: {
            text: 'Port(backup)',
            validation: false,
            errorMessage: '* Números',
            regex: /^/,
          },
        },
      ],
    },
  ],
};
