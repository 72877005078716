import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { weight } from '../../theme/fonts';

export const MenuTopWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 34px;
  align-items: center;
  border-bottom: 1px solid ${colors.greyLine};
  padding-top: 2px;
  font-size: 20px;
  overflow-x: visible;

  .container {
    box-sizing: border-box;
    padding: 0 12px;
    display: flex;
    justify-items: center;
    align-items: center;
    height: 100%;

    a {
      height: 100%;
    }

    &__link {
      box-sizing: border-box;
      display: flex;
      margin-left: 24px;
      height: 100%;
      justify-items: center;
      align-items: center;
      transition: all 0.5s;
      border-bottom: 1px solid transparent;
      border-radius: 10px 10px 0 0;
      transition: all 0.3s;

      .menuTop__icon {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${colors.secondaryColor};
        transition: all 0.3s;

        .item-link {
          cursor: pointer;
          color: ${colors.primaryColor};
          transition: all 0.3s;
        }

        .menuTop__icon {
          color: ${colors.primaryColor};
          transition: all 0.3s;
          margin-right: 5px;
        }
      }
    }

    &__link-active {
      box-sizing: border-box;
      display: flex;
      margin-left: 24px;
      border-bottom: 1px solid ${colors.primaryColor};
      height: 100%;
      justify-items: center;
      align-items: center;
      background-color: ${colors.secondaryColor};
      border-radius: 10px 10px 0 0;
      transition: all 0.3s;

      .menuTop__icon {
        color: ${colors.primaryColor};
        transition: all 0.3s;
        margin-right: 5px;
      }
    }
  }

  .item-link {
    box-sizing: border-box;
    font-size: 15px;
    color: ${colors.black};
    font-weight: ${weight.medium};

    line-height: 16px;
    letter-spacing: 0.25px;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .link-active {
    box-sizing: border-box;

    color: ${colors.primaryColor};
    font-weight: ${weight.medium};
    font-size: 15px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.25px;
    transition: all 0.5s;
    border-radius: 8px;
    padding: 10px;
  }
  .tooltip {
    background-color: ${colors.primaryColor};
    border-radius: 8px;
    font-size: 12px;
    z-index: 9999;
  }
`;
