import React from 'react';

import { ButtonWrapper } from './Button.style';

export const Button = ({
  text,
  backgroundColor,
  width,
  Icon,
  icon2,
  fontSize,
  height,
  colorText,
  border,
  onClick,
  hoverBackgroundColor,
  hoverColorText,
  hoverIcon,
  id,
  strokeWidth = 4,
  heightIcon = 12,
  borderRadius = '10px',
}) => {
  return (
    <ButtonWrapper
      id={id}
      backgroundColor={backgroundColor}
      width={width}
      fontSize={fontSize}
      height={height}
      colorText={colorText}
      border={border}
      onClick={onClick}
      hoverBackgroundColor={hoverBackgroundColor}
      hoverColorText={hoverColorText}
      hoverIcon={hoverIcon}
      borderRadius={borderRadius}
    >
      {Icon && <Icon height={heightIcon} strokeWidth={strokeWidth} className="button__icon" />}
      {text && <p>{text}</p>}
      {icon2 && <img src={icon2} alt={text} />}
    </ButtonWrapper>
  );
};
