import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenDepartment } from '../../../data/dataPreferences';
import { nameTables } from '../../../data/dataTable';
import { useDepartments } from '../../../hooks/useDepartments';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { routesPreferences, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import { DepartmentScreenWrapper } from './DepartmentScreen.style';

export const DepartmentScreen = () => {
  const { departmentsHook, getDepartments, deleteDepartment, uploadDepartment, loading } = useDepartments();

  const { typeSwal } = useSwal();
  const { changeView, isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  const { notFound } = dataScreenDepartment;

  let navigate = useNavigate();

  useEffect(() => {
    getDepartments();
    setSearch([]);
    
  }, [setSearch]);

  const COLUMNS = [
    {
      Header: 'Nombre',
      accessor: 'name',
    },
  ];

  const DATA = departmentsHook.map(({ name, id }) => ({
    name,
    id,
  }));

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.preferences}/${routesPreferences.createDepartment}`, {});
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.preferences}/${routesPreferences.updateDepartment}`, {
      state: item.original ? item.original : item,
    });
  };

  const dataExport = departmentsHook.map(({ name }) => ({
    Nombre: name,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ name, id }) => ({
        title: name,
        id,
        name,
      }));
    } else {
      return DATA.map(({ name, id }) => ({
        title: name,
        id,
        name,
      }));
    }
  };

  const dataToCards = dataCard();

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteDepartment(item.original);
          } else {
            response = await deleteDepartment(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getDepartments();
            typeSwal('delete');
          }
          if (response.status === 203) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            getDepartments();
            typeSwal('error', table);
          }
        } catch (err) {
          return err.message;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadDepartment(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getDepartments();
        typeSwal('upload');
      }
    } catch (err) {
      return err;
    } finally {
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Nombre: '',
    },
  ];

  return (
    <DepartmentScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },

            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            changeView: {
              position: 'right',
              function: changeView,
              isCardView: isCardView,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : departmentsHook[0]?.status ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setIsBottom={setIsBottom}
          />
        </div>
      )}
    </DepartmentScreenWrapper>
  );
};
