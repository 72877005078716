import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize } from '../../theme/fonts';
import { weight } from '../../theme/fonts';

export const MultiFormPagesWrapper = styled.div`
    width: 100%;
    min-height: 500px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 70%);
    .multiFormPages {
        height: 100%;
        width: 100%;
        border-radius: 0 5px 5px 5px;
        position: relative;
        margin-top: 40px;

        &__error-delete-header {
          position: absolute;
          top: -44px;
          left: 10px;

          p {
            font-size: ${newFontSize.span};
            margin: 0;
            color: ${colors.red}
          }
        }

        &__header {
            height: 31px;
            width: 100%;
            position: absolute;
            top: -31px;
            display: flex;
            overflow: hidden;


            &__title {
                min-width: 149px;
                height: 100%;
                box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 25%);
                margin-left: 1px;            
                border-radius: 5px 5px 0 0;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #d2d3d445;

                &:hover {
                    cursor: pointer;
                    background-color: ${colors.secondaryColor};
                }

                &:active {
                  
                  transform: scale(0.96);
                  transition: all 0.1s;
                }

                &__item {
                    margin: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 8px;
                    font-size: ${newFontSize.button};
                    color: #444972;
                    font-weight: ${weight.medium};
                    border-radius: 5px 5px 0 0;
                }

                &__icon {
                    margin-right: 5px;
                }

                &__item-add {
                    margin: 0;
                    height: 102%;
                    display: flex;
                    align-items: center;
                    padding-left: 8px;
                    font-size: ${newFontSize.button};
                    color: #444972;
                    font-weight: ${weight.medium};
                }
            }
        }

        &__body {
            height: 100%;
            
            width: 100%;

           &__contain {
                height: 100%;
                width: 100%;
                padding: 25px;
                box-sizing: border-box;

           }
    }

    .active {
        color: ${colors.primaryColor};
        background-color: ${colors.secondaryColor};
        transition: all 0.5s;
        height: 102%;
    }

    .inactive {
        color: ${colors.grey};
        transition: all 0.5s;
    }
    .plus {
      min-width: 50px;
    }

    .errorPageActive {
      background-color: ${colors.red};
      color:white;
      transition: all 0.3s;

      &:hover {
        background-color: ${colors.redHover};
        transition: all 0.3s;

      }
      p{
        color: white;
      }

    }

    .errorPageInactive {
      background-color: ${colors.redHover};
      color:white;
      transition: all 0.3s;

      &:hover {
        background-color: ${colors.red};
        transition: all 0.3s;

      }
      p{
        color: white;
      }
    }
`;
