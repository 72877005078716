import React, { useEffect, useState } from 'react';

import { dataScreenIvr } from '../../data/dataApplications';
import { colors } from '../../theme/colors';
import { DestinationSelect } from '../DestinationSelect/DestinationSelect';
import { InputNumber } from '../InputNumber/InputNumber';
import { Key } from './Key/Key';

import { PhoneWrapper } from './Phone.style';

export const Phone = ({ onChange, refForm, crudType, item }) => {
  const [isActive, setIsActive] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [numbersActives, setNumbersActives] = useState([]);
  const [timeOutDefaultValues, setTimeOutDefaultValues] = useState(undefined);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { phone } = dataScreenIvr;
  const { data, icon, text, timeOut, notPressed } = phone;

  const onClick = (item) => {
    setIsActive(item);
  };

  const handleNumberActive = (isActive) => {
    setNumbersActives([...numbersActives, isActive]);
  };

  const handleDeleteOption = (data) => {
    const existingIndexInSelect = selectedOption.findIndex((item) => item.title === data.title);
    const existingIndexInNumbers = numbersActives.findIndex((item) => item === data.title);
    if (existingIndexInSelect >= 0) {
      setSelectedOption(selectedOption.filter((item, index) => index !== existingIndexInSelect));
    }
    if (existingIndexInNumbers >= 0) {
      setNumbersActives(numbersActives.filter((item, index) => index !== existingIndexInNumbers));
    }
    onChange(data, data.title, refForm);
  };

  const handleSelectChange = (option, name, idPage, destinationOpen, itemForm, number) => {
    if (name === 'clear') {
      option.title = number;
      handleDeleteOption(option);
    } else {
      const existingIndex = selectedOption.findIndex((item) => item.title === name);
      if (existingIndex >= 0) {
        setSelectedOption(
          selectedOption.map((item, index) => {
            if (index === existingIndex) {
              return {
                title: name,
                label: option.label,
                value: option.value,
                name: option.name,
              };
            }
            return item;
          })
        );
      } else {
        setSelectedOption([
          ...selectedOption,
          {
            title: name,
            label: option.label,
            value: option.value,
            name: option.name,
          },
        ]);
        handleNumberActive(name);
      }
      onChange(option, name, refForm);
    }
  };

  const handelTimeOut = (value) => {
    let timeOut = {
      label: value,
      value: value,
      ref: 'timeOut',
    };

    onChange(timeOut, 'timeOut', refForm);
  };

  useEffect(() => {
    setTimeout(() => {
      if (crudType === 'edit') {
        const { defaultValues } = item;

        const updatedNumbersActives = defaultValues.filter((obj) => obj.destinationType).map((obj) => obj.key);

        setNumbersActives(updatedNumbersActives);

        const findSelectedOption = defaultValues
          .filter((obj) => obj.destinationType)
          .map((obj) => ({
            title: obj.key !== 'timeOut' ? obj.key : 'Si no se pulsa',
            label: obj.destinationName,
            value: obj.destinationId !== 0 ? obj.destinationId : 'hangup',
            name: obj.name,
          }));

        setSelectedOption(findSelectedOption);

        const timeOut = defaultValues.find((obj) => obj.hasOwnProperty('timeOut'));
        setTimeOutDefaultValues(timeOut.timeOut);
      }
      if (selectedOption.length === 0) {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 500);
    
  }, []);

  return (
    <PhoneWrapper>
      <div className="phone">
        <div className="phone__container">
          <div className="phone__keys">
            {data.map((item, index) => (
              <div className="phone__key" key={index}>
                {item === isActive || numbersActives.includes(item) ? (
                  <Key item={item} onClick={onClick} backgroundColor={colors.secondaryColor} />
                ) : item === '*' || item === '#' ? (
                  <Key item={item} onClick={() => {}} backgroundColor={colors.greyHover} isDisable={true} />
                ) : (
                  <Key item={item} onClick={onClick} backgroundColor={colors.white} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="phone__selection">
          <div className="phone__selection__items">
            {isActive !== '' ? (
              <>
                <div className="phone__key__active">
                  {text} {isActive}
                </div>
                <div className="phone__select">
                  <DestinationSelect onChange={handleSelectChange} name={isActive} placeholder={'Selecciona una opción'} defaultValue={{}} />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {selectedOption.length !== 0 && (
        <div className="phone__info">
          {selectedOption.map((item, index) => (
            <React.Fragment key={index}>
              <div className="phone__key__active">
                {item.title.length === 1 ? text : ''} {item.title}
              </div>
              <div className="phone__option__active">
                <div className="phone__option__item">
                  <p>{item.value === 'hangup' ? item.label : `${item.name} : ${item.label}`}</p>
                  <img src={icon.cross.img} alt={icon.cross.alt} onClick={() => handleDeleteOption(item)} />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      <div className="phone__notPressed">
        <div className="phone__notPressed__contain">
          <div className="phone__notPressed__title">
            <p className="phone__notPressed__title__item">{notPressed}</p>
          </div>
          <div className="phone__notPressed__select">
            <DestinationSelect onChange={handleSelectChange} name={notPressed} placeholder={'Selecciona una opción'} defaultValue={{}} />
          </div>
        </div>
      </div>
      <div className="phone__timeOut">
        <div className="phone__timeOut__contain">
          <div className="phone__timeOut__title">
            <p>{timeOut}</p>
          </div>
          <div className="phone__timeOut__input">
            <InputNumber onChange={handelTimeOut} defaultValues={timeOutDefaultValues} />
          </div>
        </div>
      </div>
    </PhoneWrapper>
  );
};
