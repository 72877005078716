import { useCallback, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
  clearScheduleFormAction,
  changeScheduleNameAction,
  changeScheduleIdAction,
  changeScheduleHoursAction,
  addScheduleTimeIdAction,
  removeScheduleTimeIdAction,
  changeCalendarNameAction,
  changeCalendarColorAction,
  changeCalendarDaysAction,
  changeCalendarLinkIdAction,
  clearCalendarFormAction,
  addCalendarDaysIdAction,
} from '../redux/actions/preferences/actionCreators';

import {
  // Schedule
  createScheduleThunk,
  getSchedulesThunk,
  updateScheduleThunk,
  deleteScheduleThunk,
  // Calendar
  getCalendarThunk,
  createCalendarThunk,
  updateCalendarThunk,
  deleteCalendarThunk,
} from '../redux/thunks/preferencesThunk';

import { dataScreenSchedule, cardDays } from '../data/dataPreferences';
import { changeDepartmentIdFormAction, changeDepartmentNameFormAction, clearDepartmentFormAction } from '../redux/actions/department/actionCreator';

const usePreferences = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();
  const [loading, setLoading] = useState();
  const [actualSchedule, setActualSchedule] = useState({
    schedule: [],
    title: '',
  });
  const [actualCalendar, setActualCalendar] = useState([]);
  const [enable, setEnable] = useState({});
  const scheduleArrayHook = useSelector((scheduleArray) => scheduleArray.scheduleReducer);
  const scheduleForm = useSelector((schedule) => schedule.scheduleFormReducer);
  const calendarArrayHook = useSelector((calendar) => calendar.calendarReducer);
  const calendarForm = useSelector((calendar) => calendar.calendarFormReducer);

  const toWeekDay = (day) => {
    if (day === 'mon') return cardDays.mon;
    if (day === 'tue') return cardDays.tue;
    if (day === 'wed') return cardDays.wed;
    if (day === 'thu') return cardDays.thu;
    if (day === 'fri') return cardDays.fri;
    if (day === 'sat') return cardDays.sat;
    if (day === 'sun') return cardDays.sun;
    else return 'Error';
  };

  const dataToCardFunction = (scheduleArray) => {
    const cardsArray = [];
    let i = 0;

    while (i < scheduleArray.length) {
      if (scheduleArray[i].print) {
        cardsArray.push({
          title: toWeekDay(scheduleArray[i].ScheduleTime.weekDay),
          icon: dataScreenSchedule.calendar,
          subtitle: '',
          icon2: dataScreenSchedule.clock,
          text: scheduleArray[i].scheduleString,
          icon3: dataScreenSchedule.settings,
          id: scheduleArray[i].id,
        });
      }
      ++i;
    }

    return cardsArray;
  };

  const refactorSchedule = (scheduleArray) => {
    const schedule = {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    };

    scheduleArray.forEach((scheduleTime) => {
      if (schedule[scheduleTime.ScheduleTime.weekDay].length === 0)
        schedule[scheduleTime.ScheduleTime.weekDay].push({
          id: 0,
          initialHour: scheduleTime.ScheduleTime.hourInit,
          finalHour: scheduleTime.ScheduleTime.hourEnd,
        });
      else
        schedule[scheduleTime.ScheduleTime.weekDay].push({
          id: schedule[scheduleTime.ScheduleTime.weekDay].length,
          initialHour: scheduleTime.ScheduleTime.hourInit,
          finalHour: scheduleTime.ScheduleTime.hourEnd,
        });
    });

    return schedule;
  };

  const refactorCalendar = (selectedCalendar) => {
    const refactoredCalendar = [
      {
        id: selectedCalendar[0].id,
        name: selectedCalendar[0].CalendarName.name,
        color: selectedCalendar[0].CalendarName.color,
        days: selectedCalendar.map((day) => day.CalendarDay.day),
      },
    ];

    return refactoredCalendar;
  };

  const handleClick = (item) => {
    let selectedSchedule = scheduleArrayHook.filter((schedule) => schedule[0].id === item.id);

    setActualSchedule({
      schedule: selectedSchedule[0],
      title: selectedSchedule[0][0].Schedule.name,
    });

    setEnable(item.id);
  };

  const handleClickCalendar = (item) => {
    if (item.id === 'all') {
      setActualCalendar(calendarArrayHook.map((calendar) => refactorCalendar(calendar)[0]));

      setEnable(item.id);
    } else {
      const selectedCalendar = calendarArrayHook.filter((calendar) => calendar[0].id === item.id);

      const refactoredCalendar = refactorCalendar(selectedCalendar[0]);

      setActualCalendar(refactoredCalendar);

      setEnable(item.id);
    }
  };

  const getScheduleDB = useCallback(() => {
    dispatch(getSchedulesThunk(setLoading));
  }, [dispatch]);

  
  const createSchedule = useCallback(() => {
    const actualState = getState()['scheduleFormReducer'];

    const response = createScheduleThunk(
      {
        name: actualState.name,
        schedule: actualState.schedule,
      },
      setLoading
    );

    return response;
  });

  
  const updateSchedule = useCallback(() => {
    const actualState = getState()['scheduleFormReducer'];

    const response = updateScheduleThunk(
      {
        name: actualState.name,
        schedule: actualState.schedule,
        scheduleId: actualState.scheduleId,
        scheduleTimeId: actualState.scheduleHoursId,
      },
      setLoading
    );

    return response;
  });

  
  const deleteSchedule = useCallback((_scheduleId, _scheduleTimeIdArray) => {
    const response = deleteScheduleThunk(
      {
        scheduleId: _scheduleId,
        scheduleTimeId: _scheduleTimeIdArray,
      },
      setLoading
    );

    return response;
  });

  const getCalendar = useCallback(() => {
    dispatch(getCalendarThunk(setLoading));
  }, [dispatch]);

  
  const createCalendar = useCallback(() => {
    const actualState = getState()['calendarFormReducer'];

    const response = createCalendarThunk(
      {
        name: actualState.name,
        color: actualState.color,
        days: actualState.days,
      },
      setLoading
    );

    return response;
  });

  const updateCalendar = useCallback(() => {
    const actualState = getState()['calendarFormReducer'];

    const response = updateCalendarThunk(
      {
        name: actualState.name,
        color: actualState.color,
        days: actualState.days,
        calendarId: actualState.calendarId,
        calendarDaysId: actualState.calendarDaysId,
      },
      setLoading
    );

    return response;

    
  }, [dispatch]);

  
  const deleteCalendar = useCallback((payload) => {
    const response = deleteCalendarThunk(
      {
        calendarId: payload[0].calendarNameId,
        calendarDaysId: payload.map((days) => days.calendarDaysId),
      },
      setLoading
    );

    return response;
  });

  
  const preferencesForm = useCallback((input, reference) => {
    const { action, value } = input;
    const { form, element } = reference;

    switch (form) {
      case 'schedule':
        switch (action) {
          case 'clear':
            dispatch(clearScheduleFormAction());
            break;

          case 'input':
            if (element === 'name') dispatch(changeScheduleNameAction(value));
            if (element === 'scheduleId') dispatch(changeScheduleIdAction(value));
            if (element === 'hour') dispatch(changeScheduleHoursAction(value));
            if (element === 'scheduleTimeId') dispatch(addScheduleTimeIdAction(value));
            break;

          case 'remove_value':
            if (element === 'remove_id') dispatch(removeScheduleTimeIdAction(value));
            break;

          default:
            break;
        }
        break;

      case 'calendar':
        switch (action) {
          case 'clear':
            dispatch(clearCalendarFormAction());
            break;

          case 'input':
            if (element === 'name') dispatch(changeCalendarNameAction(value));
            if (element === 'id') dispatch(changeCalendarLinkIdAction(value));
            if (element === 'color') dispatch(changeCalendarColorAction(value));
            if (element === 'days') dispatch(changeCalendarDaysAction(value));
            if (element === 'daysId') dispatch(addCalendarDaysIdAction(value));
            break;
          default:
            break;
        }
        break;

      case 'department':
        switch (action) {
          case 'clear':
            dispatch(clearDepartmentFormAction());
            break;

          case 'input':
            if (element === 'name') dispatch(changeDepartmentNameFormAction(value));
            if (element === 'id') dispatch(changeDepartmentIdFormAction(value));
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }
  });

  return {
    scheduleArrayHook,
    scheduleForm,
    actualSchedule,

    calendarArrayHook,
    calendarForm,
    actualCalendar,

    enable,
    dataToCardFunction,
    refactorSchedule,
    //DB Functions
    getScheduleDB,
    createSchedule,
    updateSchedule,
    deleteSchedule,

    getCalendar,
    createCalendar,
    updateCalendar,
    deleteCalendar,
    //Form inputs dispatcher
    preferencesForm,
    //Loader
    loading,

    handleClick,
    handleClickCalendar,
  };
};

export default usePreferences;
