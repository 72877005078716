import React from 'react';
import { IconUser } from '@tabler/icons-react';

import { userProfile } from '../../data/dataUserProfile';
import { UserProfileWrapper } from './UserProfile.style';

export const UserProfile = ({ user, isClosed }) => {
  const { firstName, lastName, email, rolesName } = user.user;
  return (
    <UserProfileWrapper isClosed={isClosed}>
      <div className="photo">
        <IconUser height={20} />
      </div>
      <div className="user">
        <div className="user__contain__title">
          <p className="user__title">{firstName} </p>
          <p className="user__title">{lastName}</p>
        </div>
        <div className="contain__subtitle">
          {/* <img className="image__icon" src={userProfile.trophy} alt="mail" /> */}
          <p className="subTitle">{rolesName}</p>
        </div>
        <div className="contain__subtitle">
          {/* <img
                        className="image__icon"
                        src={userProfile.mail}
                        alt="mail"
                    /> */}
          <p className="subTitle">{email}</p>
        </div>
      </div>
    </UserProfileWrapper>
  );
};
