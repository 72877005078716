import styled from 'styled-components';

const MonthWrapper = styled.table`
  .month_name {
    font-weight: 400;
    font-size: 12px;
    color: #7f40a6;
    text-align: center;
    line-height: 20px;
    padding-left: 5px;
  }

  .week_days {
    font-weight: 400;
    font-size: 10px;
  }
`;

const Month = ({ monthName, calendarRows, weekDays }) => {
  return (
    <MonthWrapper>
      <thead>
        <tr className="month_container">
          <th colSpan={7} className="month_name">
            {' '}
            {monthName}{' '}
          </th>
        </tr>

        <tr className="week_days">
          <th>{weekDays[0]}</th>
          <th>{weekDays[1]}</th>
          <th>{weekDays[2]}</th>
          <th>{weekDays[3]}</th>
          <th>{weekDays[4]}</th>
          <th>{weekDays[5]}</th>
          <th>{weekDays[6]}</th>
        </tr>
      </thead>

      <tbody>{calendarRows}</tbody>
    </MonthWrapper>
  );
};

export default Month;
