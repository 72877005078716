import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardGird } from '../../../components/CardGrid/CardGird';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterWhiteList, dataScreenWhiteList } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { routesSettings, routesWide } from '../../../navigation/routes';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import WhiteListWrapper from './WhiteList.style';

export const WhiteList = () => {
  const { loading, whitelist, getWhitelist, uploadFileWhitelist, deleteWhitelist } = useSettings();
  const { changeView, isCardView } = useView();
  const { typeSwal } = useSwal();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });

  const { notFound } = dataScreenWhiteList;

  let navigate = useNavigate();

  useEffect(() => {
    getWhitelist();
    
  }, []);

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.settings}/${routesSettings.createWhitelist}`, {});
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await uploadFileWhitelist(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        typeSwal('upload');
        getWhitelist();
      }
    } catch (err) {
      return err;
    }
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteWhitelist(item.original);
          } else {
            response = await deleteWhitelist(item);
          }

          if (response.status === 200 || response.status === 201 || response.status === 204) {
            typeSwal('delete');
            getWhitelist();
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.settings}/${routesSettings.updateWhitelist}`, {
      state: item.original ? item.original : item,
    });
  };

  const DATA = whitelist.map(({ ip_addr, id }) => ({
    ip_addr,
    id,
  }));
  const COLUMNS = [
    {
      Header: 'IP Address',
      accessor: 'ip_addr',
    },
  ];
  const dataExport = whitelist.map(({ ip_addr, id }) => ({
    IP: ip_addr,
  }));

  const template = [
    {
      'Ip Address': '',
    },
  ];

  const items = whitelist.map(({ ip_addr, id }) => ({
    ip_addr,
    id,
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(({ ip_addr, id }) => ({
        subtitle: ip_addr,
        id,
        ip_addr,
      }));
    } else {
      return whitelist.map(({ ip_addr, id }) => ({
        subtitle: ip_addr,
        id,
        ip_addr,
      }));
    }
  };

  const dataToCards = dataCard();

  const tableInstance = useRef(null);

  return (
    <WhiteListWrapper>
      {' '}
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },

            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            changeView: {
              position: 'right',
              function: changeView,
              isCardView: isCardView,
            },
            file: {
              position: 'right',
              data: dataExport,
              template,
              import: false,
            },
            filter: {
              position: 'left',
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
        {whitelist[0]?.status ? null : (
          <div className={'contain__menuFilter'}>
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={items} filter={dataFilterWhiteList} setSearch={setSearch} />
          </div>
        )}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : whitelist[0]?.status ? (
        <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} setIsBottom={setIsBottom} />
        </div>
      ) : (
        <>
          <div className="contain__table">
            <TableInfo
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              format={'list'}
              deleteFunction={handleDelete}
              updateFunction={handleOpenFormEdit}
              ref={tableInstance}
              onClick={handleOpenFormEdit}
              setIsBottom={setIsBottom}
            />
          </div>
        </>
      )}
    </WhiteListWrapper>
  );
};
