import Axios from 'axios';

import {
  getApiAction,
  getConfigAction,
  getDomainsAction,
  getMenuSubmenuAction,
  getPrivilegesAction,
  getRolesAction,
  getWhitelistAction,
} from '../actions/settingsScreen/settingsActions/actionsCreator';

const urlApi = process.env.REACT_APP_URL;

export const getPrivilegesThunk = () => async (dispatch) => {
  try {
    const privileges = await Axios.get(`${urlApi}settings/privileges`);

    if (privileges.status === 200) {
      dispatch(getPrivilegesAction(privileges.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const getMenusSubmenusThunk = () => async (dispatch) => {
  try {
    const arrayMenusSubmenus = await Axios.get(`${urlApi}settings/menus`);
    if (arrayMenusSubmenus.status === 200) {
      dispatch(getMenuSubmenuAction(arrayMenusSubmenus.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const postPrivilegesThunk = (privileges) => async (dispatch) => {
  try {
    await Axios.patch(`${urlApi}settings/permissions/update`, {
      data: privileges,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getRolesThunk = () => async (dispatch) => {
  try {
    const response = await Axios.get(`${urlApi}settings/roles`);
    if (response.status === 200) {
      dispatch(getRolesAction(response.data));
    }
  } catch (err) {
    console.log(err);
  }
};

export const createApiThunk = (apiKey) => async (dispatch) => {
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/apiKey/create`, {
      data: apiKey,
    });
  } catch (err) {
    console.log(err);
  } finally {
    return response;
  }
};

export const getApiThunk = (userId, setLoading) => async (dispatch) => {
  setLoading(true);

  try {
    const response = await Axios.get(`${urlApi}settings/apiKey?userId=${userId}`);

    if (response.status === 200) {
      dispatch(getApiAction(response.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const getDomainsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlApi}settings/domains`);
    if (response.status === 200) {
      dispatch(getDomainsAction(response.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const createDomainThunk = (domain) => async () => {
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/domains/create`, {
      data: domain,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};
export const updateDomainThunk = (domain) => async () => {
  let response;
  try {
    response = await Axios.patch(`${urlApi}settings/domains/update`, {
      data: domain,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};

export const deleteDomainThunk = (domain, setLoading) => async () => {
  setLoading(true);
  let response;
  try {
    response = await Axios.delete(`${urlApi}settings/domains/delete`, {
      data: domain,
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const uploadFileDomainThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/domains/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const getWhitelistThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlApi}settings/whitelist`);
    if (response.status === 200) {
      dispatch(getWhitelistAction(response.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const createWhitelistThunk = (whitelist) => async () => {
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/whitelist/create`, {
      data: whitelist,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};
export const updateWhitelistThunk = (whitelist) => async () => {
  let response;
  try {
    response = await Axios.patch(`${urlApi}settings/whitelist/update`, {
      data: whitelist,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};

export const deleteWhitelistThunk = (whitelist, setLoading) => async () => {
  setLoading(true);
  let response;
  try {
    response = await Axios.delete(`${urlApi}settings/whitelist/delete`, {
      data: whitelist,
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const uploadFileWhitelistThunk = (file, setLoading) => async () => {
  setLoading(true);
  const data = new FormData();
  data.append('file', file[0]);
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/whitelist/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
    return response;
  }
};

export const createConfigThunk = (config) => async () => {
  let response;
  try {
    response = await Axios.post(`${urlApi}settings/config/create`, {
      data: config,
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};

export const getConfigDataThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.get(`${urlApi}settings/config`);
    if (response.status === 200) {
      dispatch(getConfigAction(response.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};
