import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useSettings from '../../../hooks/useSettings';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { PermissionsScreenWrapper } from './PermissionsScreen.style';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { dataScreenSettings } from '../../../data/dataSettings';

export const PermissionsScreen = () => {
  const { privileges, getPrivileges, roles, getRoles, users, getUsers } = useSettings();
  const { permissions, icon } = dataScreenSettings;

  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);

  let navigate = useNavigate();

  useEffect(() => {
    getPrivileges();
    getRoles();
    getUsers();
  }, [getPrivileges, getRoles, getUsers]);

  useEffect(() => {
    setTimeout(() => {
      if (privileges.length === 0 || roles.length === 0 || users.length === 0) {
        setLoadingCounter(loadingCounter + 1);
      } else {
        setLoading(false);
      }
    }, 100);
    
  }, [loadingCounter]);

  const handleOpenSettings = (data) => {
    if (data.title !== 'Superadmin') {
      navigate('/settings/permissions/edit', {
        state: data,
      });
    }
  };

  function numberProfile(name) {
    const howManyRoles = [];
    users.map((item) => {
      if (item.Role.name === name) {
        howManyRoles.push(item);
        return null;
      } else {
        return null;
      }
    });
    return howManyRoles.length;
  }

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  function handleIcon(name) {
    let newIcon;
    switch (name) {
      case 'superadmin':
        newIcon = icon.crown;

        break;
      case 'admin':
        newIcon = icon.trophy;

        break;
      case 'operator':
        newIcon = icon.flame;

        break;
      case 'agent':
        newIcon = icon.mushroom;

        break;

      default:
        break;
    }
    return newIcon;
  }

  const dataToCard = roles.map(({ name, id }) => ({
    title: capitalizeFirstLetter(name),
    subtitle: name === permissions.allFunctionalitiesRole ? permissions.allFunctionalitiesText : permissions.functionalitiesText,
    text2: numberProfile(name) === 1 ? numberProfile(name) + permissions.profile : numberProfile(name) + permissions.profiles,
    text: permissions.member,
    id,
    iconTitle: handleIcon(name),
  }));

  return loading ? (
    <LoaderSpinner />
  ) : (
    <PermissionsScreenWrapper>
      <div className="contain__cardGrid">
        <CardGird data={dataToCard} onClick={handleOpenSettings} />
      </div>
    </PermissionsScreenWrapper>
  );
};
