import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LineCharts from '../../../components/Graphs/Charts';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataScreenHistory } from '../../../data/dataEM';
import useEM from '../../../hooks/useEM';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';

import { HistoryWrapper } from './HistoryScreen.style';
import useApplications from '../../../hooks/useApplications';



import 'moment-timezone/builds/moment-timezone-with-data';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { changeApplicationsLocutionsFormName } from '../../../redux/actions/applications/actionCreators';


export const HistoryScreen = () => {
  const { histories, getHistories, loading } = useEM();
  const { arrayEMAlerts, getEMAlerts } = useEM();
  const { audios } = useApplications();

  const filesMP3 = audios.audio;

  const { notFound } = dataScreenHistory;

  const navigate = useNavigate();

  const [search, setSearch] = useState([]);
  const [, setIsBottom] = useState(false);

  useEffect(() => {
    getHistories();
    getEMAlerts();
    setSearch([]);
    
  }, [setSearch]);

  function foundFile(filename) {
    let file;
    if (filesMP3.find((file) => file.includes(filename))) {
      file = filename;
    }
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
      return process.env.REACT_APP_URL.replace('api', 'uploads') + '/mp3/' + file;
    } else {
      return  '/uploads/mp3/' + filename;
    }
  }


  // Function to extract the value of em_alert_message from appdata
function extractEmAlertMessage(appdata) {
  // Split the appdata string by commas
  const appdataArray = appdata.split(',');

  // Find the item in the array that contains "em_alert_message"
  const emAlertMessageItem = appdataArray.find(item => item.includes("em_alert_message"));

  if (emAlertMessageItem) {
    // Split the em_alert_message item by ":" to get the value
    const value = emAlertMessageItem.split(":")[1];

    // Trim any extra whitespace
    const trimmedValue = value.trim();
    // Replace the path with the variable data for filename
    const replacedValue = trimmedValue.replace(
      "/var/lib/asterisk/call_recordings/rec_alertas/",
      "/uploads/rec_alertas/"
    );
    return replacedValue + '.wav';
  } else {
    return null; // em_alert_message not found in appdata
  }
}


  const groupHistories = (items) => {
    const userDefType = [];
    items.map((history) => {
      const { userdeftype } = history;
      if (userdeftype) {
        userDefType.push(history);
      }
    });

  


    userDefType.map((item) => {
      let substrings = item.appdata.split(',');
      let src = substrings.find((substring) => substring.includes('em_uid'));
      if (src) {
        let srcValue = src.substring(src.indexOf(':em-') + 1);
        item.em_uid = srcValue;
        // Extract and assign em_alert_message value
    item.em_alert_message = extractEmAlertMessage(item.appdata);
      } else {
        console.log('No hay datos');
      }
    });

    const group = userDefType.reduce((ac, obj) => {
      if (!ac[obj.em_uid]) {
        ac[obj.em_uid] = [];
      }
      ac[obj.em_uid].push(obj);
      return ac;
    }, {});

    const arrayGroup = Object.entries(group).map(([key, value]) => {
      return {
        em_uid: key,
        objects: value,
      };
    });
    console.log(arrayGroup);
    return arrayGroup;
  };

  const userDefType = groupHistories(histories);

  const COLUMNS = [
    {
      Header: 'ID alerta',
      accessor: 'linkedid',
    },
    {
      Header: 'Lista de difusión',
      accessor: 'listDiffusion',
      Cell: ({ row }) => (
        <div>
          {row.original.emCode} | {row.original.listDiffusion} 
        </div>
      ),
    },
    // {
    //   Header: 'Locución escuchada',
    //   accessor: 'soundPrompt',
    //   Cell: ({ row }) => (
    //     <div>
    //       <audio style={{ width: '190px', height: '35px' }} src={row.original.soundPrompt} controls />
    //     </div>
    //   ),
    // },
    {
      Header: 'Grabada',
      accessor: 'callAndRecord',
      Cell: ({ row }) => (
        <>
          {row.original.callAndRecord ? (
            // Render FontAwesome icon if callAndRecord is present
            <FontAwesomeIcon icon={faHeadset} style={{ marginRight: '8px' }} />
          ) : null}
          {row.original.emAlertMessage ? <FontAwesomeIcon icon={faHeadset} style={{ marginRight: '8px',color: 'green' }} /> : ''}
        </>
      ),
      disableSortBy: true, // Esto desactiva la capacidad de ordenar esta columna
    },
    {
      Header: 'Mensaje Alerta',
      accessor: 'soundPrompt',
      Cell: ({ row }) => (
        <div>
          {row.original.emAlertMessage ? (
            // Render FontAwesome icon and emAlertMessage if it's present
            <>
              <audio style={{ width: '190px', height: '35px' }} src={row.original.emAlertMessage} controls />
            </>
          ) : (
            // Render soundPrompt if emAlertMessage is null
            <audio style={{ width: '190px', height: '35px' }} src={row.original.soundPrompt} controls />
          )}
        </div>
      ),
      disableSortBy: true, // Esto desactiva la capacidad de ordenar esta columna
    },

    {
      Header: 'Origen Alerta',
      accessor: 'issuingExtension',
    },
    
    {
      Header: 'Hora de emisión',
      accessor: 'eventtime',
      Cell: ({ row }) => {
        console.log('row.original.eventtime:',row.original.eventtime)

        const eventTime = new Date(row.original.eventtime);
        console.log('eventTime:',eventTime)

        const day = eventTime.getDate().toString().padStart(2, '0');
        const month = (eventTime.getMonth() + 1).toString().padStart(2, '0');
        const year = eventTime.getFullYear().toString().slice(-2); // Obtener los últimos 2 dígitos del año
        const hours = eventTime.getUTCHours().toString().padStart(2, '0'); // Obtener horas en UTC
        const minutes = eventTime.getUTCMinutes().toString().padStart(2, '0'); // Obtener minutos en UTC
        const seconds = eventTime.getUTCSeconds().toString().padStart(2, '0'); // Obtener segundos en UTC
        
        
        const formattedEventTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

        console.log('formattedEventTime:',formattedEventTime)
        
        return <div dangerouslySetInnerHTML={{ __html: formattedEventTime }} />;
      },
    },
    
    
    
    
  ];

  function dataInsideRow(row) {
    navigate('/EM/history/item', {
      state: row.original ? row.original : row,
    });
  }

  function foundData(data, search) {
    let substrings = data.split(',');
    let src = substrings.find((substring) => substring.includes(search));
    if (src) {
      let srcValue = src.substring(src.indexOf(':') + 1);
      // console.log('data search srcValue')
      // console.log(data)
      // console.log(search)
      // console.log(srcValue)
      return srcValue;
    } else {
      return 'No hay datos';
    }
  } 

  function foundTime(date) {
    let time = moment(date).format('YYYY-MM-DD <br/> HH:mm:ss');
    return time;
  }

  function foundAlert(data, search, type) {
    let codeAlert = foundData(data, search);
    let newName;
    if (type === 'list') {
      arrayEMAlerts.forEach((item) => {
        if (item.keyCall === codeAlert) {
          newName = item.principalList.name;
        }
      });
    } else if (type === 'sound') {
      arrayEMAlerts.forEach((item) => {
        if (item.keyCall === codeAlert) {
          newName = item.Soundprompt.filename;
        }
      });
    }
    return newName;
  }

  // const DATA = userDefType.reverse().map((item) => ({
  //   itemsSameLinkedId: item.objects,
  //   linkedid: item.objects[0].linkedid,
  //   listDiffusion: foundAlert(item.objects[0].appdata, 'emcode:', 'list'),
  //   soundPrompt: foundFile(foundAlert(item.objects[0].appdata, 'emcode:', 'sound')),
  //   issuingExtension: foundData(item.objects[0].appdata, 'src:'),
  //   eventtime: foundTime(item.objects[0].eventtime),
  //   emAlertMessage: extractEmAlertMessage(item.objects[0].appdata), // Include em_alert_message

  // }));


  const DATA = userDefType.reverse().map((item) => {
    const emAlertMessage = extractEmAlertMessage(item.objects[0].appdata);
    const hasRecAlertas = emAlertMessage && emAlertMessage.includes("rec_alertas");
    
    return {
      itemsSameLinkedId: item.objects,
      linkedid: item.objects[0].linkedid,
      listDiffusion: foundAlert(item.objects[0].appdata, 'emcode:', 'list'),
      emCode: foundData(item.objects[0].appdata, 'emcode:'),

      // issuingExtension: foundData(item.objects[0].appdata, 'src:'),

      issuingExtension: item.objects[0].accountcode,
      // eventtime: foundTime(item.objects[0].eventtime),
      eventtime: item.objects[0].eventtime,
      emAlertMessage: hasRecAlertas ? emAlertMessage : null, // Include em_alert_message if it has "rec_alertas"
      soundPrompt: hasRecAlertas ? null : foundFile(foundAlert(item.objects[0].appdata, 'emcode:', 'sound')), // Include soundPrompt if no "rec_alertas"
    };
  });
  

  const tableInstance = useRef(null);

  let groupData = DATA.reduce(function (acc, history) {
    let date = history.eventtime.match(/^\d{4}-\d{2}-\d{2}/)[0]; // Extraer la date
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(history);
    return acc;
  }, {});

  for (let date in groupData) {
    let dayName = new Date(date).toLocaleString('es', { weekday: 'long' });
    groupData[dayName] = groupData[date];
    delete groupData[date];
  }

  const dataKeys = [{ dataKey: 'salientes', color: '#ED7E63' }];

  const dataLine = [
    { name: 'lunes', salientes: 0 },
    { name: 'martes', salientes: 0 },
    { name: 'miércoles', salientes: 0 },
    { name: 'jueves', salientes: 0 },
    { name: 'viernes', salientes: 0 },
    { name: 'sábado', salientes: 0 },
    { name: 'domingo', salientes: 0 },
  ];

  for (let i in dataLine) {
    for (let date in groupData) {
      if (dataLine[i].name === date) {
        dataLine[i].salientes = groupData[date].length;
      }
    }
  }

  return (
    <HistoryWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            // file: {
            //     position: 'right',
            //      data: dataExport,
            //     // headers: headersExport,
            //     import: false,
            // },
          }}
          setSearch={setSearch}
        />
      </div>

      {loading ? (
        <LoaderSpinner />
      ) : histories[0]?.status ? (
        <div className="contain__notFound">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : (
        <div className="contain__table-statistics">
          <div className="contain__statistics">
            <TableInfo
              format={'list'}
              COLUMNS={COLUMNS}
              DATA={search.length === 0 ? DATA : search}
              deleteFunction={null}
              updateFunction={null}
              isOptions={false}
              cursorPointer={true}
              onClick={dataInsideRow}
              ref={tableInstance}
              setIsBottom={setIsBottom}
              isCheckbox={false}
            />
          </div>

          <div className="stadistics_charts">
            <div className="graph border">
              <LineCharts data={dataLine} dataKeys={dataKeys} />
            </div>
            {/* <div className="graph border">
                            <BarCharts data={dataBar} />
                        </div> */}
          </div>
        </div>
      )}
    </HistoryWrapper>
  );
};
