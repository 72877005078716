import React from 'react';
import { Navigate } from 'react-router-dom';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import { menuOutbound } from '../../../data/dataOutbound';
import { OverviewOutboundWrapper } from './OverviewOutboundScreen.style';

export const OverviewOutboundScreen = () => {
  return (
    <OverviewOutboundWrapper>
      <Navigate to="/outboundRoutes/groupCategories" />
      <LinkButtonBig menuTop={menuOutbound} />
    </OverviewOutboundWrapper>
  );
};
