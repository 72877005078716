import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateUser } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import useUsers from '../../../hooks/useUsers';
import { routesSettings, routesWide } from '../../../navigation/routes';

export const UserScreenForm = ({ crudType }) => {
  const { postCreateUser, userForm, usersFormReducer, updateUser } = useUsers();
  const { getUsers } = useSettings();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);

  const data = formCreateUser;

  useEffect(() => {
    userForm(
      { action: 'clearForm' },
      {
        form: 'user',
      }
    );
    if (crudType === 'edit') {
      userForm(
        {
          action: 'id',
          value: location.state.id,
        },
        { form: 'user', element: 'id' }
      );
      Object.keys(location.state).map((item) =>
        data.steps.map((item2) =>
          item2.input.map((item3) => {
            if (item === item3.ref.element) {
              return userForm(
                {
                  action: item3.cardType,
                  value: location.state[item],
                },
                {
                  form: item3.ref.form,
                  element: item3.ref.element,
                },
                {
                  label: location.state[item],
                  value: String(location.state.roleId),
                }
              );
            } else {
              return null;
            }
          })
        )
      );
    }
    
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof usersFormReducer === 'object') {
        if (crudType === 'edit' && (usersFormReducer.firstname === null || usersFormReducer.firstname !== location.state.firstname)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'firstname':
                  item2.defaultValues = usersFormReducer.firstname;
                  break;
                case 'lastname':
                  item2.defaultValues = usersFormReducer.lastname;
                  break;
                case 'secondname':
                  item2.defaultValues = usersFormReducer.secondname;
                  break;
                case 'email':
                  item2.defaultValues = usersFormReducer.email;
                  break;
                case 'password':
                  item2.defaultValues = usersFormReducer.password;
                  break;
                case 'role':
                  item2.options.filter((item3) => {
                    if (usersFormReducer.role.label === item3.label) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                default:
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
    
  }, [crudType, loadingCounter]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.settings}/${routesSettings.users}`, 'user', postCreateUser);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', userForm, getUsers, `/${routesWide.settings}/${routesSettings.users}`, 'user', updateUser);
    }
  };

  return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={userForm} dataSave={dataSave} crudType={crudType} />}</>;
};
