import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const StepFormWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  margin: auto;
  .error {
    color: ${colors.red};
    font-size: ${newFontSize.span};
    margin-top: 2px;
  }

  .textRequired {
    color: ${colors.greySpanLabel};
    font-size: ${newFontSize.span};
    margin-bottom: 2px;
  }

  .stepForm {
    width: 100%;
    height: 100%;
    margin: auto;
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    max-width: ${(props) => (props.maxwidth === undefined ? '600px' : props.maxwidth)};
    row-gap: 13px;

    &__header {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &__number {
      background-color: ${colors.primaryColor};
      font-size: ${newFontSize.button};
      color: ${colors.white};
      font-weight: ${weight.medium};
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0;
    }

    &__title {
      font-size: ${newFontSize.body};
      color: ${colors.black};
      font-weight: ${weight.bold};
      font-family: ${fontFamily.font};
      margin-left: 5px;

      .title {
        margin: 0;
      }
    }

    &__contain-typeInput {
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      display: grid;
      grid-template-columns: ${(props) => (props.maxwidth === undefined ? 'repeat(auto-fit, minmax(220px, 1fr))' : 'repeat(auto-fit, minmax(280px, 1fr))')};
      column-gap: 26px;
      // grid-template-rows: repeat(auto-fit, minmax(70px, 1fr));
      row-gap: 5px;

      .contain__select {
        display: flex;
        flex-direction: column;
        height: 62px;
        width: 100%;
      }

      .contain__input {
        display: flex;
        flex-direction: column;
        height: 62px;
        width: 100%;
        position: relative;
      }

      .contain__toggleButton {
        display: flex;
        flex-direction: column;
        height: 62px;
        width: 100%;
        position: relative;

        &__item {
          height: 27px;
          width: 220px;
        }
      }

      .contain__form-dropzone {
        display: flex;
        flex-direction: column;
        height: 62px;
        width: 100%;

        .dropzone {
          width: 100%;
          display: grid;
        }
      }

      .contain__titleInputPair {
        min-height: 96px;
      }
    }

    &__toggle {
      display: flex;
      flex-direction: column;
      height: 62px;
      width: 100%;
      align-items: flex-start;

      .toggle__item {
        height: 34px;
      }
    }

    &__radio {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 0 5px 10px 0;

      .contain__items-radio {
        display: flex;
        gap: 10px;
        min-height: 35px;
        width: 100%;
      }
    }

    .schedule_container {
      // width: calc(100% - 80px);
      grid-column: 1 / 4;
    }

    .calendar_container {
      width: 100%;
      grid-column: 1 / 4;

      .calendar_wrapper {
        margin-top: 15px;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        border: 1px solid #d2d3d4;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .color {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    .container_multiFormPagesWrapper {
      position: relative;
      width: 100%;
    }
  }
`;
