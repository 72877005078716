import React from 'react';
import { LinkButtonBig } from '../../../components/LinkButtonBig/LinkButtonBig';
import OverviewTitle from '../../../components/OverviewTitle/OverviewTitle';
import { menuEM } from '../../../data/dataEM';
import { OverviewEMWrapper } from './OverviewEMScreen.style';
import { titleEM } from '../../../data/dataEM';

export const OverviewEMScreen = () => {
  return (
    <OverviewEMWrapper>
      <OverviewTitle overviewTitle={titleEM} />
      <LinkButtonBig menuTop={menuEM} />
    </OverviewEMWrapper>
  );
};
