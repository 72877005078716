import { OverviewTitleWrapper } from './OverviewTitle.style';

const OverviewTitle = ({ overviewTitle }) => {
  const { title, subtitle, icon } = overviewTitle;

  return (
    <OverviewTitleWrapper>
      <h1>
        {' '}
        <img src={icon} alt="title-icon" /> {title}{' '}
      </h1>
      <h3> {subtitle} </h3>
    </OverviewTitleWrapper>
  );
};

export default OverviewTitle;
