import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import LoaderSpinner from '../../components/LoaderSpinner/LoaderSpinner';
import useUsers from '../../hooks/useUsers';
import WideNavigation from '../../navigation/WideNavigation/WideNavigation';
import { NavMenuLeftScreen } from '../NavMenuLeftScreen/NavMenuLeftScreen';
import { loginUserAction } from '../../redux/actions/login/actionCreators';
import { Footer } from '../../components/Footer/Footer';
import { getUsersPermissionAction } from '../../redux/actions/settingsScreen/user/actionCreators';

import '../../styles/StaticScreen.css';

const StaticScreen = () => {
  const { permissionsUser, getPermissionsUser } = useUsers();
  const [loading, setLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getState } = useStore();

  const user = getState()['login'];

  const initialState = {
    authChecked: false,
    loggedIn: false,
    user: {},
  };

  const initialStatePermissions = [];

  function calculateTime(time) {
    const timeNow = moment();

    const diffTime = moment.utc(moment(timeNow, 'YYYY/MM/DD HH:mm:ss').diff(moment(time, 'YYYY/MM/DD HH:mm:ss'))).format('HH');

    return diffTime > 3;
  }

  useEffect(() => {
    setTimeout(() => {
      if (user.loggedIn === false) {
        const tokenUser = localStorage.getItem('tokenStorage');
        if (tokenUser) {
          const userDecode = jwtDecode(tokenUser);

          dispatch(loginUserAction(userDecode));
        }
        setLoadingCounter(loadingCounter + 1);
      } else if (user.loggedIn === true && permissionsUser.length === 0) {
        getPermissionsUser(user.user.id);
        setLoadingCounter(loadingCounter + 1);
      } else if (user.user.rolesName === 'superadmin') {
        const isTokenExpired = calculateTime(user.user.timeLogin);
        if (!isTokenExpired) {
          setLoading(false);
        } else {
          localStorage.removeItem('tokenStorage');
          dispatch(loginUserAction(initialState));
          dispatch(getUsersPermissionAction(initialStatePermissions));
          navigate('/login', { replace: true });
        }
      } else {
        setLoading(false);
      }
    }, 100);
  }, [user, getPermissionsUser, dispatch, permissionsUser, loadingCounter]);

  return (
    <div className="static-screen">
      <div className="container">
        {loading ? (
          <LoaderSpinner />
        ) : (
          <div className="fullScreen">
            <NavMenuLeftScreen user={user} />
            <div className="contain">
              <WideNavigation />
            </div>
            {/* <UserProfile user={user} /> */}
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaticScreen;
