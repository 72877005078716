import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PasswordWatcher from '../../../components/PasswordWatcher/PasswordWatcher';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import { TableInfo } from '../../../components/TableInfo/TableInfo';
import { dataFilterExtension, dataScreenExtensions } from '../../../data/dataNumbering';
import useNumbering from '../../../hooks/useNumbering';
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { useSwal } from '../../../hooks/useSwal';
import useView from '../../../hooks/useView';
import { MenuFilter } from '../../../components/Filter/MenuFilter/MenuFilter';
import { nameTables } from '../../../data/dataTable';

import { ExtensionsWrapper } from './ExtensionsScreen.style';
import { routesNumbering, routesWide } from '../../../navigation/routes';

export const ExtensionsScreen = () => {
  const { arrayNumberingExtensionsHook, getExtensions, deleteExtensions, updateFileExtension, loading } = useNumbering();
  const { typeSwal } = useSwal();
  const { changeView, isCardView } = useView();

  const [search, setSearch] = useState([]);
  const [isFilter, setIsFilter] = useState({
    open: false,
    active: false,
  });
  const [showPassword, setShowPassword] = useState(false);

  const { notFound, icon } = dataScreenExtensions;

  const navigate = useNavigate();

  useEffect(() => {
    getExtensions();
    setSearch([]);
    
  }, [setSearch]);

  const COLUMNS = [
    {
      Header: 'Extensión',
      accessor: 'extension',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Contraseña SIP',
      accessor: 'password',
      Cell: ({ row }) => <PasswordWatcher row={row} showPassword={showPassword} text={'Contraseña'} />,
      disableSortBy: true,
    },
    {
      Header: 'Compañia',
      accessor: 'company',
    },
    {
      Header: 'Departamento',
      accessor: 'department',
    },
    {
      Header: 'Tiempo de ring',
      accessor: 'ringTimeout',
    },
    {
      Header: 'Identificación',
      accessor: 'identification',
    },
    {
      Header: 'Tipo de extensión',
      accessor: 'typeExtension',
    },
  ];

  const DATA = arrayNumberingExtensionsHook.map(
    ({
      extension,
      name,
      password,
      id,
      ringTimeout,
      typeExtension,
      Ddi,
      callForward,
      callForwardNA,
      companyId,
      record,
      voicemailActive,
      Company,
      Department,
      CategoriesGroup,
      callWaiting,
      sipPeerId,
      AstSipPeer,
    }) => ({
      extension,
      name,
      password,
      id,
      ringTimeout,
      typeExtension,
      identification: Ddi?.ddi,
      ddiId: Ddi?.id,
      callForward,
      callForwardNA,
      companyId,
      record,
      voicemailActive,
      company: Company?.name,
      department: Department?.name,
      departmentId: Department?.id,
      categoryGroup: CategoriesGroup?.description,
      categoryGroupId: CategoriesGroup?.id,
      callWaiting,
      sipPeerId,
      namedpickupgroup: AstSipPeer?.namedpickupgroup,
      allow: AstSipPeer?.allow,
    })
  );

  const allDataExport = arrayNumberingExtensionsHook.map(
    ({ extension, name, password, ringTimeout, typeExtension, Ddi, voicemailActive, Department, callForward, callForwardNA, CategoriesGroup, Company, record }) => ({
      Extensión: extension,
      Nombre: name,
      Contraseña: password,
      'Tiempo de ring': ringTimeout,
      'Tipo de extensión': typeExtension,
      Identificación: Ddi?.id,
      Buzón: voicemailActive ? 'Activado' : 'Desactivado',
      'Valor desvio permanente': callForward,
      'Valor desvio permanente NA': callForwardNA,
      'Grupo de categoría': CategoriesGroup?.description,
      Companñia: Company?.name,
      Departamento: Department?.name,
      Grabaciones: record ? record : 'No',
    })
  );
  const filterDataExport = search.map(({ extension, name, password, ringTimeout, typeExtension, ddiId, callForward, callForwardNA, voicemailActive, record, company, department, categoryGroup }) => ({
    Extensión: extension,
    Nombre: name,
    Contraseña: password,
    'Tiempo de ring': ringTimeout,
    'Tipo de extensión': typeExtension,
    Identificación: ddiId,
    Buzón: voicemailActive ? 'Activado' : 'Desactivado',
    'Valor desvio permanente': callForward,
    'Valor desvio permanente NA': callForwardNA,
    'Grupo de categoría': categoryGroup,
    Companñia: company,
    Departamento: department,
    Grabaciones: record ? record : 'No',
  }));

  const dataCard = () => {
    if (search.length > 0) {
      return search.map(
        ({
          extension,
          name,
          password,
          ringTimeout,
          typeExtension,
          ddiId,
          callForward,
          callForwardNA,
          companyId,
          record,
          voicemailActive,
          company,
          department,
          departmentId,
          categoryGroup,
          categoryGroupId,
          id,
        }) => ({
          title: name,
          icon: icon.phoneCard,
          subtitle: extension,
          icon2: icon.home,
          text: company,
          icon3: icon.hierarchy,
          text2: typeExtension,
          extension,
          name,
          password,
          ringTimeout,
          typeExtension,
          ddiId,
          callForward,
          callForwardNA,
          companyId,
          record,
          voicemailActive,
          company,
          department,
          departmentId,
          categoryGroup,
          categoryGroupId,
          id,
        })
      );
    } else {
      return arrayNumberingExtensionsHook.map(
        ({ extension, name, password, id, ringTimeout, typeExtension, Ddi, callForward, callForwardNA, companyId, record, voicemailActive, Company, Department, CategoriesGroup }) => ({
          title: name,
          icon: icon.phoneCard,
          subtitle: extension,
          icon2: icon.home,
          text: Company?.name,
          icon3: icon.hierarchy,
          text2: typeExtension,
          id,
          boolean: voicemailActive,
          extension,
          name,
          password,
          ringTimeout,
          typeExtension,
          identification: Ddi?.ddi,
          ddiId: Ddi?.id,
          callForward,
          callForwardNA,
          companyId,
          record,
          voicemailActive,
          company: Company?.name,
          department: Department?.name,
          departmentId: Department?.id,
          categoryGroup: CategoriesGroup?.description,
          categoryGroupId: CategoriesGroup?.id,
        })
      );
    }
  };

  const dataToCards = dataCard();

  const handleOpenFormCreate = () => {
    navigate(`/${routesWide.numbering}/${routesNumbering.createExtensions}`, {});
  };

  const handleOpenFormEdit = (item) => {
    navigate(`/${routesWide.numbering}/${routesNumbering.updateExtensions}`, {
      state: {
        state: item.original ? item.original : item,
      },
    });
  };

  const handleDelete = async (item) => {
    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response;
          if (item.original) {
            response = await deleteExtensions(item.original);
          } else {
            response = await deleteExtensions(item);
          }
          if (response.status === 200 || response.status === 201 || response.status === 204) {
            getExtensions();
            typeSwal('delete');
          } else if (response.status === 205) {
            let table = Object.keys(nameTables)
              .filter((p) => p.includes(response.data.table))
              .reduce((obj, key) => {
                obj[key] = nameTables[key];
                return obj[key];
              }, {});
            getExtensions();
            typeSwal('error', table);
          }
        } catch (err) {
          return err;
        }
      }
    });
  };

  const handleUploadFile = async (file) => {
    try {
      let response = await updateFileExtension(file);
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        getExtensions();
        typeSwal('upload');
      }
    } catch (err) {
      return err;
    } finally {
      window.location.reload();
    }
  };

  const tableInstance = useRef(null);

  const template = [
    {
      Extensión: '',
      Nombre: '',
      'Contraseña SIP': '',
      'Sede *id': '',
      'Departamento *id': '',
      'Tiempo de ring': '',
      'Identificacion *id': '',
      'Typo de extension': 'sip, rainbow, teams, virtual',

      'voicemailActive si/no': '',
      'Valor desvio permanente': '',
      'Valor desvio permanente NA': '',
      'Categoria *id': '',
    },
  ];

  return (
    <ExtensionsWrapper isFilter={isFilter.open}>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
            search: {
              position: 'left',
              data: DATA,
              columns: COLUMNS,
            },
            changeView: {
              position: 'right',
              function: changeView,
              isCardView: isCardView,
            },
            file: {
              position: 'right',
              data: isFilter.active ? filterDataExport : allDataExport,
              template,
            },
            filter: {
              position: 'left',
            },
          }}
          importFile={handleUploadFile}
          setSearch={setSearch}
          setIsFilter={setIsFilter}
          isFilter={isFilter}
        />
        {arrayNumberingExtensionsHook[0]?.status ? null : (
          <div className="contain__menuFilter">
            <MenuFilter isFilter={isFilter} setIsFilter={setIsFilter} data={DATA} filter={dataFilterExtension} setSearch={setSearch} />
          </div>
        )}
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : arrayNumberingExtensionsHook[0]?.status === 'notFound' ? (
        <div className="contain__table">
          <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} onClick={handleOpenFormCreate} />
        </div>
      ) : search[0]?.notFound ? (
        <div className="contain__notFound">
          <h4>{search[0].notFound}</h4>
        </div>
      ) : isCardView ? (
        <div className="contain__cardGrid">
          <CardGird data={dataToCards} onClick={handleOpenFormEdit} />
        </div>
      ) : (
        <div className="contain__table">
          <TableInfo
            format={'list'}
            COLUMNS={COLUMNS}
            DATA={search.length === 0 ? DATA : search}
            deleteFunction={handleDelete}
            updateFunction={handleOpenFormEdit}
            ref={tableInstance}
            onClick={handleOpenFormEdit}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
          />
        </div>
      )}
    </ExtensionsWrapper>
  );
};
