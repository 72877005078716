import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const PageScreenWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid ${colors.greyBorder};

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 1px;
    background: ${colors.primaryColor};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }

  .pageScreen__title {
    box-sizing: border-box;
    width: 100%;
    min-height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid ${colors.greyBorder};

    &__text {
      margin-left: 10px;
      color: ${colors.black};
      font-size: ${newFontSize.h3};
      font-weight: ${weight.medium};
      letter-spacing: 0.25px;
    }
  }

  .pageScreen {
    width: 80%;
    margin-top: 50px;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 70%);

    &__tabs {
      height: 33px;
      width: 100%;
      position: absolute;
      top: -33px;
      display: flex;
      overflow: hidden;

      &__tab {
        min-width: 130px;
        height: 100%;
        box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 25%);
        margin-left: 1px;
        border-radius: 10px 10px 0 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d2d3d445;

        &:hover {
          cursor: pointer;
          background-color: ${colors.secondaryColor};
        }

        &:active {
          transform: scale(0.96);
          transition: all 0.1s;
        }

        &__item {
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: ${newFontSize.button};
          color: ${colors.primaryColor};
          font-weight: ${weight.medium};
          border-radius: 5px 5px 0 0;
        }
      }
      .active {
        color: ${colors.primaryColor};
        background-color: ${colors.secondaryColor};
        transition: all 0.5s;
        height: 102%;
      }

      .inactive {
        color: #b7b6b6;
        transition: all 0.5s;
        background-color: #dbdbdb;
      }
    }

    &__form {
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }
`;
