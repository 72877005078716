import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, weight } from '../../theme/fonts';

export const LinkButtonBigWrapper = styled.div`

  display: flex;
  justify-content: center;
  gap: 80px;
  width: 100%;
  flex-wrap: wrap;



    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 25px;
      gap: 10px;
      width: 210px;
      height: 160px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      // box-shadow: 0px 0px 13.3962px rgba(127, 64, 166, 0.12), inset 0px 0px 3.34906px rgba(248, 249, 250, 0.1);
      border: 1px solid ${colors.greyLine};
      transition: all 0.3s;

      margin-top: 20px;

      filter: drop-shadow(13px 16px 8px rgba(73, 80, 87, 0.01)) drop-shadow(7px 9px 7px rgba(73, 80, 87, 0.04)) drop-shadow(7px 8px 10px rgba(73, 80, 87, 0.06)) drop-shadow(2px 2px 6px rgba(73, 80, 87, 0.07)) drop-shadow(0px 0px 0px rgba(73, 80, 87, 0.07));
      
      &:hover {
      background-color: ${colors.greyHover}; 
      transition: all 0.3s;

    }
    &:active {
      
      transform: scale(0.96);
      transition: all 0.1s;
    }
    }
   
    p {
      font-family: ${fontFamily.font};
      text-align: center;
      font-size: 13.39px;
      font-weight: ${weight.medium};
      line-height: 15px;
      width: 114.5px;
      margin: 7px 0 0 0;
      // color: ${colors.primaryColor};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      width: 75%;
      
      img {
        height: 15px;
      }
    }
  }
`;
