import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .user {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    text-align: right;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    .user__contain__title {
      display: flex;
      .user__title {
        font-weight: ${weight.light};
        font-size: ${newFontSize.body};
        color: ${colors.white};
        line-height: 16px;
        letter-spacing: 0.25px;
        z-index: ${(p) => (p.isClosed ? -1 : 10)};
        opacity: ${(p) => (p.isClosed ? 0 : 1)};
        transition: opacity 0.5s;
        transition-delay: ${(p) => (p.isClosed ? 0.7 : 0.9)}s;
        margin: 0;
      }
    }
    .contain__subtitle {
      display: flex;
      align-items: center;
      margin-top: 1px;
    }
    .subTitle {
      font-weight: ${weight.light};
      font-size: ${newFontSize.span};
      color: ${colors.white};
      line-height: 14px;
      letter-spacing: 0.25px;
      z-index: ${(p) => (p.isClosed ? -1 : 10)};
      opacity: ${(p) => (p.isClosed ? 0 : 1)};
      transition: opacity 0.5s;
      transition-delay: ${(p) => (p.isClosed ? 0.7 : 0.9)}s;
      margin: 0;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .image__icon {
      height: 10px;
      width: 10px;
      padding-top: 1px;
      padding-right: 5px;
    }
  }

  .photo {
    height: 35px;
    width: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
