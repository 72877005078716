export const routesWide = {
  overview: 'overview',
  numbering: 'numbering',
  applications: 'applications',
  cdr: 'cdr',
  outboundRoutes: 'outboundRoutes',
  preferences: 'preferences',
  profile: 'profile',
  help: 'help',
  settings: 'settings',
  EM: 'EM',
};

export const routesNumbering = {
  numberingList: 'numberingList',
  createNumberingList: 'numberingList/create',
  updateNumberingList: 'numberingList/update',
  extensions: 'extensions',
  createExtensions: 'extensions/create',
  updateExtensions: 'extensions/update/',
};

export const routesEM = {
  diffusionLists: 'diffusionLists',
  createDiffusionList: 'diffusionLists/create',
  updateDiffusionList: 'diffusionLists/update',
  alerts: 'alerts',
  createAlerts: 'alerts/create',
  updateAlerts: 'alerts/update',
  history: 'history',
  historyItem: 'history/item',
};

export const routesApplications = {
  conference: 'conference',
  createConferences: 'conference/create',
  updateConferences: 'conference/update',
  locutions: 'locutions',
  createLocutions: 'locutions/create',
  updateLocutions: 'locutions/update',
  mailboxes: 'mailboxes',
  createMailboxes: 'mailboxes/create',
  updateMailboxes: 'mailboxes/update',
  ivr: 'ivr',
  createIvr: 'ivr/create',
  updateIvr: 'ivr/update',
  songWaiting: 'songWaiting',
  createSongWaiting: 'songWaiting/create',
  updateSongWaiting: 'songWaiting/update',
  shortMarking: 'shortMarking',
  createShortMarking: 'shortMarking/create',
  updateShortMarking: 'shortMarking/update',
  queue: 'queue',
  createQueue: 'queue/create',
  updateQueue: 'queue/update',
};

export const routesPreferences = {
  calendar: 'calendar',
  createCalendar: 'calendar/create',
  updateCalendar: 'calendar/update',
  schedule: 'schedule',
  createSchedule: 'schedule/create',
  updateSchedule: 'schedule/update',
  protocolSetup: 'protocolSetup/',
  company: 'company',
  department: 'department',
  createDepartment: 'department/create',
  updateDepartment: 'department/update',
  createCompany: 'company/create',
  updateCompany: 'company/update',
};

export const routesSettings = {
  users: 'users',
  modules: 'modules',
  permissions: 'permissions',
  permissionsEdit: 'permissions/edit',
  companyInformation: 'companyInformation',
  createUser: 'users/create',
  updateUser: 'users/update/',
  configurationAsterisk: 'configurationAsterisk',
  api: 'apiKey',
  createApi: 'apiKey/create',
  domains: 'domains',
  createDomains: 'domains/create',
  updateDomains: 'domains/update',
  whitelist: 'whitelist',
  createWhitelist: 'whitelist/create',
  updateWhitelist: 'whitelist/update',
};

export const routesCallRegister = {
  general: 'general',
  activity: 'activity',
  productivity: 'productivity',
  customer: 'customer',
};

export const routesOutbound = {
  groupCategories: 'groupCategories',
  createGroupCategory: 'groupCategories/create',
  updateGroupCategory: 'groupCategories/update',
  categories: 'categories',
  createCategory: 'categories/create',
  updateCategory: 'categories/update',
  trunks: 'trunks',
  createTrunks: 'trunks/create',
  updateTrunks: 'trunks/update/',
};

export const routesHelp = {
  faqs: 'faqs',
  tutorials: 'tutorials',
  guide: 'guide',
  profile: 'profile/',
};
