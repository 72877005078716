import styled from 'styled-components';
import { colors } from '../../../theme/colors';
import { weight } from '../../../theme/fonts';

export const OptionSelectWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .options-select {
        margin-top: 5px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        height: 35px;

        &:hover {
            cursor: pointer;
            background: ${colors.secondaryColor};
        }

        &__item {
            padding: 0 15px;
            display: flex;
            align-items: center;
            height: 20px;
            width: 100%;
            font-weight: ${weight.regular};
            font-size: 14px;
            line-height: 18px;
        }

        &__input {
            padding: 0 15px;
            display: flex;
            align-items: center;
            height: 20px;
            width: 100%;
            font-weight: ${weight.regular};
            font-size: 14px;
            line-height: 18px;
        }
    }
    .option-select__input {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            margin: 0;
            font-weight: ${weight.regular};
            font-size: 14px;
            line-height: 18px;
            text-align: center;

        }
        input {
            width: 120px;
            border: 1px solid #d2d3d5;
            border-radius: 10px;
            padding: 2px 10px;
            margin-top: 5px;
            height: 28px;
            text-align: center;
        }

        .option-select__input-button {
          height: 20px;
          width: 100%;
          display: flex;
          margin-top: 10px;
          justify-content: start;
    }

    &__info {
        position: absolute;
        left: 100px;
    }
`;
