import React from 'react';

import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
import { menuEM } from '../../data/dataEM';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { EMNavigation } from '../../navigation/EMNavigation/EMNavigation';
import { EmergencyWrapper } from './EMScreen.style';

export const EMScreen = () => {
  return (
    <EmergencyWrapper>
      <Header image={menuNavLeft.menuTop[7].image} text={menuNavLeft.menuTop[7].text} />
      <MenuTop menu={menuEM} />
      <EMNavigation />
    </EmergencyWrapper>
  );
};
