//svg from NavMenuLeftTop
import arrowUpRight from '../../assets/images/navMenu/corner-up-right.svg';
import eye from '../../assets/images/navMenu/eye.svg';
import hash from '../../assets/images/navMenu/hash.svg';
import hatchecks from '../../assets/images/navMenu/hatchecks.svg';
import layoutGrid from '../../assets/images/navMenu/layout-grid.svg';
import settings from '../../assets/images/navMenu/settings-2.svg';
import { IconSquare, IconHash, IconLayoutGrid, IconListCheck, IconArrowForwardUp, IconHomeCog, IconAmbulance, IconAdjustmentsHorizontal } from '@tabler/icons-react';

//svg from NavMenuMedium
import ambulance from '../../assets/images/navMenu/ambulance.svg';

//svg from NavMenuLeftBottom
import adminPanel from '../../assets/images/navMenu/settings.svg';

//logo
import zerovozLogo from '../../assets/images/logo/zerovoz-logo.png';
import minizerovoz from '../../assets/images/logo/zerovoz-mini.svg';

//paths for routes navigation
import { routesWide } from '../../navigation/routes';

import arrow from '../../assets/images/navMenu/arrow.svg';

export const logo = zerovozLogo;
export const miniLogo = minizerovoz;

export const menuNavLeft = {
  chevrons: { icon: arrow, alt: 'button open close navbar' },
  menuTop: [
    {
      image: IconSquare,
      text: 'Overview',
      path: routesWide.overview,
      restricted: 'overview',
    },
    {
      image: IconHash,
      text: 'Numeraciones',
      path: routesWide.numbering,
      restricted: 'numbering',
    },
    {
      image: IconLayoutGrid,
      text: 'Aplicaciones',
      path: routesWide.applications,
      restricted: 'applications',
    },
    {
      image: IconListCheck,
      text: 'Registro de llamadas',
      path: routesWide.cdr,
      restricted: 'cdr',
    },
    {
      image: IconArrowForwardUp,
      text: 'Rutas de salida',
      path: routesWide.outboundRoutes,
      restricted: 'outboundRoutes',
    },
    {
      image: IconAdjustmentsHorizontal,
      text: 'Configuración',
      path: routesWide.preferences,
      restricted: 'preferences',
    },
    {
      text: 'Divider',
    },
    {
      image: IconAmbulance,
      text: 'Emergency manager',
      path: routesWide.EM,
      restricted: 'emergencyManager',
    },
  ],
  menuBottom: [
    {
      image: IconHomeCog,
      text: 'Panel de administración',
      path: routesWide.settings,
      restricted: 'settings',
    },
  ],
};
