import Axios from 'axios';

import {
  getEMAction,
  getEMAlarmsAction,
  getEMListExtensionAction,
  createEMListExtensionAction,
  updateEMListExtensionAction,
  createEMAlertsAction,
  changeEMAlertsCallAndRecordAction,
  getHistoryAction,
  loadFirstItemsHistoryAction,
} from '../actions/EM/actionCreators';

const urlApi = process.env.REACT_APP_URL;

export const getEMThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const arrayEM = await Axios.get(`${urlApi}EM`);
    dispatch(getEMAction(arrayEM.data));
  } catch (error) {
    return error;
  }
};

export const getEMListExtensionThunk = (setLoading) => async (dispatch) => {
  try {
    const arrayEMExtension = await Axios.get(`${urlApi}EM/diffusionList/extensions`);
    dispatch(getEMListExtensionAction(arrayEMExtension.data));
  } catch (error) {
    return error;
  }
};

export const createEMListExtensionThunk = (diffusionList, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlApi}EM/diffusionList/create`, {
      data: diffusionList,
    });
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createEMListExtensionAction(diffusionList));
    }
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const updateEMListExtensionThunk = (diffusionList, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlApi}EM/diffusionList/update`, {
      data: diffusionList,
    });
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(updateEMListExtensionAction(diffusionList));
    }
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const updateEMAlertsThunk = (alert, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlApi}EM/alerts/update`, {
      data: alert,
    });

    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const deleteEMListExtensionThunk = (diffusionList, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlApi}EM/diffusionList/delete`, {
      data: diffusionList,
    });

    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const getEMAlertsThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const arrayEMAlert = await Axios.get(`${urlApi}EM/alerts`);
    dispatch(getEMAlarmsAction(arrayEMAlert.data));
  } catch (error) {
    return error;
  } finally {
    setLoading(false);
  }
};

export const createEMAlertsThunk = (alert, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.post(`${urlApi}EM/alerts/create`, {
      data: alert,
    });
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      dispatch(createEMAlertsAction(alert));
    }
    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const changeEMAlertsCallAndRecordThunk = (callAndRecord, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const response = await Axios.patch(`${urlApi}EM/alerts/updateCallAndRecord`, {
      data: callAndRecord,
    });
    if (response.status === 200) {
      dispatch(changeEMAlertsCallAndRecordAction(callAndRecord));
    }
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const deleteEMAlertsThunk = (alert, setLoading) => async () => {
  setLoading(true);
  try {
    const response = await Axios.delete(`${urlApi}EM/alerts/delete`, {
      data: alert,
    });

    return response;
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};

export const uploadFileAlertsThunk = (file, setLoading) => async () => {
  const data = new FormData();
  data.append('file', file[0]);
  setLoading(true);
  try {
    var response = await Axios.post(`${urlApi}EM/alerts/uploadFile`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (err) {
    return err;
  } finally {
    return response;
  }
};

//History
export const getHistoriesThunk = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const histories = await Axios.get(`${urlApi}EM/history`);
    if (histories.status === 200) {
      dispatch(getHistoryAction(histories.data));
      dispatch(loadFirstItemsHistoryAction(histories.data));
    }
  } catch (err) {
    return err;
  } finally {
    setLoading(false);
  }
};
