import { FormScreen } from '../../../components/FormScreen/FormScreen';
import { formCreateApiKey } from '../../../data/dataSettings';
import useSettings from '../../../hooks/useSettings';
import { useSwal } from '../../../hooks/useSwal';
import { routesSettings, routesWide } from '../../../navigation/routes';

export const ApiScreenForm = ({ crudType }) => {
  const { createApi, settingForm, getApi } = useSettings();

  const { typeSwal } = useSwal();

  const data = formCreateApiKey;

  settingForm(
    { action: 'clearForm' },
    {
      form: 'apiKey',
    }
  );

  const onSubmit = async () => {
    if (crudType === 'create') {
      typeSwal('createLoader', '', settingForm, getApi, `/${routesWide.settings}/${routesSettings.api}`, 'api', createApi);
    }
  };

  return <>{<FormScreen data={data} onSubmit={onSubmit} crudType={crudType} form={settingForm} />}</>;
};
