import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwal } from '../../../hooks/useSwal';
import usePreferences from '../../../hooks/usePreferences';
import { dataScreenSchedule } from '../../../data/dataPreferences';
import { menuButtonsDropDownTable } from '../../../data/dataButtons';
import { DistributionTable } from '../../../components/DistributionTable/DistributionTable';
import { NavButtons } from '../../../components/NavButtons/NavButtons';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { CardGird } from '../../../components/CardGrid/CardGird';
import { routesPreferences } from '../../../navigation/routes';

import { ScheduleScreenWrapper } from './ScheduleScreen.style';

const ScheduleScreen = () => {
  const { scheduleArrayHook, getScheduleDB, deleteSchedule, loading, actualSchedule, enable, handleClick, dataToCardFunction } = usePreferences();

  const { typeSwal } = useSwal();

  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getScheduleDB();
    
  }, []);

  const navigate = useNavigate();

  const onClick = (item) => {
    console.log(item);
  };

  const handleDelete = async (item) => {
    const deleteScheduleItem = scheduleArrayHook.find((schedule) => schedule[0].id === item.id);
    const scheduleTimeIdArray = deleteScheduleItem.map((schedule) => schedule.scheduleTimeId);

    typeSwal('wantDelete').then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deleteSchedule(deleteScheduleItem[0].scheduleId, scheduleTimeIdArray);
          if (response.status === 200) {
            getScheduleDB();
            typeSwal('delete');
          }
        } catch (error) {
          return error;
        }
      }
    });
  };

  const handleOpenFormCreate = () => {
    navigate(`/preferences/${routesPreferences.createSchedule}`, {});
  };

  const handleOpenFormEdit = (item) => {
    const formState = scheduleArrayHook.find((schedule) => schedule[0].id === item.id);

    navigate(`/preferences/${routesPreferences.updateSchedule}`, {
      state: { schedule: formState, scheduleId: formState[0].scheduleId },
    });
  };

  menuButtonsDropDownTable.forEach((item) => {
    if (item.id === 1) item.function = handleOpenFormEdit;
    else if (item.id === 2) item.function = handleDelete;
  });

  const options = scheduleArrayHook.map((fullSchedule) => ({
    text: 'Horario ' + fullSchedule[0]?.Schedule.name,
    id: fullSchedule[0]?.id,
  }));

  options.sort((a, b) => {
    if (a.text === 'Horario 24/7') {
      return -1;
    } else if (b.text === 'Horario 24/7') {
      return 1;
    } else {
      return a.id - b.id; // ordena por índice
    }
  });

  const dataToCards = dataToCardFunction(actualSchedule.schedule);

  return (
    <ScheduleScreenWrapper>
      <div className="contain__buttons">
        <NavButtons
          optionButtons={{
            add: {
              position: 'left',
              function: handleOpenFormCreate,
            },
          }}
        />
      </div>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <div className="distributionTable">
          <DistributionTable
            title={dataScreenSchedule.title}
            option={options}
            component={<CardGird data={dataToCards} onClick={onClick} />}
            selectedId={selectedId}
            setSelectId={setSelectedId}
            handleClick={handleClick}
            menuButtonsDropDown={menuButtonsDropDownTable}
            enable={enable}
            isDisableFirstOption={true}
          />
        </div>
      )}
    </ScheduleScreenWrapper>
  );
};

export default ScheduleScreen;
