import React, { useState } from 'react';

import { Logo } from '../../components/Logo/Logo';
import { NavMenuLeft } from '../../components/NavMenuLeft/NavMenuLeft';
import { logo, menuNavLeft, miniLogo } from '../../data/dataNavMenuLeft';
import { NavMenuLeftWrapper } from './NavMenuLeftScreen.style';

export const NavMenuLeftScreen = ({ user }) => {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <NavMenuLeftWrapper isClosed={isClosed} width={isClosed ? '53px' : '232px'}>
      <div className="contain__navMenuLeft">
        <NavMenuLeft menu={menuNavLeft} isClosed={isClosed} setIsClosed={setIsClosed} user={user} />
      </div>
    </NavMenuLeftWrapper>
  );
};
