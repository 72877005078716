import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateNumberingExtensions } from '../../../data/dataNumbering';
import { useDepartments } from '../../../hooks/useDepartments';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import { useOutbound } from '../../../hooks/useOutboundRoutes';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';

export const ExtensionsFormScreen = ({ crudType }) => {
  const { createExtension, formExtensionsReducer, getNumberingList, arrayNumberingHook, updateExtensions, listNumberingForm, getExtensions, arrayNumberingExtensionsHook } = useNumbering();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { departmentsHook, getDepartments } = useDepartments();
  const { getCategoriesGroupHook, categoriesGroupHook } = useOutbound();
  const { typeSwal } = useSwal();

  useEffect(() => {
    getNumberingList();
    getHeadquarter();
    getDepartments();
    getCategoriesGroupHook();
    getExtensions();
    
  }, []);

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const { state } = useLocation();

  const data = formCreateNumberingExtensions;

  const handleRecord = (record) => {
    if (record === '' || record === undefined || record === null) return { empty: true, record: [] };
    else {
      const allRecords = record.split(',');

      const auxRecords = { empty: false, record: [] };

      allRecords.forEach((rec) => {
        if (rec === 'internas')
          auxRecords.record.push({
            label: 'Internas',
            value: 'internas',
            id: 1,
          });
        else if (rec === 'salientes')
          auxRecords.record.push({
            label: 'Salientes',
            value: 'salientes',
            id: 2,
          });
        else if (rec === 'externas')
          auxRecords.record.push({
            label: 'Externas',
            value: 'externas',
            id: 3,
          });
        else console.log('error');
      });

      return auxRecords;
    }
  };

  const optionsAllow = [
    { label: 'Allaw', value: 'alaw', id: 1 },
    { label: 'Ulaw', value: 'ulaw', id: 2 },
    { label: 'g729', value: 'g729', id: 3 },
  ];

  useEffect(() => {
    listNumberingForm({ action: 'clearForm' }, { form: 'extension' });

    if (crudType === 'edit') {
      const finalRecord = handleRecord(state.state.record);
      const namedpickupgroup = state.state.namedpickupgroup === 'null' ? '' : state.state.namedpickupgroup;
      const depArr = namedpickupgroup?.split(', ');
      const newNamePickupGroup = [];
      const removeAccents = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };
      if (depArr) {
        for (let dep of depArr) {
          const match = departmentsHook.find((obj) => removeAccents(obj.name.toLowerCase()) === removeAccents(dep.toLowerCase()));
          if (match) {
            newNamePickupGroup.push({ label: match.name, value: match.id });
          }
        }
      }
      const nameAllow = state.state.allow === 'null' ? '' : state.state.allow;
      const allowArr = nameAllow?.split(',');
      const newAllow = optionsAllow.filter((item) => allowArr?.includes(item.value)).map((item) => ({ value: item.value, label: item.label, id: item.id }));

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'pikedGroup',
        },
        newNamePickupGroup
      );

      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'allow',
        },
        newAllow
      );

      listNumberingForm(
        {
          action: 'input',
          value: state.state.sipPeerId,
        },
        {
          form: 'extension',
          element: 'sipPeerId',
        }
      );

      listNumberingForm(
        {
          action: 'input',
          value: state.state.id,
        },
        {
          form: 'extension',
          element: 'id',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.extension,
        },
        {
          form: 'extension',
          element: 'extension',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.name,
        },
        {
          form: 'extension',
          element: 'name',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.password,
        },
        {
          form: 'extension',
          element: 'password',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.ringTimeout,
        },
        {
          form: 'extension',
          element: 'ringTimeout',
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'identification',
        },
        {
          label: state.state.identification,
          value: String(state.state.ddiId),
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'typeExtension',
        },
        {
          label: state.state.typeExtension,
          value: String(state.state.typeExtension),
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.callForward,
        },
        {
          form: 'extension',
          element: 'callForward',
        }
      );
      listNumberingForm(
        {
          action: 'input',
          value: state.state.callForwardNA,
        },
        {
          form: 'extension',
          element: 'callForwardNA',
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'companyId',
        },
        {
          label: state.state?.company,
          value: String(state.state.companyId),
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'department',
        },
        {
          label: state.state?.department,
          value: String(state.state.departmentId),
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'extension',
          element: 'categories',
        },
        {
          label: state.state.categoryGroup,
          value: String(state.state.categoryGroupId),
        }
      );
      listNumberingForm(
        {
          action: 'toggle',
          value: state.state.voicemailActive,
        },
        {
          form: 'extension',
          element: 'voicemailActive',
        }
      );
      if (!finalRecord.empty)
        finalRecord.record.forEach((rec) => {
          listNumberingForm({ action: 'select-option', value: rec }, { form: 'extension', element: 'record' });
        });
      listNumberingForm(
        {
          action: 'toggle',
          // value: { label: 'Activa', value: 1, id: 6 },
          value: state.state.callWaiting,
        },
        {
          form: 'extension',
          element: 'callWaiting',
        }
      );
    }
    
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof formExtensionsReducer === 'object' && arrayNumberingHook.length > 0 && arrayHeadquarterHook.length > 0 && departmentsHook.length > 0 && categoriesGroupHook.length > 0) {
        data.steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref.element === 'identification') {
              arrayNumberingHook.forEach((item3) => {
                if (item3.ddi === null) {
                  item3.ddi = 'not defined';
                }
                let identification = {
                  label: item3.ddi,
                  value: item3.id?.toString(),
                };
                const foundDdi = item2.options.some((ddi) => ddi.value === identification.value);
                if (!foundDdi && identification.value !== undefined) {
                  item2.options.push(identification);
                }
              });
            }
            if (item2.ref.element === 'companyId') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref.element === 'department') {
              item2.options = [];
            }
            if (item2.ref.element === 'categories') {
              categoriesGroupHook.forEach((item3) => {
                if (item3.description === null) {
                  item3.description = 'not defined';
                }
                const category = {
                  label: item3?.description,
                  value: item3.id?.toString(),
                };
                const found = item2.options.some((e) => e.value === category.value);
                if (!found && category.value !== undefined) {
                  item2.options.push(category);
                }
              });
            }
            return item2;
          });
          return item;
        });
        if (
          crudType === 'edit' &&
          (formExtensionsReducer.number === '' ||
            formExtensionsReducer.number !== state.state.extension ||
            formExtensionsReducer.callForward !== state.state.callForward ||
            formExtensionsReducer.department.value === '' ||
            +formExtensionsReducer.category.value !== +state.state.categoryGroupId ||
            (formExtensionsReducer.pikedGroup.length === 0 && formExtensionsReducer.typeExtension.label === 'sip'))
        ) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref.element) {
                case 'extension':
                  item2.defaultValues = formExtensionsReducer.number;
                  break;
                case 'name':
                  item2.defaultValues = formExtensionsReducer.name;
                  break;
                case 'password':
                  item2.defaultValues = formExtensionsReducer.password;
                  break;
                case 'ringTimeout':
                  item2.defaultValues = formExtensionsReducer.ringTimeout;
                  break;
                case 'identification':
                  item2.options.filter((item3) => {
                    if (item3.label === formExtensionsReducer.identification.label) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'typeExtension':
                  item2.options.filter((item3) => {
                    if (item3.label === formExtensionsReducer.typeExtension.label) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'callForward':
                  item2.defaultValues = formExtensionsReducer.callForward;
                  break;
                case 'callForwardNA':
                  item2.defaultValues = formExtensionsReducer.callForwardNA;
                  break;

                case 'companyId':
                  item2.options.filter((item3) => {
                    if (item3.value === formExtensionsReducer.companyId.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'department':
                  item2.defaultValues = formExtensionsReducer.department;

                  break;
                case 'categories':
                  item2.options.filter((item3) => {
                    if (item3.value === formExtensionsReducer.category.value) {
                      item2.defaultValues = item3;
                    }
                    return false;
                  });
                  break;
                case 'record':
                  item2.defaultValues = [];
                  if (formExtensionsReducer.record.length !== 0) {
                    formExtensionsReducer.record.map((rec) =>
                      item2.options.forEach((rec2) => {
                        if (rec.value === rec2.value) item2.defaultValues = [...item2.defaultValues, rec];
                      })
                    );
                  }

                  break;
                case 'voicemailActive':
                  item2.defaultValues = formExtensionsReducer.voicemailActive;

                  break;
                case 'callWaiting':
                  item2.defaultValues = formExtensionsReducer.callWaiting;

                  break;
                case 'pikedGroup':
                  item2.defaultValues = formExtensionsReducer.pikedGroup;
                  break;

                case 'allow':
                  item2.defaultValues = formExtensionsReducer.allow;
                  break;

                default:
                  break;
              }
              return item2;
            });

            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
    
  }, [loadingCounter, crudType]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getExtensions, `/${routesWide.numbering}/${routesNumbering.extensions}`, 'extension', createExtension);
    } else if (crudType === 'edit') {
      if (state.route) typeSwal('updateLoader', '', listNumberingForm, getExtensions, `${state.route}`, 'extension', updateExtensions);
      else typeSwal('updateLoader', '', listNumberingForm, getExtensions, `/${routesWide.numbering}/${routesNumbering.extensions}`, 'extension', updateExtensions);
    }
  };
  return <>{loading ? <LoaderSpinner /> : <FormScreen data={data} onSubmit={onSubmit} form={listNumberingForm} dataSave={dataSave} crudType={crudType} arrScreen={arrayNumberingExtensionsHook} />}</>;
};
