import React, { useRef } from 'react';
import { IconDotsVertical } from '@tabler/icons-react';

import { DropDown } from '../DropDown/DropDown';
import { DistributionTableWrapper } from './DistributionTable.style';
import { colors } from '../../theme/colors';

export const DistributionTable = ({ title, option, component, handleClick, menuButtonsDropDown, enable, isDisableFirstOption = false }) => {
  function AddedRef() {
    let refContainer = useRef(null);
    return refContainer;
  }

  return (
    <DistributionTableWrapper>
      <div className="title">
        {title.map((item, index) => (
          <div className="title__contain" key={index}>
            <img className="title__icon" src={item.icon} alt={item.text} />
            <h5 className="title__text">{item.text}</h5>
          </div>
        ))}
      </div>
      <div className="info">
        <div className="options">
          {option.map((item, index) => (
            <div className={enable === item.id ? 'option active' : 'option'} key={index} onClick={() => handleClick(item)}>
              <div className="option__iconText">
                {item.icon && <img className="option__icon" src={item.icon} alt={item.text} />}
                <p className={item.icon ? 'option__text' : 'option__textNoIcon'}>{item.text}</p>
              </div>
              {index === 0 && isDisableFirstOption ? null : (
                <div className="dropDown">
                  <DropDown
                    data={item}
                    layout={item.id}
                    menuButtonsDropDown={menuButtonsDropDown}
                    Icon={IconDotsVertical}
                    direction={'column'}
                    position={'calc(2% + 5px);'}
                    refContainer={AddedRef()}
                    iconColor={colors.grey}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="component">{component}</div>
      </div>
    </DistributionTableWrapper>
  );
};
