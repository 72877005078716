import styled from 'styled-components';
import { colors } from '../../../../theme/colors';
import { newFontSize, weight } from '../../../../theme/fonts';

export const EditPermissionsWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  .permission {
    box-sizing: border-box;
    height: calc(100% - 55px);

    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid ${colors.greyLine};

      h4 {
        font-size: ${newFontSize.h4};
        font-weight: ${weight.medium};
        color: ${colors.black};
      }

      img {
        margin-right: 2px;
      }
    }

    &__contain-cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(395px, 395px));
      padding: 37px;
      column-gap: 37px;
      row-gap: 10px;
      justify-content: center;
    }
  }
`;
