import { useCallback, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import {
  addConfigFormCallbackAudioIdAction,
  addConfigFormPreferencesAction,
  changeApiFormAction,
  changeConfigFormAdministradorSecretoAction,
  changeConfigFormAzureSubscripcionKeyAction,
  changeConfigFormBxferCountAction,
  changeConfigFormCallrecpathAction,
  changeConfigFormCapturarLlamadaAction,
  changeConfigFormCodelenAction,
  changeConfigFormColaEndAction,
  changeConfigFormColaStartAction,
  changeConfigFormColgarLlamadaAction,
  changeConfigFormConferenceEndAction,
  changeConfigFormConferenceStartAction,
  changeConfigFormConfrecpathAction,
  changeConfigFormDfRingtimeoutAction,
  changeConfigFormDigitoDeMarcacionAction,
  changeConfigFormDomainAction,
  changeConfigFormExtensionEndAction,
  changeConfigFormExtensionStartAction,
  changeConfigFormFormatoDefaultVmAction,
  changeConfigFormGroupEndAction,
  changeConfigFormGroupStartAction,
  changeConfigFormIpAdministradorAction,
  changeConfigFormIpLocalAction,
  changeConfigFormMailBoxesEndAction,
  changeConfigFormMailBoxesStartAction,
  changeConfigFormMaquinaAction,
  changeConfigFormMaxInboundChanAction,
  changeConfigFormMydynamicFearuresAction,
  changeConfigFormNumcortaOriginAction,
  changeConfigFormNumeroMaximoDeExtensionesAction,
  changeConfigFormOpcColaAction,
  changeConfigFormOptAction,
  changeConfigFormOutOptionsAction,
  changeConfigFormOutTimeoutAction,
  changeConfigFormPasswordAction,
  changeConfigFormPathWssAction,
  changeConfigFormPortWssAction,
  changeConfigFormPrefixMovCorpAction,
  changeConfigFormPriMovAction,
  changeConfigFormPuertoAdministradorAction,
  changeConfigFormRutaIndicacionDeSonidoAction,
  changeConfigFormSeltimeoutAction,
  changeConfigFormShortMarkingEndAction,
  changeConfigFormShortMarkingStartAction,
  changeConfigFormSubNotifyApiAction,
  changeConfigFormTimeoutAction,
  changeConfigFormTimeoutCfAction,
  changeConfigFormTransferenciaAtendidaAction,
  changeConfigFormTransferenciaCiegaAction,
  changeConfigFormUsuarioAdministradorAction,
  changeConfigFormVmgreetpathAction,
  changeDomainsFormDomainAction,
  changeDomainsFormIdAction,
  changeNameApiFormAction,
  changeWhitelistFormIdAction,
  changeWhitelistFormIpAddrAction,
  clearApiFormAction,
  clearFormDomainsAction,
  clearFormWhitelistAction,
} from '../redux/actions/settingsScreen/settingsActions/actionsCreator';
import {
  getPrivilegesThunk,
  getMenusSubmenusThunk,
  postPrivilegesThunk,
  getRolesThunk,
  createApiThunk,
  getApiThunk,
  getDomainsThunk,
  getWhitelistThunk,
  createDomainThunk,
  updateDomainThunk,
  deleteDomainThunk,
  uploadFileDomainThunk,
  createWhitelistThunk,
  updateWhitelistThunk,
  deleteWhitelistThunk,
  uploadFileWhitelistThunk,
  createConfigThunk,
  getConfigDataThunk,
} from '../redux/thunks/settingsThunks';
import { getUsersThunk } from '../redux/thunks/usersThunks';

const useSettings = () => {
  const dispatch = useDispatch();
  const { getState } = useStore();

  const [loading, setLoading] = useState(true);
  const user = getState()['login'];

  // array save the all users created + permissions the user for id + array what have menus and submenus for roles
  const users = useSelector((user) => user.usersReducer);
  const loadListUsers = useSelector((user) => user.loadListUsersReducer);

  // array save the all menus and submenus
  const settingMenusSubmenusReducer = useSelector((menus) => menus.settingMenusSubmenusReducer);

  const privileges = useSelector((privileges) => privileges.getPrivilegesReducer);

  // array save privileges
  const objectPrivileges = useSelector((privileges) => privileges.objectPrivileges);

  const roles = useSelector((reducer) => reducer.getRolesReducer);

  const getUsers = useCallback(() => {
    dispatch(getUsersThunk(setLoading));
  }, [dispatch]);

  const getPrivileges = useCallback(() => {
    dispatch(getPrivilegesThunk());
  }, [dispatch]);

  const getMenusSubmenus = useCallback(() => {
    dispatch(getMenusSubmenusThunk());
  }, [dispatch]);

  const postPrivileges = (privileges) => {
    dispatch(postPrivilegesThunk(privileges));
  };

  const getRoles = useCallback(() => {
    dispatch(getRolesThunk());
    
  }, []);

  //API

  const api = useSelector((reducer) => reducer.getApiReducer);

  const getApi = useCallback(() => {
    const userId = user.user.id;
    dispatch(getApiThunk(userId, setLoading));
    
  }, []);

  const createApi = useCallback(() => {
    const apiKey = getState()['createApiKeyReducer'];
    const userId = user.user.id;
    apiKey.userId = userId;

    const response = dispatch(createApiThunk(apiKey));
    return response;

    
  }, [dispatch]);

  //Domains

  const domains = useSelector((reducer) => reducer.getDomainsReducer);

  const getDomains = useCallback(() => {
    dispatch(getDomainsThunk(setLoading));
    // eslint-disable-next-line
  }, []);

  const domainsForm = useSelector((reducer) => reducer.domainsFormReducer);

  const createDomain = useCallback(() => {
    const domain = getState()['domainsFormReducer'];
    const response = dispatch(createDomainThunk(domain));
    return response;
    
  }, [dispatch]);

  const updateDomain = useCallback(() => {
    const domain = getState()['domainsFormReducer'];
    const response = dispatch(updateDomainThunk(domain));
    return response;
    
  }, [dispatch]);

  const deleteDomain = useCallback(
    (domain) => {
      let response = dispatch(deleteDomainThunk(domain, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileDomain = useCallback(
    (file) => {
      let response = dispatch(uploadFileDomainThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //Whitelist

  const whitelist = useSelector((reducer) => reducer.getWhitelistReducer);

  const getWhitelist = useCallback(() => {
    dispatch(getWhitelistThunk(setLoading));
    // eslint-disable-next-line
  }, []);

  const whitelistForm = useSelector((reducer) => reducer.whitelistFormReducer);

  const createWhitelist = useCallback(() => {
    const whitelist = getState()['whitelistFormReducer'];
    const response = dispatch(createWhitelistThunk(whitelist));
    return response;
    
  }, [dispatch]);

  const updateWhitelist = useCallback(() => {
    const whitelist = getState()['whitelistFormReducer'];
    const response = dispatch(updateWhitelistThunk(whitelist));
    return response;
    
  }, [dispatch]);

  const deleteWhitelist = useCallback(
    (domain) => {
      let response = dispatch(deleteWhitelistThunk(domain, setLoading));
      return response;
    },
    [dispatch]
  );

  const uploadFileWhitelist = useCallback(
    (file) => {
      let response = dispatch(uploadFileWhitelistThunk(file, setLoading));
      return response;
    },
    [dispatch]
  );

  //configurationAsterisk
  let configDataForm = useSelector((item) => item.configFormReducer);
  let configDataGet = useSelector((item) => item.getConfigReducer);

  const getConfigData = useCallback(() => {
    dispatch(getConfigDataThunk(setLoading));
    // eslint-disable-next-line
  }, []);

  const createConfiguration = useCallback(() => {
    const config = getState()['configFormReducer'];
    let response = dispatch(createConfigThunk(config));
    return response;
    
  }, [dispatch]);

  //form settings
  const settingForm = useCallback((arg, ref, inputValue) => {
    switch (ref.form) {
      case 'config':
        switch (arg.action) {
          case 'select-option':
            if (ref.element === 'preferences') {
              dispatch(addConfigFormPreferencesAction(arg.value));
            }
            if (ref.element === 'callback_audio_id') {
              dispatch(addConfigFormCallbackAudioIdAction(inputValue));
            }
            break;
          case 'toggleButton':
            dispatch(changeConfigFormMaquinaAction(inputValue));
            break;

          case 'input':
            if (ref.element === 'ipLocal') {
              dispatch(changeConfigFormIpLocalAction(arg.value));
            }
            if (ref.element === 'domain') {
              dispatch(changeConfigFormDomainAction(arg.value));
            }
            if (ref.element === 'portWss') {
              dispatch(changeConfigFormPortWssAction(arg.value));
            }

            if (ref.element === 'pathWss') {
              dispatch(changeConfigFormPathWssAction(arg.value));
            }
            if (ref.element === 'password') {
              dispatch(changeConfigFormPasswordAction(arg.value));
            }
            if (ref.element === 'azure_subscripcion_key') {
              dispatch(changeConfigFormAzureSubscripcionKeyAction(arg.value));
            }
            if (ref.element === 'ruta_indicacion_de_sonido') {
              dispatch(changeConfigFormRutaIndicacionDeSonidoAction(arg.value));
            }
            if (ref.element === 'numero_maximo_de_extensiones') {
              dispatch(changeConfigFormNumeroMaximoDeExtensionesAction(arg.value));
            }
            if (ref.element === 'digito_de_marcacion') {
              dispatch(changeConfigFormDigitoDeMarcacionAction(arg.value));
            }
            if (ref.element === 'administrador_secreto') {
              dispatch(changeConfigFormAdministradorSecretoAction(arg.value));
            }
            if (ref.element === 'usurario_administrador') {
              dispatch(changeConfigFormUsuarioAdministradorAction(arg.value));
            }
            if (ref.element === 'puerto_administrador') {
              dispatch(changeConfigFormPuertoAdministradorAction(arg.value));
            }
            if (ref.element === 'ip_administrador') {
              dispatch(changeConfigFormIpAdministradorAction(arg.value));
            }
            if (ref.element === 'extension_start') {
              dispatch(changeConfigFormExtensionStartAction(arg.value));
            }
            if (ref.element === 'extension_end') {
              dispatch(changeConfigFormExtensionEndAction(arg.value));
            }
            if (ref.element === 'group_start') {
              dispatch(changeConfigFormGroupStartAction(arg.value));
            }
            if (ref.element === 'group_end') {
              dispatch(changeConfigFormGroupEndAction(arg.value));
            }
            if (ref.element === 'mailBoxes_start') {
              dispatch(changeConfigFormMailBoxesStartAction(arg.value));
            }
            if (ref.element === 'mailBoxes_end') {
              dispatch(changeConfigFormMailBoxesEndAction(arg.value));
            }
            if (ref.element === 'conference_start') {
              dispatch(changeConfigFormConferenceStartAction(arg.value));
            }
            if (ref.element === 'conference_end') {
              dispatch(changeConfigFormConferenceEndAction(arg.value));
            }
            if (ref.element === 'shortMarking_start') {
              dispatch(changeConfigFormShortMarkingStartAction(arg.value));
            }
            if (ref.element === 'shortMarking_end') {
              dispatch(changeConfigFormShortMarkingEndAction(arg.value));
            }
            if (ref.element === 'cola_start') {
              dispatch(changeConfigFormColaStartAction(arg.value));
            }
            if (ref.element === 'cola_end') {
              dispatch(changeConfigFormColaEndAction(arg.value));
            }
            if (ref.element === 'opt') {
              dispatch(changeConfigFormOptAction(arg.value));
            }
            if (ref.element === 'timeout') {
              dispatch(changeConfigFormTimeoutAction(arg.value));
            }
            if (ref.element === 'timeout_cf') {
              dispatch(changeConfigFormTimeoutCfAction(arg.value));
            }
            if (ref.element === 'out_timeout') {
              dispatch(changeConfigFormOutTimeoutAction(arg.value));
            }
            if (ref.element === 'out_options') {
              dispatch(changeConfigFormOutOptionsAction(arg.value));
            }
            if (ref.element === 'confrecpath') {
              dispatch(changeConfigFormConfrecpathAction(arg.value));
            }
            if (ref.element === 'vmgreetpath') {
              dispatch(changeConfigFormVmgreetpathAction(arg.value));
            }
            if (ref.element === 'callrecpath') {
              dispatch(changeConfigFormCallrecpathAction(arg.value));
            }
            if (ref.element === 'mydynamic_fearures') {
              dispatch(changeConfigFormMydynamicFearuresAction(arg.value));
            }
            if (ref.element === 'df_ringtimeout') {
              dispatch(changeConfigFormDfRingtimeoutAction(arg.value));
            }
            if (ref.element === 'formato_default_vm') {
              dispatch(changeConfigFormFormatoDefaultVmAction(arg.value));
            }
            if (ref.element === 'bxfer_count') {
              dispatch(changeConfigFormBxferCountAction(arg.value));
            }
            if (ref.element === 'seltimeout') {
              dispatch(changeConfigFormSeltimeoutAction(arg.value));
            }
            if (ref.element === 'prefix_mov_corp') {
              dispatch(changeConfigFormPrefixMovCorpAction(arg.value));
            }
            if (ref.element === 'pri_mov') {
              dispatch(changeConfigFormPriMovAction(arg.value));
            }
            if (ref.element === 'numcorta_origin') {
              dispatch(changeConfigFormNumcortaOriginAction(arg.value));
            }
            if (ref.element === 'codelen') {
              dispatch(changeConfigFormCodelenAction(arg.value));
            }
            if (ref.element === 'opc_cola') {
              dispatch(changeConfigFormOpcColaAction(arg.value));
            }
            if (ref.element === 'max_inbound_chan') {
              dispatch(changeConfigFormMaxInboundChanAction(arg.value));
            }
            if (ref.element === 'external_api_url') {
              dispatch(changeConfigFormSubNotifyApiAction(arg.value));
            }
            if (ref.element === 'capturar_llamada') {
              dispatch(changeConfigFormCapturarLlamadaAction(arg.value));
            }
            if (ref.element === 'transferencia_ciega') {
              dispatch(changeConfigFormTransferenciaCiegaAction(arg.value));
            }
            if (ref.element === 'colgar_llamada') {
              dispatch(changeConfigFormColgarLlamadaAction(arg.value));
            }
            if (ref.element === 'tranferencia_atendida') {
              dispatch(changeConfigFormTransferenciaAtendidaAction(arg.value));
            }

            break;
          default:
            break;
        }
        break;

      case 'domains':
        switch (arg.action) {
          case 'clearForm':
            dispatch(clearFormDomainsAction());
            break;
          case 'input':
            if (ref.element === 'domain') {
              dispatch(changeDomainsFormDomainAction(arg.value));
            }
            break;
          case 'id':
            if (ref.element === 'id') {
              dispatch(changeDomainsFormIdAction(arg.value));
            }
            break;
          default:
            break;
        }
        break;
      case 'whitelist':
        switch (arg.action) {
          case 'clearForm':
            dispatch(clearFormWhitelistAction());
            break;
          case 'input':
            if (ref.element === 'ip_addr') {
              dispatch(changeWhitelistFormIpAddrAction(arg.value));
            }
            break;
          case 'id':
            if (ref.element === 'id') {
              dispatch(changeWhitelistFormIdAction(arg.value));
            }
            break;
          default:
            break;
        }
        break;
      case 'apiKey':
        if (arg.action === 'clearForm') {
          dispatch(clearApiFormAction());
        }
        if (ref.element === 'apiKey') {
          dispatch(changeApiFormAction(arg));
        }
        if (ref.element === 'name') {
          dispatch(changeNameApiFormAction(arg.value));
        }
        break;

      default:
        break;
    }
    
  }, []);

  return {
    //save in the same array
    users,
    getUsers,
    loadListUsers,
    getPrivileges,
    //save in the same array
    settingMenusSubmenusReducer,
    getMenusSubmenus,
    privileges,
    //save in the same object
    objectPrivileges,
    postPrivileges,

    //dif Roles
    roles,
    getRoles,

    //Api
    createApi,
    getApi,
    api,

    //Domains
    getDomains,
    domains,
    createDomain,
    updateDomain,
    domainsForm,
    deleteDomain,
    uploadFileDomain,

    //Whitelist
    getWhitelist,
    whitelist,
    whitelistForm,
    createWhitelist,
    updateWhitelist,
    deleteWhitelist,
    uploadFileWhitelist,

    //config
    createConfiguration,
    configDataForm,
    configDataGet,
    getConfigData,

    loading,

    settingForm,
  };
};

export default useSettings;
