import React from 'react';

import PermissionContext from './PermissionContext';

const PermissionProvider = ({ permissionsUser, children }) => {
  const isAllowedTo = (permission) => permissionsUser.includes(permission);

  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
