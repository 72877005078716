import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FormScreen } from '../../../components/FormScreen/FormScreen';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { formCreateNumberingList } from '../../../data/dataNumbering';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import useNumbering from '../../../hooks/useNumbering';
import usePreferences from '../../../hooks/usePreferences';
import { useSwal } from '../../../hooks/useSwal';
import { routesNumbering, routesWide } from '../../../navigation/routes';
import { addDestinationCalendarAction, addDestinationOpenAction } from '../../../redux/actions/numbering/actionCreator';

export const ListNumberFormScreen = ({ crudType }) => {
  const { listNumberingForm, createNumberingList, FormListNumberingReducer, updateNumberingList, getNumberingList, arrayNumberingHook } = useNumbering();
  const { typeSwal } = useSwal();
  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { getCalendar, getScheduleDB } = usePreferences();
  const { getApplicationsLocutions, arrayLocutionsHook, getDestination, destination } = useApplications();

  const [loading, setLoading] = useState(true);
  const [dataSave, setDataSave] = useState(false);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const dispatch = useDispatch();

  const location = useLocation();

  let destinationCalendarEdit = [];

  if (crudType === 'edit') {
    location.state.destinationCalendar.forEach((item) => {
      const calendar = {
        label: item.CalendarName === null ? 'Predeterminado' : item.CalendarName.name,
        value: item.CalendarName === null ? 'Predeterminado' : item.CalendarName.id.toString(),
      };

      const found = destinationCalendarEdit.find((obj) => obj.calendar.value === calendar.value && obj.calendar.label === calendar.label);
      if (!found) {
        destinationCalendarEdit.push({
          id: destinationCalendarEdit.length + 1,
          calendar,
          destinationOpen: [],
        });

        const soundPrompt = {
          label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
          value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
        };
        const destination = {
          label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
          value:
            item.Destination?.destinationType === 'external'
              ? item.Destination?.destinationType
              : item.Destination?.destinationType === 'hangup'
              ? item.Destination?.destinationType
              : item.Destination?.destinationId.toString(),
        };
        const schedule = {
          label: item.Schedule.name,
          value: item.Schedule.id.toString(),
        };

        const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();

        for (let i = 0; i < destinationCalendarEdit.length; i++) {
          if (destinationCalendarEdit[i].calendar?.value === calendarId) {
            destinationCalendarEdit[i].destinationOpen.push({
              id: destinationCalendarEdit[i].destinationOpen.length + 1,
              soundPrompt,
              destination,
              schedule,
            });
          }
        }
      } else {
        if (item.Schedule === null) {
          const soundPromptClose = {
            label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
            value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
          };
          const destinationClose = {
            label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
            value:
              item.Destination?.destinationType === 'external'
                ? item.Destination?.destinationType
                : item.Destination?.destinationType === 'hangup'
                ? item.Destination?.destinationType
                : item.Destination?.destinationId.toString(),
          };

          const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();
          for (let i = 0; i < destinationCalendarEdit.length; i++) {
            if (destinationCalendarEdit[i].calendar?.value === calendarId) {
              destinationCalendarEdit[i].soundPromptClose = soundPromptClose;
              destinationCalendarEdit[i].destinationClose = destinationClose;
            }
          }
        } else {
          const soundPrompt = {
            label: item.Soundprompt === null ? '' : item.Soundprompt?.description,
            value: item.Soundprompt === null ? '' : item.Soundprompt?.id.toString(),
          };
          const destination = {
            label: item.Destination?.destinationType === 'external' ? item.Destination?.destinationValue : item.Destination?.destinationType,
            value:
              item.Destination?.destinationType === 'external'
                ? item.Destination?.destinationType
                : item.Destination?.destinationType === 'hangup'
                ? item.Destination?.destinationType
                : item.Destination?.destinationId.toString(),
          };
          const schedule = {
            label: item.Schedule.name,
            value: item.Schedule.id.toString(),
          };

          const calendarId = item.calendarId === null ? 'Predeterminado' : item.calendarId.toString();

          for (let i = 0; i < destinationCalendarEdit.length; i++) {
            if (destinationCalendarEdit[i].calendar?.value === calendarId) {
              destinationCalendarEdit[i].destinationOpen.push({
                id: destinationCalendarEdit[i].destinationOpen.length + 1,
                soundPrompt,
                destination,
                schedule,
              });
            }
          }
        }
      }
    });
  }

  const data = formCreateNumberingList;

  useEffect(() => {
    getHeadquarter();
    getCalendar();
    getScheduleDB();
    getApplicationsLocutions();
    getDestination();
    listNumberingForm(
      { action: 'clearForm' },
      {
        form: 'numberingList',
      }
    );
    if (crudType === 'edit') {
      destinationCalendarEdit.forEach((item) => {
        if (item.id !== 1) {
          dispatch(addDestinationCalendarAction(item.id));
        }
        item.destinationOpen.forEach((item2) => {
          let newDestinationOpen = { id: item2.id };
          let refId = item.id;
          let newObjectWithRef = {
            newDestinationOpen,
            refId,
          };
          dispatch(addDestinationOpenAction(newObjectWithRef));
        });
      });
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'companyId',
        },
        {
          label: location.state?.company,
          value: String(location.state?.companyId),
        }
      );
      listNumberingForm(
        {
          action: 'select-option',
        },
        {
          form: 'numberingList',
          element: 'department',
        },
        {
          label: location.state?.department,
          value: String(location.state?.departmentId),
        }
      );
      destinationCalendarEdit.forEach((item) => {
        listNumberingForm(
          {
            action: 'select-option',
          },
          {
            form: 'numberingList',
            element: 'calendar',
          },
          {
            label: item.calendar?.label,
            value: item.calendar?.value,
            optionRefPage: item.id,
          }
        );
        listNumberingForm(
          {
            action: 'select-option',
          },
          {
            form: 'numberingList',
            element: 'soundPromptClose',
          },
          {
            label: item.soundPromptClose?.label,
            value: item.soundPromptClose?.value,
            optionRefPage: item.id,
          }
        );
        listNumberingForm(
          {
            action: 'select-option',
          },
          {
            form: 'numberingList',
            element: 'destinationClose',
          },
          {
            label: item.destinationClose?.label,
            value: item.destinationClose?.value,
            optionRefPage: item.id,
          }
        );
        item.destinationOpen.forEach((item2) => {
          listNumberingForm(
            {
              action: 'select-option',
            },
            {
              form: 'numberingList',
              element: 'schedule',
            },
            {
              label: item2.schedule?.label,
              value: item2.schedule?.value,
              optionRefPage: item.id,
              destinationOpen: item2.id,
            }
          );
          listNumberingForm(
            {
              action: 'select-option',
            },
            {
              form: 'numberingList',
              element: 'soundPrompt',
            },
            {
              label: item2.soundPrompt?.label,
              value: item2.soundPrompt?.value,
              optionRefPage: item.id,
              destinationOpen: item2.id,
            }
          );
          listNumberingForm(
            {
              action: 'select-option',
            },
            {
              form: 'numberingList',
              element: 'destination',
            },
            {
              label: item2.destination?.label,
              value: item2.destination?.value,
              optionRefPage: item.id,
              destinationOpen: item2.id,
            }
          );
        });
      });
      Object.keys(location.state).map((item) =>
        data.steps.map((item2) =>
          item2.input.map((item3) => {
            if (item === item3.ref?.element) {
              return listNumberingForm(
                {
                  action: item3.cardType,
                  value: location.state[item],
                },
                {
                  form: item3.ref.form,
                  element: item3.ref.element,
                }
              );
            } else {
              return null;
            }
          })
        )
      );
    }
    
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (typeof FormListNumberingReducer === 'object' && arrayHeadquarterHook.length > 0) {
        data.steps.forEach((item) => {
          item.input.forEach((item2) => {
            switch (item2.ref?.element) {
              case 'companyId':
                arrayHeadquarterHook.forEach((item3) => {
                  if (item3.name === null) {
                    item3.name = 'not defined';
                  }
                  const company = {
                    label: item3.name,
                    value: item3.id?.toString(),
                  };
                  const found = item2.options.some((e) => e.value === company.value);
                  if (!found && company.value !== undefined) {
                    item2.options.push(company);
                  }
                });
                break;
              case 'multiFormPages':
                item2.dataRef.forEach((item4) => {
                  item4.destinationOpen?.forEach((item5) => {
                    item5.input.forEach((item8) => {
                      if (item8.ref.element === 'soundPrompt') {
                        arrayLocutionsHook.forEach((item6) => {
                          const soundPrompt = {
                            label: item6.description,
                            value: item6.id?.toString(),
                          };
                          const found = item8.options.some((e) => e.value === soundPrompt.value);
                          if (!found && soundPrompt.value !== undefined) {
                            item8.options.push(soundPrompt);
                          }
                        });
                      }
                      if (item8.ref.element === 'destination') {
                        destination.forEach((item6) => {
                          const destinationName = {
                            label: item6.destinationType,
                            value: item6.id?.toString(),
                          };
                          const found = item8.options.some((e) => e.value === destinationName.value);
                          if (!found) {
                            item8.options.push(destinationName);
                          }
                        });
                      }
                    });
                  });
                  item4.input?.forEach((item5) => {
                    if (item5.ref.element === 'soundPromptClose') {
                      arrayLocutionsHook.forEach((item6) => {
                        const soundPrompt = {
                          label: item6.description,
                          value: item6.id?.toString(),
                        };
                        const found = item5.options.some((e) => e.value === soundPrompt.value);
                        if (!found && soundPrompt.value !== undefined) {
                          item5.options.push(soundPrompt);
                        }
                      });
                    }
                    if (item5.ref.element === 'destinationClose') {
                      destination.forEach((item6) => {
                        const destinationName = {
                          label: item6.destinationType,
                          value: item6.id?.toString(),
                        };
                        const found = item5.options.some((e) => e.value === destinationName.value);
                        if (!found) {
                          item5.options.push(destinationName);
                        }
                      });
                    }
                  });
                });

                break;
              default:
                break;
            }
          });
        });

        if (crudType === 'edit' && (FormListNumberingReducer.ddi === '' || FormListNumberingReducer.ddi !== location.state.ddi)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.steps.map((item) => {
            item.input.map((item2) => {
              switch (item2.ref?.element) {
                case 'ddi':
                  item2.defaultValues = FormListNumberingReducer.ddi;
                  break;
                case 'name':
                  item2.defaultValues = FormListNumberingReducer.name;
                  break;

                case 'companyId':
                  item2.options.filter((item3) => {
                    if (item3.value === FormListNumberingReducer.companyId.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'department':
                  item2.defaultValues = FormListNumberingReducer.department;

                  break;

                default:
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') {
          setLoading(false);
        }
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
    
  }, [crudType, loadingCounter]);

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', createNumberingList);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', listNumberingForm, getNumberingList, `/${routesWide.numbering}/${routesNumbering.numberingList}`, 'numberingList', updateNumberingList);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <FormScreen
          data={data}
          onSubmit={onSubmit}
          form={listNumberingForm}
          dataSave={dataSave}
          crudType={crudType}
          maxwidth={'1000px'}
          reducerForm={'FormListNumberingReducer'}
          arrScreen={arrayNumberingHook}
        />
      )}
    </>
  );
};
