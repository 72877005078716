import React, { useEffect, useRef, useState } from 'react';
import { IconX, IconChevronDown } from '@tabler/icons-react';
import { OptionSelect } from './OptionSelect/OptionSelect';
import { SelectWrapper } from './Select.style';
import { colors } from '../../theme/colors';

export const Select = ({ data, onChange, name, placeholder, idPage, destinationOpen, itemForm, defaultValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState('');
  const [selectOptionName, setSelectOptionName] = useState();

  useEffect(() => {
    if (Object.keys(defaultValue).length > 0) {
      setSelectOptionName(defaultValue);
    }
  }, [defaultValue]);

  const handleOpenOptions = () => {
    setIsOpen(!isOpen);
    setShowInfo(false);
  };

  const handleShowInfo = (value, items) => {
    setValue(items.options);
    setShowInfo(value);
  };

  const refContainer = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refContainer.current && !refContainer.current.contains(event.target)) {
        setIsOpen(false);
        setShowInfo(false);
        setShowInput(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleRemoveOption = (option, name) => {
    setSelectOptionName();
    onChange(option, 'clear', idPage, destinationOpen, itemForm, name);
  };

  return (
    <SelectWrapper ref={refContainer}>
      <div className="select">
        <div className="select__contain">
          <div className="select__item" onClick={() => handleOpenOptions()}>
            <div className="select__text">
              <p className={selectOptionName ? 'select__text__itemSelected' : 'select__text__item'}>{selectOptionName ? selectOptionName.label : placeholder}</p>
              {selectOptionName && (
                <IconX width={15} strokeWidth={4} fontWeight={4} color={colors.primaryColor} onClick={() => handleRemoveOption(selectOptionName, name)} className="select__text__remove" />
              )}
            </div>

            <div className="select__img__dropdown">
              <IconChevronDown className="select__img__dropdown__item" height={18} strokeWidth={3} />
            </div>
          </div>
          {isOpen && (
            <div className="select__options">
              {data.map((item, index) => (
                <div key={index} className="select__option" onMouseEnter={() => setShowInput(false)}>
                  {item.options ? (
                    <OptionSelect data={item} handleShowInfo={handleShowInfo} type={'option'} />
                  ) : item.type === 'input' ? (
                    <OptionSelect
                      data={item}
                      onChange={onChange}
                      setSelectOptionName={setSelectOptionName}
                      type={'input'}
                      name={name}
                      handleShowInfo={setShowInput}
                      idPage={idPage}
                      destinationOpen={destinationOpen}
                      itemForm={itemForm}
                    />
                  ) : (
                    <OptionSelect
                      data={item}
                      onChange={onChange}
                      setSelectOptionName={setSelectOptionName}
                      type={'optionData'}
                      handleShowInfo={handleShowInfo}
                      name={name}
                      idPage={idPage}
                      destinationOpen={destinationOpen}
                      itemForm={itemForm}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {showInfo && (
            <div className="select__optionsSub" onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)}>
              {value.map((item, index) => (
                <div className="select__optionSub" key={index}>
                  <OptionSelect
                    data={item}
                    type={'data'}
                    onChange={onChange}
                    setSelectOptionName={setSelectOptionName}
                    name={name}
                    idPage={idPage}
                    destinationOpen={destinationOpen}
                    itemForm={itemForm}
                  />
                </div>
              ))}
            </div>
          )}
          {showInput && (
            <div className="select__optionsSub-input" onMouseEnter={() => setShowInput(true)}>
              <div className="select__optionSub-input">
                <OptionSelect
                  type={'inputData'}
                  onChange={onChange}
                  setSelectOptionName={setSelectOptionName}
                  name={name}
                  handleShowInfo={setShowInput}
                  idPage={idPage}
                  destinationOpen={destinationOpen}
                  itemForm={itemForm}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </SelectWrapper>
  );
};
