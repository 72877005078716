// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
div#root {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    font-family: 'Ubuntu', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
}

.screen__inside-size {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.contain__buttons {
    box-sizing: border-box;
    height: 55px;
}

.contain__table {
    box-sizing: border-box;
    height: calc(100% - 55px);
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 1px;
}

.tooltip {
    background: #222529;
    border-radius: 10px;
    z-index: 9999;
    font-size: 12px;
    color: #fff;
    padding: 6px 8px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;IAGI,YAAY;IACZ,WAAW;IACX,OAAO;IACP,MAAM;IACN,SAAS;IACT,UAAU;IACV,6JAA6J;IAC7J,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["html,\nbody,\ndiv#root {\n    height: 100%;\n    width: 100%;\n    left: 0;\n    top: 0;\n    margin: 0;\n    padding: 0;\n    font-family: 'Ubuntu', apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\na {\n    text-decoration: none;\n    color: inherit;\n}\n\n.screen__inside-size {\n    box-sizing: border-box;\n    width: 100%;\n    height: 100%;\n}\n\n.contain__buttons {\n    box-sizing: border-box;\n    height: 55px;\n}\n\n.contain__table {\n    box-sizing: border-box;\n    height: calc(100% - 55px);\n    position: relative;\n    display: flex;\n    width: 100%;\n    margin-top: 1px;\n}\n\n.tooltip {\n    background: #222529;\n    border-radius: 10px;\n    z-index: 9999;\n    font-size: 12px;\n    color: #fff;\n    padding: 6px 8px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
