import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { LoginScreen } from '../../screens/LoginScreen/LoginScreen';
import StaticScreen from '../../screens/StaticScreen/StaticScreen';

import './StaticNavigation.css';

export const StaticNavigation = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(JSON.parse(localStorage.getItem('tokenStorage')) || false);

  const setAuth = (value) => {
    setIsAuthenticated(value);
  };

  useEffect(() => {
    localStorage.setItem('tokenStorage', JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  return (
    <div className="contain-router">
      <BrowserRouter>
        <Routes>
          <Route path={'/*'} element={isAuthenticated === false ? <Navigate to={'/login'} replace /> : <StaticScreen />} />
          <Route path="/login" element={<LoginScreen setAuth={setAuth} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
