import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const NavMenu = styled.div`
  height: 100%;
  .contain__navLeft {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .iconActionMenu {
    height: 79px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    &__contain {
      height: 30px;
      transform: rotate(90deg);
      padding-right: calc(53px - 30px);
    }
  }

  .container__top {
    padding-left: 5px;
  }

  .container__bottom {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    padding-left: 5px;
  }

  .item__contain {
    position: relative;
    display: flex;
    height: 45px;
    width: 100%;
    align-items: center;
    transition: all 0.3s;
    border-radius: 10px 0 0 10px;
    margin-top: 10px;

    &:hover {
      cursor: pointer;
      background-color: ${colors.secondaryColor};
      .item__text {
        color: ${colors.primaryColor};
        transition: all 0.3s;
      }
      .item__image {
        color: ${colors.primaryColor};
        transition: all 0.3s;
      }
    }

    .item__text {
      z-index: ${(p) => (p.isClosed ? -1 : 10)};
      padding: 5px;
      margin: 0;
      color: ${colors.white};
      font-size: ${newFontSize.button};
      font-family: ${fontFamily.font};
      font-weight: ${weight.medium};
      opacity: ${(p) => (p.isClosed ? 0 : 1)};
      transition: opacity 0.5s;
      transition-delay: ${(p) => (p.isClosed ? 0.7 : 0.9)}s;
      padding-left: 30px;
    }

    .item__image {
      position: fixed;
      padding-left: 10px;
      height: 16px;
      width: 16px;
      transition: all 0.1s;
      color: ${colors.white};
      transition: all 0.3s;
    }
  }
  .item__divider {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      height: 1px;
      background: ${colors.white};
      width: 100%;
      margin: auto;
    }
  }
  .item__active {
    display: flex;
    height: 45px;
    width: 100%;
    align-items: center;
    background-color: ${colors.secondaryColor};
    // -webkit-box-shadow: inset -2px 0px 0px 0px #9fa2b6;
    // -moz-box-shadow: inset -2px 0px 0px 0px #9fa2b6;
    // box-shadow: inset -2px 0px 0px 0px #9fa2b6;
    border-radius: 10px 0 0 10px;
    margin-top: 10px;

    .item__image {
      height: 16px;
      width: 16px;
      color: ${colors.primaryColor};
      position: fixed;
      padding-left: 10px;
    }

    .item__text {
      padding: 5px;
      margin: 0;
      color: ${colors.primaryColor};
      font-size: ${newFontSize.button};
      font-family: ${fontFamily.font};
      font-weight: ${weight.medium};
      opacity: ${(p) => (p.isClosed ? 0 : 1)};
      transition: opacity 1s;
      transition-delay: ${(p) => (p.isClosed ? 0 : 0.9)}s;
      z-index: ${(p) => (p.isClosed ? -1 : 10)};
      padding-left: 30px;
    }
  }
`;
