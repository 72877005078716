import React from 'react';

import { colors } from '../../theme/colors';
import { ToggleSwitch } from '../ToggleSwich/ToggleSwitch';

import { ModuleCardWrapper } from './ModuleCard.style';

export const ModuleCard = ({ module }) => {
  return (
    <ModuleCardWrapper>
      <div className="module__title">
        <p>{module.title}</p>
      </div>
      <div className="module__description">
        <p>{module.description}</p>
      </div>
      <ToggleSwitch colorRole={colors.primaryColor} />
    </ModuleCardWrapper>
  );
};
