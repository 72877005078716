import React from 'react';
import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { menuSettings } from '../../data/dataSettings';
import { SettingsNavigation } from '../../navigation/SettingsNavigation/SettingsNavigation';
import { SettingsScreenWrapper } from './SettingsScreen.style';

export const SettingsScreen = () => {
  return (
    <SettingsScreenWrapper>
      <Header image={menuNavLeft.menuBottom[0].image} text={menuNavLeft.menuBottom[0].text} />
      <MenuTop menu={menuSettings} />
      <SettingsNavigation />
    </SettingsScreenWrapper>
  );
};
