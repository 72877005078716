import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner';
import { PageScreen } from '../../../components/PageScreen/PageScreen';
import { formCreateQueue } from '../../../data/dataApplications';
import useApplications from '../../../hooks/useApplications';
import { useHeadquarter } from '../../../hooks/useHeadquarter';
import { useSwal } from '../../../hooks/useSwal';
import { routesApplications, routesWide } from '../../../navigation/routes';
import useNumbering from '../../../hooks/useNumbering';

export const QueueFormScreen = ({ crudType }) => {
  const { applicationsForm, getQueues, createQueue, updateQueue, getSongWaitingLink, songWaiting, getApplicationsLocutions, arrayLocutionsHook, queuesForm } = useApplications();
  const { arrayNumberingExtensionsHook, getExtensions } = useNumbering();

  const { arrayHeadquarterHook, getHeadquarter } = useHeadquarter();
  const { typeSwal } = useSwal();

  const location = useLocation();

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [dataSave, setDataSave] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const data = formCreateQueue;

  useEffect(() => {
    applicationsForm({ action: 'clearForm' }, { form: 'queue' });

    
  }, []);

  useEffect(() => {
    getHeadquarter();
    getSongWaitingLink();
    getApplicationsLocutions();
    getExtensions();

    const foundSongWaiting = (name) => {
      const found = songWaiting.find((item) => item.name === name);
      return {
        label: found?.name,
        value: String(found?.id),
      };
    };

    if (crudType === 'edit') {
      let form = 'queue';
      let foundMusicclass = foundSongWaiting(location.state?.AstQueue?.musicclass);

      let agents = location.state.QueueMember.map((member) => {
        const extension = arrayNumberingExtensionsHook.find((ext) => ext.id === member.userId);
        return extension ? { ...extension, order: member.order } : null;
      }).filter((extension) => extension !== null);

      let pausedAgents = location.state.QueueMember.filter((agent) => agent.paused === true);
      let externalAgents = location.state.QueueMember.filter((agent) => agent.external === true);

      let pausedExtensions = arrayNumberingExtensionsHook.filter((extension) => {
        return pausedAgents.some((obj) => obj.userId === extension.id);
      });

      let externalExtensions = arrayNumberingExtensionsHook.filter((extension) => {
        return externalAgents.some((obj) => obj.userId === extension.id);
      });
      applicationsForm(
        {
          action: 'select-option',
        },
        {
          form,
          element: 'musicclass',
        },
        foundMusicclass
      );

      if (location.state.QueueMember.length !== agents.length) {
        setLoadingCounter(loadingCounter + 1);
      } else if (location.state.QueueMember.length === agents.length && !refresh) {
        //miss agents external and agents paused
        setRefresh(true);
        applicationsForm(
          {
            action: 'input',
            value: location.state.AstQueue.id,
          },
          {
            form,
            element: 'idAstQueue',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.id,
          },
          { form, element: 'id' }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.name,
          },
          {
            form,
            element: 'name',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.number,
          },
          {
            form,
            element: 'number',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.max_wainting_users,
          },
          {
            form,
            element: 'max_waiting_users',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.call_to_use_extensions,
          },
          {
            form,
            element: 'call_to_use_extensions',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.AstQueue.timeout,
          },
          {
            form,
            element: 'timeout_queue',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.AstQueue.retry,
          },
          {
            form,
            element: 'retry',
          }
        );
        applicationsForm(
          {
            action: 'input',
            value: location.state?.timeout_priority,
          },
          {
            form,
            element: 'timeout_priority',
          }
        );
        applicationsForm(
          {
            action: 'select-option',
          },
          {
            form,
            element: 'companyId',
          },
          {
            label: location.state?.Company?.name,
            value: String(location.state?.Company.id),
          }
        );

        //miss destination & preferences
        applicationsForm(
          {
            action: 'select-option',
          },
          {
            form,
            element: 'selectStrategy',
          },
          {
            label: location.state?.strategy === 'ringall' ? 'Todos a la vez' : location.state.strategy === '2' ? 'Por orden' : 'Por orden grupal',
            value: location.state?.strategy === 'ringall' ? 1 : location.state?.strategy === '2' ? 2 : 3,
          }
        );
        applicationsForm(
          {
            action: 'agents',
          },
          {
            form,
            element: 'addButton',
          },
          agents
        );
        pausedExtensions.forEach((agent) => {
          applicationsForm(
            {
              action: 'agents',
            },
            {
              form,
              element: 'toggleButton',
            },
            agent
          );
        });
        externalExtensions.forEach((agent) => {
          applicationsForm(
            {
              action: 'agents',
            },
            {
              form,
              element: 'switchButton',
            },
            agent
          );
        });
      }
    }

    
  }, [loadingCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (typeof arrayHeadquarterHook === 'object' && arrayHeadquarterHook.length > 0 && songWaiting.length > 0 && arrayLocutionsHook.length > 0 && arrayNumberingExtensionsHook.length > 0) {
        data.tabs[0].steps.map((item) => {
          item.input.map((item2) => {
            if (item2.ref?.element === 'companyId') {
              arrayHeadquarterHook.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const company = {
                  label: item3.name,
                  value: item3.id.toString(),
                };
                const found = item2.options.some((e) => e.value === company.value);
                if (!found && company.value !== undefined) {
                  item2.options.push(company);
                }
              });
            }
            if (item2.ref?.element === 'musicclass') {
              songWaiting.forEach((item3) => {
                if (item3.name === null) {
                  item3.name = 'not defined';
                }
                const musicWaiting = {
                  label: item3.name,
                  value: item3.id.toString(),
                };
                const found = item2.options.some((e) => e.value === musicWaiting.value);
                if (!found && musicWaiting.value !== undefined) {
                  item2.options.push(musicWaiting);
                }
              });
            }
            if (item2.ref?.element === 'preferences') {
              item2.options.forEach((item3) => {
                if (item3.selectData) {
                  arrayLocutionsHook.forEach((item4) => {
                    if (item4.description === null) {
                      item4.description = 'not defined';
                    }
                    const locutions = {
                      label: item4.description,
                      value: item4.id.toString(),
                    };
                    const found = item3.selectData.options.some((e) => e.value === locutions.value);
                    if (!found && locutions.value !== undefined) {
                      item3.selectData.options.push(locutions);
                    }
                  });
                }
              });
            }

            return item2;
          });
          return item;
        });
        if (crudType === 'edit' && (queuesForm.id === '' || queuesForm.id !== location.state.id)) {
          setLoadingCounter(loadingCounter + 1);
        } else if (crudType === 'edit') {
          data.tabs[0].steps.map((item) => {
            item.input.map((item2) => {
              if (item2.navSlider) {
                const { selectStrategy } = item2.navSlider;
                selectStrategy.defaultValues = queuesForm.strategy;
              }
              switch (item2.ref?.element) {
                case 'name':
                  item2.defaultValues = queuesForm.name;
                  break;
                case 'number':
                  item2.defaultValues = queuesForm.number;
                  break;
                case 'max_waiting_users':
                  item2.defaultValues = queuesForm.max_waiting_users;
                  break;
                case 'call_to_use_extensions':
                  item2.defaultValues = queuesForm.call_to_use_extensions;
                  break;
                case 'timeout_queue':
                  item2.defaultValues = queuesForm.timeout_queue;
                  break;
                case 'retry':
                  item2.defaultValues = queuesForm.retry;
                  break;
                case 'timeout_priority':
                  item2.defaultValues = queuesForm.timeout_priority;
                  break;
                case 'companyId':
                  item2.options.filter((item3) => {
                    if (item3.value === queuesForm.companyId.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                case 'musicclass':
                  item2.options.filter((item3) => {
                    if (item3.value === queuesForm.musicclass.value) {
                      return (item2.defaultValues = item3);
                    }
                    return false;
                  });
                  break;
                default:
                  break;
              }
              return item2;
            });
            return item;
          });
          setLoading(false);
        } else if (crudType === 'create') setLoading(false);
      } else {
        setLoadingCounter(loadingCounter + 1);
      }
    }, 100);
    
  });
  const columnsModal = [
    {
      Header: 'Extensión',
      accessor: 'extension',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
    },

    {
      Header: 'Compañia',
      accessor: 'company',
    },
    {
      Header: 'Identificación',
      accessor: 'identification',
    },
    {
      Header: 'Tipo de extensión',
      accessor: 'typeExtension',
    },
  ];

  const dataModal = arrayNumberingExtensionsHook.map(
    ({
      extension,
      name,
      password,
      id,
      ringTimeout,
      typeExtension,
      Ddi,
      callForward,
      callForwardNA,
      companyId,
      record,
      voicemailActive,
      Company,
      Department,
      CategoriesGroup,
      callWaiting,
      sipPeerId,
      AstSipPeer,
    }) => ({
      extension,
      name,
      password,
      id,
      ringTimeout,
      typeExtension,
      identification: Ddi?.ddi,
      ddiId: Ddi?.id,
      callForward,
      callForwardNA,
      companyId,
      record,
      voicemailActive,
      company: Company?.name,
      department: Department?.name,
      departmentId: Department?.id,
      categoryGroup: CategoriesGroup?.description,
      categoryGroupId: CategoriesGroup?.id,
      callWaiting,
      sipPeerId,
      namedpickupgroup: AstSipPeer?.namedpickupgroup,
      allow: AstSipPeer?.allow,
    })
  );

  const dataModalButtonToTable = {
    dataModal,
    columnsModal,
  };

  const onSubmit = async () => {
    setDataSave(true);
    if (crudType === 'create') {
      typeSwal('createLoader', '', applicationsForm, getQueues, `/${routesWide.applications}/${routesApplications.queue}`, 'queues', createQueue);
    } else if (crudType === 'edit') {
      typeSwal('createLoader', '', applicationsForm, getQueues, `/${routesWide.applications}/${routesApplications.queue}`, 'queues', updateQueue);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <PageScreen data={data} onSubmit={onSubmit} form={applicationsForm} dataSave={dataSave} crudType={crudType} maxwidth={'1000px'} dataModalButtonToTable={dataModalButtonToTable} />
      )}
    </>
  );
};
