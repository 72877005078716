import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { newFontSize, weight } from '../../theme/fonts';

export const HeaderWrapper = styled.header`
  height: 80px;
  background-color: ${colors.white};
  align-items: center;
  justify-content: center;
  display: flex;
  .header__title {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  h1 {
    font-size: ${newFontSize.h2};
    margin: 0;
    color: ${colors.primaryColor};
    font-weight: ${weight.regular};
  }
`;
