import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const CardGridWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 230px));
  grid-template-rows: repeat(auto-fit, minmax(160px, 160px));
  overflow: scroll;

  column-gap: 10px;
  row-gap: 10px;
  justify-items: start;

  &::-webkit-scrollbar {
    width: 2px;
    background: white;
    height: 5px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 2px;
    background: ${colors.primaryColor};

    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar-track-piece {
    height: 80%;
  }
`;
