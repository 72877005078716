import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';

import { colors } from '../../theme/colors';

export default function LoaderSpinner() {
  return (
    <LoaderContainer>
      <ClipLoader color={colors.primaryColor} size={40} />
    </LoaderContainer>
  );
}

export const LoaderContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 88%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
