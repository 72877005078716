import React from 'react';

import { Header } from '../../components/Header/Header';
import { MenuTop } from '../../components/MenuTop/MenuTop';
import { NumberingNavigation } from '../../navigation/NumberingNavigation/NumberingNavigation';
import { menuNavLeft } from '../../data/dataNavMenuLeft';
import { menuNumbering } from '../../data/dataNumbering';
import { NumberingWrapper } from './NumberingScreen.style';
import PermissionProvider from '../../components/Permission/PermissionProvider';

export const NumberingScreen = () => {
  return (
    <PermissionProvider>
      <NumberingWrapper>
        <Header image={menuNavLeft.menuTop[1].image} text={menuNavLeft.menuTop[1].text} />
        <MenuTop menu={menuNumbering} />
        <NumberingNavigation />
      </NumberingWrapper>
    </PermissionProvider>
  );
};
