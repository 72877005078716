import styled from 'styled-components';
import { colors } from '../../theme/colors';
import { fontFamily, newFontSize, weight } from '../../theme/fonts';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.active ? '130px' : '15px')};
  height: ${(props) => (props.active ? 'auto' : '18px')};
  border: 1px solid ${colors.greyLine};
  border-radius: 10px;
  padding: 7px;
  transition: all 0.3s ease-in-out;
  cursor: ${(props) => (props.active ? 'text' : 'pointer')};
  margin-right: 10px;

  &:hover {
    background-color: ${(props) => (!props.active ? colors.secondaryColor : 'transparent')};
    transition: all 0.3s ease-in-out;
  }

  span {
    background-color: #fff;
    border: 0.5px solid #7b7e82;

    border-radius: 8px;
    width: 80.12px;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    gap: 10px;
  }

  input {
    border: none;
    background-color: transparent;
    color: ${colors.black};
    font-weight: ${weight.medium};
    font-size: ${newFontSize.body};
    font-family: ${fontFamily.font};
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${colors.black};
      font-weight: ${weight.medium};
      font-size: ${newFontSize.body};
      font-family: ${fontFamily.font};
    }
  }
  img {
    height: 15px;
    width: 15px;
    filter: invert(1);
  }
`;
